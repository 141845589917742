import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Icon, {
    AppstoreOutlined,
    CalculatorOutlined,
    HomeOutlined,
    MailOutlined,
    MenuOutlined,
    SettingOutlined,
    ToolOutlined,
    UserOutlined,
} from "@ant-design/icons";

import {
    Button, Col, Drawer, Menu,
} from "antd";
import "./HeaderDrawer.css";
import { NoteOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../../../utils/Darkmode";
import { isUserLoggedIn } from "../../../../reducers/user";

const ROUTE_MAP = {
    home: "/",
    media_overzicht: "/media",
    note: "/note",
    boodschappen: "/boodschappen",
    formules: "/formule",
    haal_weg: "/haal_weg",
    login: "/login",
    youtube_progressie: "/youtube",
};

function YouTubeSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            fill="currentColor"
        >
            {/* eslint-disable-next-line max-len */}
            <path d="M21.8 8.001a2.746 2.746 0 0 0-1.93-1.938C18.064 5.5 12 5.5 12 5.5s-6.064 0-7.87.563A2.746 2.746 0 0 0 2.2 8.001C1.5 9.8 1.5 12 1.5 12s0 2.2.7 3.999c.27.813.999 1.542 1.81 1.811C5.936 18.5 12 18.5 12 18.5s6.064 0 7.87-.563a2.746 2.746 0 0 0 1.93-1.938C22.5 14.2 22.5 12 22.5 12s0-2.2-.7-3.999zM9.75 14.625V9.375L15.5 12l-5.75 2.625z" />
        </svg>
    );
}
function YouTubeIcon(props) {
    return <Icon component={YouTubeSvg} {...props} />;
}

const rootSubmenuKeys = ["home", "media", "note", "boodschappen", "formules"];

export function HeaderDrawer() {
    const isLoggedIn = useSelector(isUserLoggedIn);

    const items = [
        {
            label: "Home", key: "home", icon: <HomeOutlined />, loginRequired: false,
        },
        {
            label: "Medialijst",
            loginRequired: true,
            key: "media_overzicht",
            icon: <MailOutlined />,
        },
        {
            label: "YouTube",
            loginRequired: true,
            key: "youtube_progressie",
            icon: <YouTubeIcon />,
        },
        // {
        //     label: "Note", key: "note", icon: <NoteOutlined />, loginRequired: true,
        // },
        // {
        //     label: "Boodschappen",
        //     loginRequired: true,
        //     key: "boodschappen",
        //     icon: <AppstoreOutlined />,
        // },
        // { label: "Formules", key: "formules", icon: <CalculatorOutlined /> },
        { label: "Haal weg", key: "haal_weg", icon: <ToolOutlined /> },
    ];

    const [visible, setVisible] = React.useState(false);

    const location = useLocation();
    const [currentKey, partialMatch] = mapPathToKey(location.pathname);
    const [selectedKeys, setSelectedKeys] = useState(currentKey ? [currentKey] : []);
    const [openKeys, setOpenKeys] = useState(determineOpenKeys(location.pathname));

    const navigate = useNavigate();

    const [isPartialMatch, setIsPartialMatch] = useState(false);

    useEffect(() => {
        setIsPartialMatch(partialMatch);
    }, [partialMatch]);

    // on location change update the selected keys
    useEffect(() => {
        const [newCurrentKey, newPartialMatch] = mapPathToKey(location.pathname);
        setSelectedKeys(newCurrentKey ? [newCurrentKey] : []);
        setIsPartialMatch(newPartialMatch);
        setOpenKeys(determineOpenKeys(location.pathname));
    }, [location.pathname]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => !openKeys.includes(key));
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    function mapPathToKey(pathname) {
        // Probeer eerst een exacte overeenkomst te vinden
        const exactMatchKey = Object.keys(ROUTE_MAP).find((key) => ROUTE_MAP[key] === pathname);
        if (exactMatchKey) {
            // console.log("exact match", exactMatchKey, ROUTE_MAP[exactMatchKey]);
            return [exactMatchKey, false];
        }
        // Als er geen exacte overeenkomst is, zoek dan naar een gedeeltelijke overeenkomst
        const sortedKeys = Object.keys(ROUTE_MAP).sort((a, b) => ROUTE_MAP[b].length - ROUTE_MAP[a].length);
        for (const key of sortedKeys) {
            if (pathname.startsWith(ROUTE_MAP[key]) && ROUTE_MAP[key] !== "/") {
                // console.log("partial match", key, ROUTE_MAP[key]);
                return [key, true];
            }
        }

        return ["", false];
    }

    // useEffect(() => {
    //     const [newCurrentKey, newPartialMatch] = mapPathToKey(location.pathname);
    //     setSelectedKeys(newCurrentKey ? [newCurrentKey] : []);
    //     // setIsPartialMatch(newPartialMatch);
    //     setOpenKeys(determineOpenKeys(location.pathname));
    // }, [location.pathname]);

    function mapKeyToPath(key) {
        return ROUTE_MAP[key] || "/";
    }

    function determineOpenKeys(pathname) {
        if (pathname.startsWith("/media")) {
            return ["media"];
        }
        return [];
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const getItemsBasedOnLoggedIn = () => {
        // Filter eerst de items op basis van de login status
        const filteredItems = isLoggedIn
            ? items.filter((item) => !item.loginRequired || isLoggedIn)
            : items.filter((item) => !item.loginRequired);

        if (!isLoggedIn) {
            filteredItems.push({
                label: "Login", key: "login", icon: <UserOutlined />, loginRequired: false,
            });
        }

        // Verwijder vervolgens de 'loginRequired' eigenschap van elk item
        return filteredItems.map(({ loginRequired, ...item }) => item);
    };
    const { darkMode } = useDarkMode();
    return (
        <Col>
            <Button type="text" onClick={showDrawer} icon={<MenuOutlined />} />
            <Drawer
                title={(
                    <Link to="/">
                        {darkMode ? (
                            <img
                                src={require("../../../../theme/images/teamNoppertLogoTransDarkmode.png")}
                                alt="logo"
                                width="250"
                            />
                        ) : (
                            <img src={require("../../../../theme/images/teamNoppertLogoTrans.png")} alt="logo" width="250" />
                        )}
                    </Link>
                )}
                placement="left"
                closable
                onClose={onClose}
                open={visible}
            >
                <Menu
                    className={isPartialMatch ? "ant-menu-submenu-partial-selected" : ""}
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{ width: 256 }}
                    items={getItemsBasedOnLoggedIn()}
                    selectedKeys={selectedKeys}
                    onSelect={({ key }) => {
                        navigate(mapKeyToPath(key));

                        setSelectedKeys([key]);
                        onClose();
                    }}
                />
            </Drawer>
        </Col>
    );
}
