// dit heeft copilot gemaakt, later zeker nog naar kijken;

// export function PWA() {
//     const [deferredPrompt, setDeferredPrompt] = useState(null);
//     const [installable, setInstallable] = useState(false);
//
//     useEffect(() => {
//         window.addEventListener("beforeinstallprompt", (e) => {
//             e.preventDefault();
//             setDeferredPrompt(e);
//             setInstallable(true);
//         });
//     }, []);
//
//     const handleInstall = () => {
//         deferredPrompt.prompt();
//         deferredPrompt.userChoice.then((choiceResult) => {
//             if (choiceResult.outcome === "accepted") {
//                 console.log("User accepted the A2HS prompt");
//             } else {
//                 console.log("User dismissed the A2HS prompt");
//             }
//         });
//     };
//
//     return (
//         <div>
//             {installable && (
//                 <button onClick={handleInstall} className="uk-button uk-button-primary uk-margin-top">
//                     Installeren
//                 </button>
//             )}
//         </div>
//     );
// }

import { convertedVapidKey, postSubscriptionWhenUserLogin } from "../index";

export function UpdateSubscription({ userId }) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
        // console.log("Service Worker and Push is supported IN USER DING MAAR NU IN APPARTE PWA FUNCTIE");

        // register the service worker
        navigator.serviceWorker.register("/service-worker.js")
            .then((swReg) => {
                // console.log("Service Worker is registered IN USER DING", swReg);

                swReg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidKey,
                }).then((subscription) => {
                    // Pass the subscription object to your server
                    // console.log("User is subscribed: IN DE USER DING!!", subscription);

                    postSubscriptionWhenUserLogin(subscription, userId).then(() => {
                        // console.log("subscription saved with user id");
                        // console.log("SUB SAVED MET ID!");
                        // console.log(userData);
                        // console.log(userData.user._id);
                    }).catch((error) => {
                        console.error("Subscription failed: ", error);
                    });
                }).catch((err) => {
                    console.log("Failed to subscribe the user: ", err);
                });
            }).catch((error) => {
                console.error("Service Worker Error", error);
            });
    } else {
        console.warn("Push messaging is not supported");
    }
}
