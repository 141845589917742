import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../reducers/user";

export function Logout() {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <div className="uk-card uk-card-default uk-card-body uk-width-2-3 uk-align-center">
            <div className="uk-grid-small uk-text-center uk-margin-large-bottom uk-grid uk-margin-medium uk-padding-large">
                <div className="uk-width-1-1 uk-margin-medium">
                    <h1 className="uk-text-primary">Uitloggen</h1>
                </div>

                <div className="uk-width-1-1 uk-margin-medium-top uk-margin-medium-bottom">
                    <label className="uk-text-primary" htmlFor="username">Weet u zeker dat u wilt uitloggen?</label>
                </div>

                <div className="uk-width-1-1">
                    <button type="buton" onClick={handleLogout} className="uk-button uk-button-primary">Uitloggen</button>
                </div>
            </div>
        </div>
    );
}
