import { Table, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchMedialijst } from "../../../../../../reducers/Media";

function AddToTablePanel({ form }) {
    const { mediaLijst } = useSelector((state) => state.media);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!mediaLijst || mediaLijst.length === 0) {
            dispatch(fetchMedialijst({ _id: user._id }));
        }
    }, [mediaLijst]);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
            title: "Titel",
            dataIndex: "titel",
            key: "titel",
        },
        {
            title: "Type",
            dataIndex: "lijstType",
            key: "lijstType",
        },
        {
            title: "Mening",
            dataIndex: "mening",
            key: "mening",
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
            form.setFieldsValue({ addToLists: newSelectedRowKeys });
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            name: record.name,
        }),
    };

    return (
        <Form.Item
            label="Toevoegen aan lijst"
            name="addToLists"
            className="form-item"
            rules={[{ required: true, message: "Selecteer een lijst" }]}
        >
            <Table
                columns={columns}
                dataSource={mediaLijst}
                rowSelection={rowSelection}
                rowKey={(record) => record._id} // Ensure each row has a unique key
            />
        </Form.Item>
    );
}

export default AddToTablePanel;
