import {
    Button,
    Checkbox, Form, Input, Modal, Select, Space,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { checkFetchError, fetchApiSendJson } from "../../../../../../../../../snippets/functions/fetchFunctions";
import { useMessage } from "../../../../../../../../../hooks/AntComponents/useMessage";

function NewSourceModal({
    visible, close, mediaId, userId, addSource,
}) {
    const message = useMessage();
    const [addAnother, setAddAnother] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = async (values) => {
        try {
            console.log(values);
            const newSource = await fetchApiSendJson(`media/source/${mediaId}/${userId}`, "POST", { source: values });
            const response = await checkFetchError(newSource);
            addSource(response);

            if (!addAnother) {
                return close();
            }
        } catch (error) {
            console.log(error);
            message.error({ content: error.message, key: "error" });
        }
    };
    return (
        <Modal
            title="Nieuwe source"
            open={visible}
            onCancel={() => close()}
            footer={(
                <Space>
                    <Button onClick={() => close()}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => form.submit()}
                    >
                        Save
                    </Button>
                </Space>
            )}
            // onFinish={onSubmit}
            // onOk={onSubmit}
        >
            <Form
                onFinish={onSubmit}
                form={form}
            >
                <Form.Item
                    name="siteNaam"
                    rules={[
                        {
                            required: true,
                            message: "Vul de site naam in",
                        },
                    ]}
                >

                    <Input placeholder="Site Naam" />

                </Form.Item>
                <Form.Item
                    name="siteUrlEncrypt"
                    rules={[
                        {
                            required: true,
                            message: "Vul de URL in",
                        },
                    ]}
                >

                    <Input placeholder="URL" />
                </Form.Item>
                <Form.Item
                    name="sourceNaam"
                    rules={[
                        {
                            required: true,
                            message: "Vul de bron naam in",
                        },
                    ]}
                >

                    <Input placeholder="Bron Naam" />

                </Form.Item>
                <Form.Item
                    name="mening"
                    rules={[
                        {
                            required: false,
                            message: "Vul de mening in",
                        },
                    ]}
                >

                    <Select>
                        <Select.Option value="Favo">Favo</Select.Option>
                    </Select>

                </Form.Item>
                <Form.Item
                    name="default"
                    label="Default?"
                    rules={[
                        {
                            required: false,
                            message: "Vul de mening in",
                        },
                    ]}
                    valuePropName="checked"
                    initialValue={false}
                >

                    <Checkbox />
                </Form.Item>

                <Checkbox
                    checked={addAnother}
                    onChange={(e) => setAddAnother(e.target.checked)}
                >
                    Add another
                </Checkbox>
            </Form>
        </Modal>
    );
}

export default NewSourceModal;
