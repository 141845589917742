import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useSwipeable } from "react-swipeable";
import React, { useContext, useEffect, useState } from "react";
import {
    Button, Drawer, Layout, Menu,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cmsLayoutSlice, { setSideMenuMedia, toggleSideMenuMedia } from "../../../../reducers/CMSLayout";
import { useDarkMode } from "../../../../utils/Darkmode";
import {
    useIsScreenMobileWindowSize,
    useIsScreenTabletWindowSize,
    useScreenSize,
} from "../../../../hooks/useCheckWindowSize";
import "./SideComponent.css";

const { Sider } = Layout;

export default function SideComponent({
    menuItems, background, showSideMenuWhenMobile, defaultSelectedKeys, openMenuInSideDrawerWhenMobile, onCollapse, drawerMenuVisible, toogleSideMenu, isSideMenuVisible, ...props
}) {
    const navigate = useNavigate();
    const [selectedMenuItemKey, setSelectedMenuItemKey] = useState(false);
    const dispatch = useDispatch();

    const toogleDrawerMobileMenu = () => {
        if (toogleSideMenu) toogleSideMenu();
    };
    const onMenuItemClick = (e) => {
        const item = menuItems.find((item) => item.key === e.key);
        if (item) {
            navigate(item.path);

            if (drawerMenuVisible) toogleDrawerMobileMenu();
        }
    };
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        if (!menuItems) return;
        const activeItem = menuItems.find((item) => currentPath === item.path);
        setSelectedMenuItemKey(activeItem ? activeItem.key : "");
    }, [location, menuItems]);

    const [collapsed, setCollapsed] = useState(true);
    const screenSize = useScreenSize();
    const isMobileSize = useIsScreenMobileWindowSize();
    const isTabletSize = useIsScreenTabletWindowSize();

    const [userCollapsed, setUserCollapsed] = useState(null);

    useEffect(() => {
        if (screenSize.width <= 1200) {
            if (userCollapsed === null) {
                setCollapsed(true);
            }
        } else if (userCollapsed === null) {
            setCollapsed(false);
        }
    }, [screenSize, userCollapsed]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        setUserCollapsed(!collapsed);
    };

    const setDrawerMenuMobileVisible = (visible) => {
        dispatch(setSideMenuMedia(visible));
    };

    useEffect(() => {
        if (isMobileSize) setDrawerMenuMobileVisible(false);
    }, [screenSize.width]);

    const onClick = () => {
        if (!isMobileSize) {
            toggleCollapsed();
        } else {
            toogleDrawerMobileMenu();
        }
    };

    const { darkMode } = useDarkMode();

    const getSizeCss = () => {
        if (isTabletSize && !collapsed) {
            return "SmallSider";
        } if (collapsed) {
            return "SmallSider";
        } if (isMobileSize) {
            return "SmallSider";
        } if (!isMobileSize) {
            return "DesktopSizeSider";
        }
        return "DesktopSizeSider";
    };

    return (
        <>
            {menuItems && showSideMenuWhenMobile && !isMobileSize && isSideMenuVisible && (
                <Sider
                    className={`${getSizeCss()} MaxSize`}
                    style={{
                        background,
                        width: "50px",
                        overflow: "auto",
                        // TODO: op basis van header en footer dpe height automatisch aanpassen, en top ook! ik heb een id o header en footer gezet.
                        height: "50vh", // Zorg ervoor dat de hoogte van het scherm is als je wilt dat het de hele hoogte inneemt. > zou eigenlijk 100 moeten zijn, maar ivm met footer en header!
                        position: "sticky",
                        top: 90,
                        zIndex: 999,
                    }}
                    collapsed={collapsed}
                    onCollapse={onCollapse}
                >
                    {/* <div className="demo-logo-vertical" /> */}
                    <Button
                        type={darkMode ? "default" : "primary"}
                        onClick={onClick}
                        className={isMobileSize ? "makeMenuButtonSmallWhenMobile" : "w-full"}
                        style={{
                            height: 38,
                            display: !isMobileSize || showSideMenuWhenMobile ? "" : "none",
                            marginBottom: 8,
                        }}
                    >
                        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                    {(!openMenuInSideDrawerWhenMobile || !isMobileSize) && (
                        <Menu
                            style={{
                                padding: 0,
                            }}
                            theme="light"
                            mode="inline"
                            defaultSelectedKeys={defaultSelectedKeys && [defaultSelectedKeys]}
                            items={menuItems}
                            onClick={onMenuItemClick}
                            selectedKeys={[selectedMenuItemKey]}
                        />
                    )}
                </Sider>
            )}

            <MobileMenuDrawer
                menuItems={menuItems}
                showSideMenuWhenMobile={showSideMenuWhenMobile}
                openMenuInSideDrawerWhenMobile={openMenuInSideDrawerWhenMobile}
                drawerMenuVisible={drawerMenuVisible}
                toogleDrawerMobileMenu={toogleDrawerMobileMenu}
                defaultSelectedKeys={defaultSelectedKeys}
                onMenuItemClick={onMenuItemClick}
                selectedMenuItemKey={selectedMenuItemKey}
            />
        </>
    );
}

function MobileMenuDrawer({
    menuItems, showSideMenuWhenMobile, openMenuInSideDrawerWhenMobile,
    drawerMenuVisible, toogleDrawerMobileMenu, defaultSelectedKeys, onMenuItemClick, selectedMenuItemKey,
}) {
    if (menuItems && showSideMenuWhenMobile && openMenuInSideDrawerWhenMobile) {
        return (
            <Drawer
                open={drawerMenuVisible}
                placement="left"
                onClose={toogleDrawerMobileMenu}
                title="Menu"
                size="default"
            >
                <Menu
                    style={{
                        padding: 0,
                    }}
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={defaultSelectedKeys && [defaultSelectedKeys]}
                    items={menuItems}
                    onClick={onMenuItemClick}
                    selectedKeys={[selectedMenuItemKey]}
                />
            </Drawer>
        );
    }
}
