import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkFetchError, fetchApiSendJson } from "../snippets/functions/fetchFunctions";

// Asynchronous thunk action
export const fetchAllMediaThunk = createAsyncThunk("Media/fetchAllMedia", async ({ _id, title }, { dispatch }) => {
    const response = await fetchApiSendJson(`media/${_id}`, "GET");
    const mediaResponse = await checkFetchError(response);

    if (title) {
        dispatch(filterMediaByTitle(title));
    }

    return mediaResponse;
});

export const fetchMedialijst = createAsyncThunk("Media/fetchMediaLijsten", async ({ _id, title }, { dispatch }) => {
    const response = await fetchApiSendJson(`media/lijst/${_id}`, "GET");
    return checkFetchError(response);
});

export const deleteMediaThunk = createAsyncThunk("Media/deleteMedia", async ({ media_id, user_id }) => {
    const response = await fetchApiSendJson(`media/${media_id}/${user_id}`, "DELETE");
    return checkFetchError(response);
});

export const getSpecificMediaLijst = createAsyncThunk("Media/getSpecificMediaLijst", async ({ id }) => state.mediaLijst = state.mediaLijst.filter((item) => item._id === id));

export const updateMedialijst = createAsyncThunk("Media/updateMedialijst", async ({ _id, user_id, data }, { dispatch }) => {
    const response = await fetchApiSendJson(`media/lijst/${_id}/${user_id}`, "PUT", { mediaLijst: data });
    return checkFetchError(response);
});

export const deleteMediaLijst = createAsyncThunk("Media/deleteMediaLijst", async ({ medialijst_id, user_id, resolve }) => {
    try {
        const response = await fetchApiSendJson(`media/lijst/${medialijst_id}/${user_id}`, "DELETE");

        const result = await checkFetchError(response);

        if (result.isOk) {
            resolve();
        }
    } catch (error) {
        console.log("error server aan", error.message);
        resolve();
    }
});

export const fetchMedialijstEnums = createAsyncThunk("Media/fetchMedialijstEnums", async () => {
    const response = await fetchApiSendJson("media/lijst/enums");
    return checkFetchError(response);
});

export const fetchMediaEnums = createAsyncThunk("Media/fetchMediaEnums", async () => {
    const response = await fetchApiSendJson("media/enums");
    return checkFetchError(response);
});

// Initial state
const initialUserState = {
    media: [],
    mediaLijst: [],
    mediaLijstEnums: null,
    mediaEnums: null,
    error: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed' | 'tryingToken' | 'loggedOut'
};

// Create a Redux slice
const media = createSlice({
    name: "media",
    initialState: initialUserState,
    reducers: {
        filterMediaByTitle: (state, action) => {
            state.media = state.media.filter((item) => item.titel.toLowerCase().includes(action.payload.toLowerCase()));
        },
        getSpecificMedia: (state, action) => {
            state.media = state.media.filter((item) => item._id === action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMediaThunk.fulfilled, (state, action) => {
                state.media = action.payload;
            })
            .addCase(fetchAllMediaThunk.rejected, (state, action) => {
                console.log(action.payload);
            })
            .addCase(deleteMediaThunk.fulfilled, (state, action) => {
                console.log(action.payload.media_id, "DELETE_MEDIA");
                state.media = state.media.filter((item) => item._id !== action.payload.media_id);
            })
            .addCase(deleteMediaThunk.rejected, (state, action) => {
                console.log(action.payload);
            })
            .addCase(fetchMedialijst.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchMedialijst.fulfilled, (state, action) => {
                state.mediaLijst = action.payload;
                state.error = null;
                state.status = "succeeded";
            })
            .addCase(fetchMedialijst.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
            })

            // Idk of dit nodig is.
            .addCase(updateMedialijst.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateMedialijst.fulfilled, (state, action) => {
                state.mediaLijst = state.mediaLijst.map((item) => (item._id === action.payload._id ? action.payload : item));
                state.error = null;
                state.status = "succeeded";
            })
            .addCase(updateMedialijst.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
            })

            .addCase(fetchMedialijstEnums.pending, (state, action) => {
                state.status = "loadingEnums";
            })
            .addCase(fetchMedialijstEnums.fulfilled, (state, action) => {
                state.mediaLijstEnums = action.payload;
                state.error = null;
                state.status = "enumsLoaded";
            })
            .addCase(fetchMedialijstEnums.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
            })
            .addCase(fetchMediaEnums.pending, (state, action) => {
                state.status = "loadingEnums";
            })
            .addCase(fetchMediaEnums.fulfilled, (state, action) => {
                state.mediaEnums = action.payload;
                state.error = null;
                state.status = "enumsLoaded";
            })
            .addCase(fetchMediaEnums.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
            });
    },
});
export const { filterMediaByTitle, getSpecificMedia } = media.actions;

export default media.reducer;

// Uit root reducer;
/*
    Media!
 */
// export const getAllMedia = (_id, title) => async (dispatch) => new Promise(async (resolve, reject) => {
//     try {
//         const response = await fetchApiSendJson(`media/${_id}`, "GET");
//         const mediaResponse = await checkFetchError(response);
//
//         dispatch({ type: "SET_ALLUSERMEDIA", payload: mediaResponse });
//
//         if (title) {
//             dispatch(filterMediaByTitle(title));
//         }
//         resolve();
//     } catch (error) {
//         console.log("error server aan", error.message);
//         reject();
//     }
// });
// export const getMediaDecryptedDefaultSource = (media, user_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/source/${media}/${user_id}`, "GET");
//         const result = await checkFetchError(response);
//
//         if (result.siteUrlEncrypt) {
//             clipboard(result.siteUrlEncrypt).then(() => {
//                 setTimeout(() => {
//                     message.success({ content: `Gekopieerd! van: ${result.siteNaam}`, key: "loadingMessage", duration: 2 });
//                 }, 300);
//             }).catch(() => {
//                 message.error({ content: "Failed to copy source to clipboard", key: "loadingMessage", duration: 2 });
//             });
//         } else {
//             setTimeout(() => {
//                 message.error({ content: "Niet gelukt", key: "loadingMessage", duration: 2 });
//             }, 1000);
//         }
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const saveMediaInfo = (media, user_id, media_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/${media_id}/${user_id}`, "PUT", { mediaInfo: media });
//         message.info({ content: "Saving Media", key: "loadingMessage", duration: 5 });
//
//         const result = await checkFetchError(response);
//
//         if (result._id) {
//             console.log("result van saving Media info", result);
//             setTimeout(() => {
//                 message.success({ content: "Media saved", key: "loadingMessage", duration: 2 });
//             }, 500);
//         }
//
//         dispatch({ type: "SET_1Media", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error({ content: `Error: ${error.message}`, key: "loadingMessage", duration: 3 });
//     }
// };
// export const saveMediaKijkInfo = (media) => async (dispatch) => {
//
// };
//
// export const saveMediaKijkInfoSerieNummer = (media, user_id) => async (dispatch) => {
//
// };
//
// export const saveMediaKijkInfoAfleveringNummer = (media, user_id) => async (dispatch) => {
//
// };
//
// export const saveMediaKijkInfoKijkNummer = (media, user_id) => async (dispatch) => {
//
// };
//
// export const saveMediaData = (media, user_id, media_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/mediaData/${media_id}/${user_id}`, "PUT", { mediaData: media });
//         const result = await checkFetchError(response);
//
//         if (result._id) {
//             setTimeout(() => {
//                 message.success({ content: "Media + data saved", key: "loadingMessage", duration: 2 });
//             }, 500);
//         } else if (result.errors[0]) {
//             setTimeout(() => {
//                 // message.error({ content: result.errors[0], key: "loadingMessage", duration: 2 }); //Dit is de 'mediaisthe same. of de backend deze message uitgeschakelen of alleen error messagew als echt iets aan de hand is
//             }, 500);
//         } else {
//             setTimeout(() => {
//                 message.error({ content: "Niet gelukt om op te slaan", key: "loadingMessage", duration: 3 });
//             }, 500);
//         }
//
//         dispatch({ type: "SET_1Media", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         setTimeout(() => {
//             message.error({ content: error.message, key: "loadingMessage", duration: 3 });
//         }, 500);
//     }
// };
//
// export const newMediaSource = (source, user_id, media_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/source/${media_id}/${user_id}`, "POST", { source });
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "SET_1Media", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const deleteMediaSource = (source_id, user_id, media_id) => async (dispatch) => {
//     try {
//         console.log(source_id, user_id, media_id);
//         const response = await fetchApiSendJson(`media/source/${media_id}/${source_id}/${user_id}`, "DELETE");
//         const result = await checkFetchError(response);
//
//         console.log("deleteMediaSource", result);
//
//         dispatch({ type: "SET_1Media", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const getMediaDecryptedSelectedSource = (media, user_id, source_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/source/single/${media}/${source_id}/${user_id}`, "GET");
//         const result = await checkFetchError(response);
//
//         if (result.siteUrlEncrypt) {
//             clipboard(result.siteUrlEncrypt).then(() => {
//                 setTimeout(() => {
//                     message.success({ content: `Gekopieerd! van: ${result.siteNaam}`, key: "loadingMessage", duration: 2 });
//                 }, 1000);
//             }).catch(() => {
//                 message.error({ content: "Failed to copy source to clipboard", key: "loadingMessage", duration: 2 });
//             });
//         } else {
//             setTimeout(() => {
//                 message.error({ content: "Niet gelukt", key: "loadingMessage", duration: 2 });
//             }, 1000);
//         }
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const filterMediaByTitle = (title) => async (dispatch) => {
//     dispatch({ type: "FILTER_MEDIA_BY_TITLE", payload: title });
// };
//
// export const deleteMedia = (media_id, user_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/${media_id}/${user_id}`, "DELETE");
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "DELETE_MEDIA", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const addNewMedia = (media, user_id) => async (dispatch) => {
//     try {
//         console.log(media);
//         const response = await fetchApiSendJson(`media/${user_id}`, "POST", { mediaInfo: media });
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "ADD_NEW_MEDIA", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const addMediaLijst = (mediaLijst, user_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/lijst/${user_id}`, "POST", { mediaLijst });
//         const result = await checkFetchError(response);
//
//         if (result.errors && result.errors.length > 0) {
//             result.errors.forEach((err) => {
//                 message.error({ content: err, key: "loadingMessage", duration: 2 });
//             });
//         } else {
//             message.success({ content: "Lijst aangemaakt", key: "loadingMessage", duration: 2 });
//         }
//
//         dispatch({ type: "ADD_NEW_MEDIALIJST", payload: result });
//     } catch (error) {
//         handleErrors(dispatch, error);
//     }
// };
//
// export const getUserMediaLijst = (user_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/lijst/${user_id}`, "GET");
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "SET_MEDIALIJST", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const deleteMediaLijst = (mediaLijst_id, user_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/lijst/${mediaLijst_id}/${user_id}`, "DELETE");
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "DELETE_MEDIALIJST", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const addMediaToMediaLijst = (mediaLijst_id, user_id, media_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/lijst/${mediaLijst_id}/${user_id}`, "POST", { media_id });
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "UPDATE_1_MEDIALIJST", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const deleteMediaFromMediaLijst = (mediaLijst_id, user_id, media_id) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/lijst/${mediaLijst_id}/${media_id}/${user_id}`, "DELETE");
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "UPDATE_1_MEDIALIJST", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// export const changeMediaLijst = (mediaLijst_id, user_id, mediaLijst) => async (dispatch) => {
//     try {
//         const response = await fetchApiSendJson(`media/lijst/${mediaLijst_id}/${user_id}`, "PUT", { mediaLijst });
//         const result = await checkFetchError(response);
//
//         dispatch({ type: "UPDATE_1_MEDIALIJST", payload: result });
//     } catch (error) {
//         console.log("error server aan", error.message);
//         message.error(`Error: ${error.message}`);
//     }
// };
//
// const handleErrors = (dispatch, error) => {
//     if (error.message === "Unauthorized") {
//         dispatch({ type: "LOGOUT" });
//     } else {
//         dispatch({ type: "HANDLE_ERROR", error });
//     }
// };
