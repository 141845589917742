import React, {
    createContext, useCallback, useContext, useEffect, useMemo, useState,
} from "react";

const DarkModeContext = createContext(undefined);

export function useDarkMode() {
    return useContext(DarkModeContext);
}

export function DarkModeProvider({ children }) {
    const getInitialMode = () => {
        const savedMode = localStorage.getItem("darkMode");
        // Check if the retrieved mode is one of the expected values
        return ["dark", "light", "system"].includes(savedMode) ? savedMode : "system";
    };

    // State for storing the mode
    const [mode, setMode] = useState(getInitialMode);
    // State to manage whether the UI should be in dark mode
    // eslint-disable-next-line max-len
    const [darkMode, setDarkMode] = useState(() => mode === "dark" || (mode === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches));

    // Handle changes in the system's dark mode setting
    const handleSystemThemeChange = useCallback((e) => {
        if (mode === "system") {
            setDarkMode(e.matches);
        }
    }, [mode]);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        mediaQuery.addEventListener("change", handleSystemThemeChange);

        // Update the dark mode status when mode changes or system preference changes
        setDarkMode(mode === "dark" || (mode === "system" && mediaQuery.matches));

        return () => {
            mediaQuery.removeEventListener("change", handleSystemThemeChange);
        };
    }, [mode, handleSystemThemeChange]);

    // Update localStorage and body class when mode changes
    useEffect(() => {
        localStorage.setItem("darkMode", mode);
        const className = "dark";
        const bodyClass = document.body.classList;
        // eslint-disable-next-line no-unused-expressions
        darkMode ? bodyClass.add(className) : bodyClass.remove(className);
    }, [mode, darkMode]);

    const value = useMemo(() => ({
        darkMode, mode, setDarkMode: setMode,
    }), [darkMode, mode, setDarkMode]);

    return (
        <DarkModeContext.Provider value={value}>
            {children}
        </DarkModeContext.Provider>
    );
}
