import React from "react";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useIsScreenTabletWindowSize } from "../../hooks/useCheckWindowSize";
import "./PageNotFound.css";
import teamNoppertLogo from "../../theme/images/teamNoppertLogoRect.png";
import teamNoppertLogoDarkmode from "../../theme/images/teamNoppertLogoRectDarkmode.png";
import { useDarkMode } from "../../utils/Darkmode";
import { Header } from "../../components/reusableComponents/Text/Header/Header";
import { P } from "../../components/reusableComponents/Text/Paragraph/P";

export function PageNotFound() {
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();
    const isTabletSize = useIsScreenTabletWindowSize();

    return (
        <div className={`flex ${isTabletSize ? "flex-col" : "flex-row"}`}>
            <main className={`${isTabletSize ? "w-full" : "w-2/3"} flex items-center justify-center break-words`}>
                <div className="text-center p-6 w-full">
                    <P className="font-semibold">404</P>

                    <Header className="mt-4">
                        Pagina niet gevonden :/
                    </Header>
                    <P className="mt-6 text-gray-600 dark:text-gray-300  break-words" defaultDarkMode={false}>
                        Sorry, we konden de pagina die je zocht niet vinden.
                    </P>
                    <div className="mt-10 flex flex-col items-center space-y-4">
                        <Button type="default" className="h-fit text-wrap">
                            <Link
                                to="/"
                                className="rounded-md px-3.5 py-2.5 text-sm font-semibold dark:text-white shadow-sm"
                            >
                                <HomeOutlined />
                                Terug naar home
                            </Link>
                        </Button>
                        <Button
                            onClick={() => navigate(-1)}
                            type="default"
                            className="h-fit text-sm font-semibold text-gray-900 dark:text-gray-300 text-wrap"
                        >
                            Terug naar je vorige locatie (indien mogelijk)
                            <span aria-hidden="true">&rarr;</span>
                        </Button>
                    </div>
                </div>
            </main>

            {!isTabletSize && (
                <div className={`w-1/3 p-8 ${darkMode ? "hover:scale-125 transition-all duration-1000" : ""}`}>
                    <img
                        src={darkMode ? teamNoppertLogoDarkmode : teamNoppertLogo}
                        alt="Team Noppert Logo"
                        className="object-cover hover:scale-105 transition-all duration-500"
                    />
                </div>
            )}
        </div>
    );
}
