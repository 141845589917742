import React from "react";
// import PropTypes from "prop-types";
// import { withRouter } from "react-router";
import { useNavigate } from "react-router-dom";

function LinkButton(props) {
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        ...rest
    } = props;
    const navigate = useNavigate();
    return (
        // <button
        //     {...rest} // `children` is just another prop!
        //     onClick={(event) => {
        //         onClick && onClick(event);
        //         history.push(to);
        //     }}
        // />
        <button
            {...rest}
            onClick={(event) => {
                if (onClick) onClick(event);
                navigate(to);
            }}
        />
    );
}

// LinkButton.propTypes = {
//     to: PropTypes.string.isRequired,
//     children: PropTypes.node.isRequired,
// };

export default LinkButton;
