import { useEffect, useState } from "react";
import { Rate, Table } from "antd";
import { Link } from "react-router-dom";
import { checkFetchError, fetchApiSendJson } from "../../../../../../../snippets/functions/fetchFunctions";
import { GetMeningTag } from "../../../../Tags/Tags";

export function InWhatOtherMediaLists({ mediaId, userId }) {
    const [mediaLists, setMediaLists] = useState(null);
    const columns = [
        {
            title: "Titel",
            render: (record) => (
                <Link to={`/media/lijst/watch/${record._id}`}>
                    {record.titel}
                </Link>
            ),
            key: "titel",
        },
        {
            title: "Mening",
            key: "mening",
            render: (record) => (
                <GetMeningTag mening={record.mening} />
            ),
        },
        {
            title: "Rating",
            key: "rating",
            render: (record) => (
                <Rate disabled allowHalf defaultValue={record.rating} />
            ),
        },
    ];

    useEffect(() => {
        async function getData() {
            try {
                const getMediaListsResponse = await fetchApiSendJson(`media/mediaLists/${mediaId}/${userId}`, "GET");
                const getMediaListsData = await checkFetchError(getMediaListsResponse);

                setMediaLists(getMediaListsData);
            } catch (e) {
                console.error(e);
            }
        }

        getData();
    }, [mediaId, userId]);

    if (!mediaLists) return null;

    return (
        <Table
            dataSource={mediaLists}
            columns={columns}
            pagination={false}
        />
    );
}
