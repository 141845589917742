import React, {
    useContext, createContext, useMemo,
} from "react";

import { notification } from "antd";

// https://ant.design/components/notification
export const NotificationPlacementOptions = {
    TOPLEFT: "topLeft",
    TOPRIGHT: "topRight",
    BOTTOMLEFT: "bottomLeft",
    BOTTOMRIGHT: "bottomRight",
    TOP: "top",
    BOTTOM: "bottom",
};

const NotificationContext = createContext(undefined);

export function useNotification() {
    return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
    const [notificationApi, contextHolder] = notification.useNotification();

    const sendNotification = (options) => {
        if (notificationApi) {
            const {
                type = "info",
                message,
                description,
                duration,
                key = `key-${Date.now()}`,
                btn,
                onClose,
                placement = NotificationPlacementOptions.TOPRIGHT,
                icon,
                closeIcon,
            } = options;

            const enhancedBtn = btn ? React.cloneElement(btn, {
                onClick: () => {
                    if (btn.props.onClick) {
                        btn.props.onClick(); // Call any passed onClick function
                    }
                    // Optionally close this notification or all notifications
                    if (btn.props.closeAll) {
                        notificationApi.destroy();
                    } else if (key) {
                        notificationApi.destroy(key);
                    }
                },
            }) : undefined;

            notificationApi[type]({
                message,
                description,
                duration,
                key,
                btn: enhancedBtn,
                onClose,
                placement,
                icon,
                closeIcon,
            });
        }
    };

    const notificationHandler = useMemo(() => ({
        open: (options) => sendNotification(options),
        success: (options) => sendNotification({ type: "success", ...options }),
        error: (options) => sendNotification({ type: "error", ...options }),
        warning: (options) => sendNotification({ type: "warning", ...options }),
        info: (options) => sendNotification({ type: "info", ...options }),
        close: (key) => {
            notificationApi.destroy(key);
        },
        closeAll: () => {
            notificationApi.destroy();
        },
    }), [notificationApi]);

    return (
        <NotificationContext.Provider value={notificationHandler}>
            {contextHolder}
            {children}
        </NotificationContext.Provider>
    );
}

/*
    Example usage:
        const notification = useNotification();

        const openNotification = () => {
        let key = `open${Date.now()}`;
        notification.open({
            key,
            message: "Notification Title",
            description:
                "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
            duration: 0,
            placement: NotificationPlacementOptions.BOTTOMLEFT,
            btn: (
                <Space>
                    <Button type="link" size="small" onClick={() => notification.closeAll()}>
                        Destroy All
                    </Button>
                    <Button type="primary" size="small" onClick={() => notification.close(key)}>
                        Confirm
                    </Button>
                </Space>
            ),
            icon: <HomeOutlined />,
        });

        key = `open${Date.now() + 1}`;
        notification.open({
            key,
            message: "Notification Title 2",
            description:
                "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
            duration: 0,
            placement: NotificationPlacementOptions.TOPRIGHT,
            btn: (
                <Space>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            notification.close(key);
                            console.log("REECT");
                        }}
                    >
                        reject
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            notification.closeAll();
                            console.log("Notification closed 2! REECT");
                        }}
                    >
                        Destroy All
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            notification.close(key);
                            console.log("on confirm tutle 2");
                        }}
                    >
                        Confirm
                    </Button>
                </Space>
            ),
            icon: <HomeOutlined />,
            closeIcon: <HomeOutlined />,
        });
    };
 */
