import React from "react";
import {
    Row, Col, Statistic, Image, Tag, Divider, Typography, Space,
} from "antd";
import Slider from "react-slick";
import moment from "moment/moment";
import { Header } from "../../../../../reusableComponents/Text/Header/Header";
import { TheMovieDB_MediaTypes } from "../../../../../../enum/TheMovieDB";

const { Text } = Typography;

function BottemTitleInfo({ movieDbDetails, actualType }) {
    if (movieDbDetails) {
        return (
            <Space>
                <Tag className="bg-transparent text-white rounded-2xl py-1">
                    {actualType}
                </Tag>
                <Tag className="bg-transparent text-white rounded-2xl py-1">
                    {actualType === TheMovieDB_MediaTypes.TV && (
                        movieDbDetails.first_air_date
                            ? `${moment(movieDbDetails.first_air_date).format("YYYY")} - ${moment(movieDbDetails.last_air_date).format("YYYY")}`
                            : ""
                    )}
                    {actualType === TheMovieDB_MediaTypes.MOVIE && (
                        movieDbDetails.release_date
                            ? moment(movieDbDetails.release_date).format("YYYY")
                            : ""
                    )}
                </Tag>
                <Tag className="bg-transparent text-white rounded-2xl py-1">
                    {actualType === TheMovieDB_MediaTypes.TV && (
                        movieDbDetails.episode_run_time
                            ? `${`${movieDbDetails.episode_run_time} minuten` || `${movieDbDetails.episode_run_time[0]} minuten`}`
                            || movieDbDetails.last_episode_to_air.runtime : ""
                    )}

                    {actualType === TheMovieDB_MediaTypes.MOVIE && (
                        movieDbDetails.runtime
                            ? `${movieDbDetails.runtime} minuten`
                            : "Geen runtime"
                    )}
                </Tag>
            </Space>
        );
    }
}

function TitleInfo({
    HeaderText,
    Rating,
    PosterSrc,
    PosterAlt,
    CarouselImages,
    Genres,
    Cast,
    Crew,
    actualType,
    movieDbDetails,
}) {
    const settings = {
        dots: true,
        infinite: CarouselImages && CarouselImages.length > 1,
        speed: 300,
        swipeToSlide: true,
        adaptiveHeight: true,
        arrows: CarouselImages && CarouselImages.length > 1,
    };

    return (
        <div className="p-5">
            <Row gutter={24} justify="space-between" className="" align="middle">
                <Col className="pl-0">
                    <Header className="text-white ">
                        {HeaderText}
                    </Header>
                </Col>

                <Col>
                    <Statistic
                        title="MovieDB rating"
                        className="w-full max-w-full"
                        value={Rating}
                        suffix="/ 100"
                    />
                </Col>
            </Row>

            <div className="w-full ">
                <BottemTitleInfo movieDbDetails={movieDbDetails} actualType={actualType} />
            </div>

            <Row gutter={[16, 16]} className="flex flex-col md:flex-row">
                <Col xs={24} md={8} className="flex justify-center items-center">
                    <Image
                        className="object-cover"
                        style={{ maxHeight: "400px" }}
                        src={PosterSrc}
                        alt={PosterAlt}
                    />
                </Col>
                <Col xs={24} md={16} className="flex flex-col justify-center items-center mb-5">
                    <div className="w-full p-3">
                        <Image.PreviewGroup>
                            <Slider {...settings}>
                                {CarouselImages && CarouselImages.map((image) => (
                                    <Image
                                        key={image.file_path}
                                        src={`https://image.tmdb.org/t/p/original${image.file_path}`}
                                        alt={image.file_path}
                                        className="object-cover w-full h-full"
                                    />
                                ))}
                            </Slider>
                        </Image.PreviewGroup>
                    </div>
                </Col>
            </Row>

            {Genres && (
                <Row gutter={[16, 16]}>
                    {Genres.map((genre) => (
                        <Col key={genre.id}>
                            <Tag className="bg-transparent text-white rounded-2xl py-1">{genre.name}</Tag>
                        </Col>
                    ))}
                </Row>
            )}

            {Cast && (
                <Row gutter={[16, 16]} className="w-full">
                    <Col className="w-full">
                        <Row gutter={[16, 16]} className="w-full">
                            <Divider style={{ borderColor: "white", marginBottom: "0" }} />
                            <div className="border-gray-700 py-0">
                                <Text className="text-white font-bold mr-2">Cast:</Text>
                                {Cast.slice(0, 10).map((casts, index) => (
                                    <span key={casts.id}>
                                        <Text className="text-blue-500">
                                            {casts.name}
                                        </Text>
                                        {index < Cast.length - 1
                                        && <Text className="text-blue-500">, </Text>}
                                    </span>
                                ))}
                            </div>
                        </Row>
                    </Col>
                </Row>
            )}

            {Crew && (
                <Row gutter={[16, 16]} className="w-full">
                    <Col className="w-full">
                        <Row gutter={[16, 16]} className="w-full">
                            <Divider style={{ borderColor: "white", marginBottom: "0" }} />
                            <div className="border-gray-700 py-0">
                                <Text className="text-white font-bold mr-2">Crew:</Text>
                                {Crew.slice(0, 5).map((crews, index) => (
                                    <span key={crews.id}>
                                        <Text className="text-blue-500">
                                            {crews.name}
                                        </Text>
                                        {index < Crew.length - 1
                                        && <Text className="text-blue-500">, </Text>}
                                    </span>
                                ))}
                            </div>
                            <Divider style={{ borderColor: "white", marginBottom: "0", marginTop: "0" }} />
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default TitleInfo;
