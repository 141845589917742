export const checkFetchError = async (response) => {
    // Dit wat netter maken:
    if (!response) {
        throw new Error("Unauthorized");
    }

    let errorBody;
    if (!response.ok) {
        try {
            errorBody = await response.text();

            const errorData = JSON.parse(errorBody);

            const error = new Error(errorData.message || "Er is een fout opgetreden");
            error.title = errorData.title || "Error"; // Voeg een titel toe aan het Error object
            error.code = response.status;
            throw error;
        } catch {
            const error = new Error(errorBody || "Er is een fout opgetreden");
            error.code = response.status;
            throw error;
        }
    }

    // if (!response.ok) {
    //     const errorBody = await response.text();
    //     try {
    //         const errorData = JSON.parse(errorBody);
    //
    //         console.log(errorData, "ERROR DATA");
    //         const error = new Error(errorData.message || "Er is een fout opgetreden");
    //         error.title = errorData.title || "Error"; // Voeg een titel toe aan het Error object
    //         throw error;
    //     } catch {
    //         throw new Error(errorBody || "Er is een fout opgetreden");
    //     }
    // }

    const json = await response.json();
    if (response.ok) {
        return json;
    }

    return Promise.reject(new Error(json.message));
};

export const fetchApi = (path, method = "GET", opts = {}) =>
    // fetch(`http://localhost:4000/${path}`, { // localhost server voor dev
    fetch(`https://teamnoppert.ddns.net:4000/${path}`, { // server voor prod
        method,
        credentials: "include",
        mode: "cors",
        cache: method === "GET" ? "default" : "no-cache",
        ...opts,
    });

export const fetchApiSendJson = (path, method = "GET", data) => {
    const token = localStorage.getItem("token");

    const headers = {
        "Content-Type": "application/json",
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return fetchApi(path, method, {
        headers,
        body: JSON.stringify(data),
    });
};

// TODO: werkend maken!
export const fetchMovieDbApi = (path, method = "GET", data) => {
    const headers = {
        accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
    };

    // return fetch(`https://api.themoviedb.org/3/${path}`, {
    //     method,
    //     headers,
    //     body: JSON.stringify(data),
    // });
    // return fetchApi(`https://api.themoviedb.org/3/${path}`, {
    //     method,
    //     headers,
    //     body: JSON.stringify(data),
    // });
    return fetchApi2(`https://api.themoviedb.org/3/${path}`, method, {
        headers,
        body: JSON.stringify(data),
    });
};

export const fetchApi2 = (path, method = "GET", opts = {}) => fetch(`${path}`, { // localhost server voor dev
    method,
    credentials: "include",
    mode: "cors",
    cache: method === "GET" ? "default" : "no-cache",
    ...opts,
});
