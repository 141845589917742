import React from "react";
import * as ReactRedux from "react-redux";
import { Route, Switch } from "react-router-dom";
import { message, Typography } from "antd";
import moment from "moment";
import LinkButton from "../snippets/components/LinkButton";

const UIkit = require("uikit");
const Icons = require("uikit/dist/js/uikit-icons");

UIkit.use(Icons);

export class indexUI extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            window: "test",
            username: "",
            password: "",

            data: null,
            isLoading: true,
            error: null,
        };
        // this.checkServerStatus = this.checkServerStatus.bind(this);
    }

    // componentDidMount() {
    //     this.checkServerStatus();
    // }

    // checkServerStatus() {
    //     fetch("https://api.minetools.eu/ping/kankervetmcserver.ddns.net/25560")
    //         .then((response) => response.json())
    //         .then((data) => {
    //             console.log(data);
    //             this.setState({
    //                 data,
    //                 isLoading: false,
    //                 error: null,
    //             });
    //         })
    //         .catch((error) => {
    //             this.setState({
    //                 data: null,
    //                 isLoading: false,
    //                 error,
    //             });
    //         });
    // }

    render() {
        const { data, isLoading, error } = this.state;

        // if (error) {
        //     return (
        //         <div>
        //             Error:
        //             {error.message}
        //         </div>
        //     );
        // }
        // const onServerAanClick = () => {
        //     this.props.setServerAan();
        //     const sinds = moment(this.props.user.errorStats).format("MMMM Do YYYY, h:mm:ss a");
        //     const status = this.props.user.minecraftStatus;
        //     if (status !== null && sinds !== null || status !== undefined && sinds !== undefined) {
        //         return message.success(`${status} sinds: ${sinds}`);
        //     }
        //     message.success(status);
        //     console.log(this.props.user);
        // };
        // const onServerUitClick = () => {
        //     this.props.setServerUit();
        //     const sinds = moment(this.props.user.errorStats).format("MMMM Do YYYY, h:mm:ss a");
        //     const status = this.props.user.minecraftStatus;
        //     if (status !== null && sinds !== null || status !== undefined && sinds !== undefined) {
        //         return message.success(`${status} sinds: ${sinds}`);
        //     }
        //     message.success(status);
        // };
        // const mc = () => {
        //     const ButtonRefresh = (
        //         <button onClick={this.checkServerStatus}>
        //             Refresh
        //         </button>
        //     );
        //     if (data) {
        //         if (data.error) {
        //             return (
        //                 <div>
        //                     <h1>Server staat uit!</h1>
        //                     {ButtonRefresh}
        //                     <button
        //                         // onClick={onServerAanClick}
        //                         className="uk-button"
        //                         type="button"
        //                     >
        //                         momenteel niet meer mogelijk
        //                     </button>
        //                 </div>
        //             );
        //         }
        //         const players = data.players.sample.map((player) => (
        //             <li className="uk-margin-medium-left" key={player.name}>
        //                 {player.name}
        //             </li>
        //         ));
        //         return (
        //             <div>
        //                 <h1>MC</h1>
        //                 <div>
        //                     <h1>Server Ping:</h1>
        //                     <h2>
        //                         Beschrijving:
        //                         {data.description}
        //                     </h2>
        //                     <h2>
        //                         Players:
        //                         {" "}
        //                         {data.players.online}
        //                         /
        //                         {data.players.max}
        //                     </h2>
        //                     <h2>
        //                         Version:
        //                         {data.version.name}
        //                     </h2>
        //                     <h2>Players online: </h2>
        //                     <ol />
        //                     {players}
        //                     {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        //                     <button
        //                         onClick={onServerUitClick}
        //                         className="uk-button"
        //                         type="button"
        //                     >
        //                         set server uit. pls druk 1x
        //                     </button>
        //
        //                     <button
        //                         onClick={onServerAanClick}
        //                         className="uk-button"
        //                         type="button"
        //                     >
        //                         set server AAN. pls druk 1x
        //                     </button>
        //                 </div>
        //             </div>
        //         );
        //     }
        // };
        return (
            <div>
                <div className="uk-flex uk-margin-large-right uk-margin-large-left">
                    <div className="uk-background-cover uk-height-small uk-margin-large-top uk-margin-large-bottom  uk-panel uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1 uk-width-1-1 uk-width-4-5@s uk-width-3-5@m uk-align-center">
                        {/* <img */}
                        {/*    className="uk-width-expand" */}
                        {/*    src={require("../snippets/images/teamNoppertLogo.png")} */}
                        {/*    alt="" */}
                        {/* /> */}
                    </div>
                </div>

                <div className="uk-card uk-card-default uk-card-body uk-margin-medium-top uk-margin-medium-bottom uk-margin-large-right uk-margin-large-left uka-remove-padding-margin-mobile">
                    <div className="uk-card-body">
                        {/* <LinkButton to="/box/overview" className="tablink"> */}
                        {/*    Overview */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/shareItems" className="tablink"> */}
                        {/*    ShareItems */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/mc" className="tablink"> */}
                        {/*    Minecraft */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/kamerview" className="tablink"> */}
                        {/*    Kamerview */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/led" className="tablink"> */}
                        {/*    Led */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/comments" className="tablink"> */}
                        {/*    Comments */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/water" className="tablink"> */}
                        {/*    Water */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/feedback" className="tablink"> */}
                        {/*    Feedback */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        {/* <LinkButton to="/box/cheatsheet" className="tablink"> */}
                        {/*    Cheatsheet */}
                        {/*    {" "} */}
                        {/* </LinkButton> */}
                        <Typography.Title level={1} className="">In aanbouw! Keer later terug voor TeamNoppert 2.0!</Typography.Title>
                    </div>

                    {/* <div id="tabStyle" className="tabcontent uka-remove-padding-margin-mobile uk-padding-remove uk-width-expand indexBox"> */}
                    {/*    <Switch> */}
                    {/*        <Route path="/" exact component={overview} /> */}
                    {/*        <Route path="/box/" exact component={overview} /> */}
                    {/*        <Route path="/box/overview" exact strict component={overview} /> */}
                    {/*        <Route path="/box/mc" exact strict component={mc} /> */}
                    {/*        <Route path="/box/shareItems" exact strict component={shareItems} /> */}
                    {/*        <Route path="/box/kamerview" exact strict component={kamerview} /> */}
                    {/*        <Route path="/box/led" exact strict component={led} /> */}
                    {/*        <Route path="/box/comments" exact strict component={comments} /> */}
                    {/*        <Route path="/box/water" exact strict component={water} /> */}
                    {/*        <Route path="/box/feedback" exact strict component={feedback} /> */}
                    {/*        <Route path="/box/cheatSheet" exact strict component={cheatSheet} /> */}
                    {/*        <Route path="box/cheatSheet" exact strict component={cheatSheet} /> */}
                    {/*        <Route path="/box/cheatSheet" exact strict component={cheatSheet} /> */}
                    {/*        <Route path="*" component={boxNotFound} /> */}
                    {/*    </Switch> */}
                    {/* </div> */}
                </div>

                <div>
                    {/*  Rest van de pagina */}
                </div>
            </div>
        );
    }
}

const boxNotFound = () => (
    <div>
        <h1>This box is not found.</h1>
    </div>
);

const overview = () => (
    <div>
        <h1>Overview</h1>
    </div>
);
const shareItems = () => (
    <div>
        <h1>shareItems</h1>
    </div>
);
const kamerview = () => (
    <div>
        <h1>kamerview</h1>
    </div>
);
const led = () => (
    <div>
        <h1>led</h1>
    </div>
);
const comments = () => (
    <div>
        <h1>comments</h1>
    </div>
);
const water = () => (
    <div>
        <h1>water</h1>
    </div>
);
const feedback = () => (
    <div>
        <h1>feedback</h1>
    </div>
);
const cheatSheet = () => (
    <div>
        <h1>cheatSheet</h1>
    </div>
);

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

const mapDispatchToProps = {
    // setServerUit,
    // setServerAan,
};

export const Home = ReactRedux.connect(
    mapStateToProps,
    mapDispatchToProps,
)(indexUI);
