import { Link, useLocation, useNavigate } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSideMenuVisibility, toggleSideMenuMedia } from "../../../../reducers/CMSLayout";

export default function BreadcrumbsGenerator({
    routes, skipFirst = false, skipPathName = "", setIsSideMenuVisible,
}) {
    const location = useLocation();
    if (!routes && routes.length === 0) return null;
    const breadcrumbs = useBreadcrumbs(routes);

    const isClickable = (path) => {
        const matchingRoute = routes.find((route) => {
            const regexPattern = route.path.replace(/:\w+/g, "([\\w-]+)");
            return new RegExp(`^${regexPattern}$`).test(path);
        });
        // Als er geen matching route is, of isLastItemClickable is niet gedefinieerd of waar, dan is het klikbaar
        return !matchingRoute || matchingRoute.isLastItemClickable !== false;
    };

    const shouldHideSideMenu = routes.find((route) => {
        const regexPattern = route.path.replace(/:\w+/g, "([\\w-]+)");
        return new RegExp(`^${regexPattern}$`).test(location.pathname) && route.hideMenu;
    });

    useEffect(() => {
        if (shouldHideSideMenu) setIsSideMenuVisible(false);
        else setIsSideMenuVisible(true);
    }, [shouldHideSideMenu]);

    const breadcrumbItems = breadcrumbs.map(({ match, breadcrumb }, index) => {
        if (skipFirst && location.pathname.startsWith(`/${skipPathName}`) && index === 0) {
            return null;
        }

        const breadcrumbElement = typeof breadcrumb === "function" ? breadcrumb() : breadcrumb;
        return {
            href: match.pathname,
            breadcrumb: breadcrumbElement,
            path: match.pathname, // Voeg 'path' toe voor toegang in itemRender
        };
    }).filter((item) => item); // Verwijder null items

    return (
        <Breadcrumb
            style={{ fontSize: "1.15rem" }}
            items={breadcrumbItems.map((item) => ({
                path: item.path,
                breadcrumbName: item.breadcrumb,
            }))}
            itemRender={(route, params, routesMap) => {
                const lastBreadcrumb = routesMap.indexOf(route) === routesMap.length - 1;
                const clickable = isClickable(route.path);

                if (lastBreadcrumb) {
                    return <span style={{ fontWeight: 600 }}>{route.breadcrumbName}</span>;
                }
                if (!clickable) return <span aria-disabled style={{ color: "#999999" }}>{route.breadcrumbName}</span>;
                return <Link to={route.path}>{route.breadcrumbName}</Link>;
            }}
        />
    );
}
