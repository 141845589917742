import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    DatePicker, Form, Image, Input, Rate, Select, Space, Table,
} from "antd";
import moment from "moment/moment";
import {
    CheckOutlined,
    DownloadOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    RotateLeftOutlined, RotateRightOutlined,
    SwapOutlined, UndoOutlined, ZoomInOutlined, ZoomOutOutlined,
} from "@ant-design/icons";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../hooks/AntComponents/useNotification";
import { fetchAllMediaThunk, fetchMedialijstEnums } from "../../../../reducers/Media";
import { Divider } from "../../../reusableComponents/Layout/Divider/Divider";
import { useIsScreenMobileWindowSize, useIsScreenTabletWindowSize } from "../../../../hooks/useCheckWindowSize";
import { checkFetchError, fetchApiSendJson } from "../../../../snippets/functions/fetchFunctions";

const { TextArea } = Input;

function AddLijst() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const notification = useNotification();

    const [form] = Form.useForm();

    const [customPoster, setCustomPoster] = useState(false); // custom poster is als; de gebruiker zelf een poster wilt kiezen, en anders moet kan je kiezen uit de items uit media
    const [customBackdrop, setCustomBackdrop] = useState(null);
    const [allMediaWithPoster, setAllMediaWithPoster] = useState([]);
    const [allMediaWithBackdrop, setAllMediaWithBackdrop] = useState([]);

    const [selectedPoster, setSelectedPoster] = useState(null);
    const [selectedBackdrop, setSelectedBackdrop] = useState(null);

    const isMobile = useIsScreenMobileWindowSize();
    const isTablet = useIsScreenTabletWindowSize();

    const navigate = useNavigate();

    const { user } = useSelector((state) => state.user);
    const {
        mediaLijstEnums, status, media, error,
    } = useSelector((state) => state.media);

    useEffect(() => {
        if (mediaLijstEnums === null) {
            dispatch(fetchMedialijstEnums());
        }

        dispatch(fetchAllMediaThunk({ _id: user._id }));
    }, [mediaLijstEnums, dispatch, user._id]);

    // deze is volgens mijn browser het probleem!
    useEffect(() => {
        if (media?.length > 0) {
            setAllMediaWithPoster(media.filter((m) => m.poster_img));
            setAllMediaWithBackdrop(media.filter((m) => m.backdrop_img));
        }
    }, [media, setAllMediaWithPoster, setAllMediaWithBackdrop]);

    if (!mediaLijstEnums || status === "loadingEnums" || status === "loadingMedia" || !media) {
        return <p>Loading...</p>;
    }

    if (error === "failedLoadingEnums") {
        return <p>{error}</p>;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true);

        try {
            const saveMedialijst = await fetchApiSendJson(`media/lijst/${user._id}`, "POST", { mediaLijst: values });
            const responseSaveMedialijst = await checkFetchError(saveMedialijst);

            if (responseSaveMedialijst._id) {
                notification.success({ title: "Medialijst opgeslagen", message: "Medialijst is opgeslagen" });
                setLoading(false);
                form.resetFields();

                setTimeout(() => {
                    navigate(`/media/lijst/watch/${responseSaveMedialijst._id}`);
                }, 2000);
            } else {
                notification.error({ title: "Medialijst niet opgeslagen", message: "Er is iets fout gegaan bij het opslaan van de medialijst" });
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
            notification.error({ title: "Medialijst niet opgeslagen", message: e.message });
        }
    };

    const settingsPoster = {
        dots: true,
        speed: 300,
        swipeToSlide: true,
        adaptiveHeight: true,
        slidesToShow: isMobile ? 2 : isTablet ? 3 : 4,
        slidesToScroll: isMobile ? 2 : isTablet ? 3 : 4,
        centerPadding: "60px",
        rows: (!isMobile || !isTablet) ? 2 : 1, // dit werkt niet helemaal goed
    };

    const settingsBackdrop = {
        dots: true,
        speed: 300,
        swipeToSlide: true,
        adaptiveHeight: true,
        slidesToShow: isMobile ? 2 : isTablet ? 2 : 3,
        slidesToScroll: isMobile ? 2 : isTablet ? 2 : 3,
        centerPadding: "60px",
        rows: (!isMobile || !isTablet) ? 2 : 1, // dit werkt niet helemaal goed
    };

    const onDownload = (imgUrl) => {
        fetch(imgUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = `${url}.png`;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
            });
    };

    const onSelectPoster = (imgUrl) => {
        if (imgUrl) {
            form.setFieldsValue({ poster_img: imgUrl });
            setSelectedPoster(imgUrl);
        }
    };
    const onSelectBackdrop = (imgUrl) => {
        if (imgUrl) {
            form.setFieldsValue({ backdrop_img: imgUrl });
            setSelectedBackdrop(imgUrl);
        }
    };

    return (
        <Form
            form={form}
            name="create_medialijst"
            className="mt-8 mb-8 space-y-4"
            scrollToFirstError
            disabled={status === "loading" || !mediaLijstEnums}
            initialValues={{}}
            onFinish={handleSubmit}
            onFinishFailed={(errorInfo) => {
                // console.log("Failed:", errorInfo);
            }}
            layout="vertical"
            size="medium"
            labelAlign="right"
        >
            <div className="gap-4 xxl:gap-12">
                <div className="container mx-auto px-4">
                    <div className="md:col-span-2">
                        <div className="space-y-6 gap-0 xxl:gap-8 xxl:gap-y-12">
                            <div
                                className="md:grid-cols-2 gap-x-6 xxl:gap-x-12 xxl:gap-y-12"
                            >
                                <div className="space-y-6 gap-0 xxl:gap-8 xxl:gap-y-12">
                                    {/* Items big */}
                                    <Form.Item
                                        name="genre"
                                        label="genre"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="genre"
                                            maxTagCount="responsive"
                                        >
                                            {mediaLijstEnums.genres.map((m) => (
                                                <Select.Option key={m} value={m}>{m}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="laatsteMediaGekeken"
                                        label="laatsteMediaGekeken"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="media"
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {media.map((m) => (
                                                <Select.Option
                                                    key={m._id}
                                                    value={m._id}
                                                    label={m.titel}
                                                >
                                                    {m.titel}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="media"
                                        label="media"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="media"
                                            maxTagCount="responsive"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {media.map((m) => (
                                                <Select.Option key={m._id} value={m._id} label={m.titel}>
                                                    <Space>
                                                        {m.imageUrl || m.poster_img && (
                                                            <img
                                                                src={m.poster_img || m.imageUrl}
                                                                alt={m.titel}
                                                                style={{ height: "100px" }}
                                                            />
                                                        )}
                                                        {m.titel}
                                                    </Space>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    {!customPoster && (
                                        <div>
                                            <Form.Item
                                                label="Poster"
                                                name="poster_img"
                                                className="form-item mb-12"
                                                rules={[{ required: true, message: "Selecteer een poster" }]}
                                            >
                                                <Image.PreviewGroup preview={{
                                                    toolbarRender: (
                                                        _,
                                                        {
                                                            image: { url },
                                                            transform: { scale },
                                                            actions: {
                                                                onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset, onClose,
                                                            },
                                                        },
                                                    ) => (
                                                        <Space size={18} className="toolbar-wrapper max-w-full">
                                                            <DownloadOutlined
                                                                style={{ fontSize: "28px", padding: "4px", border: "1px solid gray" }}
                                                                onClick={() => {
                                                                    onDownload(url);
                                                                    onClose();
                                                                }}
                                                            />
                                                            <CheckOutlined
                                                                style={{
                                                                    color: "green", fontSize: "28px", padding: "4px", border: "1px solid gray",
                                                                }}
                                                                onClick={() => {
                                                                    onSelectPoster(url);
                                                                    onClose();
                                                                }}
                                                            />
                                                            <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onFlipX} />
                                                            <RotateLeftOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateLeft} />
                                                            <RotateRightOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateRight} />
                                                            <ZoomOutOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onReset} />
                                                        </Space>
                                                    ),
                                                }}
                                                >
                                                    <Slider {...settingsPoster}>
                                                        {allMediaWithPoster.map((poster) => (
                                                            <div key={poster}>
                                                                <Image
                                                                    src={poster.poster_img}
                                                                    alt={poster.titel}
                                                                    className={`object-cover w-full h-full ${selectedPoster === poster.poster_img ? "border-4 border-green-400" : "border-2 border-gray-200"}`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </Image.PreviewGroup>
                                            </Form.Item>

                                            <Form.Item
                                                label="Backdrop"
                                                name="backdrop_img"
                                                className="form-item"
                                                rules={[{ required: true, message: "Selecteer een backdrop" }]}
                                            >
                                                <Image.PreviewGroup preview={{
                                                    toolbarRender: (
                                                        _,
                                                        {
                                                            image: { url },
                                                            transform: { scale },
                                                            actions: {
                                                                onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset, onClose,
                                                            },
                                                        },
                                                    ) => (
                                                        <Space size={18} className="toolbar-wrapper max-w-full">
                                                            <DownloadOutlined
                                                                style={{ fontSize: "28px", padding: "4px", border: "1px solid gray" }}
                                                                onClick={() => {
                                                                    onDownload(url);
                                                                    onClose();
                                                                }}
                                                            />
                                                            <CheckOutlined
                                                                style={{
                                                                    color: "green", fontSize: "28px", padding: "4px", border: "1px solid gray",
                                                                }}
                                                                onClick={() => {
                                                                    onSelectBackdrop(url);
                                                                    onClose();
                                                                }}
                                                            />
                                                            <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onFlipX} />
                                                            <RotateLeftOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateLeft} />
                                                            <RotateRightOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateRight} />
                                                            <ZoomOutOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onReset} />
                                                        </Space>
                                                    ),
                                                }}
                                                >
                                                    <Slider {...settingsPoster}>
                                                        {allMediaWithBackdrop.map((backdrop) => (
                                                            <div key={backdrop}>
                                                                <Image
                                                                    src={backdrop.backdrop_img}
                                                                    alt={backdrop.titel}
                                                                    className={`object-cover w-full h-full ${selectedBackdrop === backdrop.backdrop_img ? "border-4 border-green-400" : "border-2 border-gray-200"}`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </Image.PreviewGroup>
                                            </Form.Item>
                                        </div>
                                    )}

                                    {customPoster && (
                                        <h1>wel custom, als in zelf echt link kiezen custom</h1>
                                    )}

                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 xxl:gap-x-12 gap-y-6">
                                {/* items small */}
                                <Form.Item
                                    name="titel"
                                    label="titel"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vul een titel in",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="beschrijving"
                                    label="Beschrijving"
                                    rules={[{ required: false, message: "" }]}
                                >
                                    <TextArea
                                        placeholder="Beschrijving"
                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                        allowClear
                                    />
                                </Form.Item>

                                {/* laatste keer gekeken */}
                                <Form.Item
                                    label="Laatste keer gekeken"
                                    name="laatsteKeerGekeken"
                                    className="form-item"
                                >
                                    <DatePicker className="w-full" />
                                </Form.Item>

                                {/* gestart met kijken */}
                                <Form.Item
                                    label="Gestart met kijken"
                                    name="gestartMetKijken"
                                    className="form-item"
                                >
                                    <DatePicker className="w-full" />
                                </Form.Item>

                                <Form.Item
                                    label="Kijk status"
                                    name="kijkStatus"
                                    className="form-item"
                                    rules={[{ required: true, message: "Selecteer een kijk status" }]}
                                >
                                    <Select className="">
                                        {mediaLijstEnums.kijkStatus.map((m) => (
                                            <Select.Option key={m} value={m}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Kijk type"
                                    name="kijkType"
                                    className="form-item"
                                    rules={[{ required: true, message: "Selecteer een kijk type" }]}
                                >
                                    <Select className="">
                                        {mediaLijstEnums.kijkType.map((m) => (
                                            <Select.Option key={m} value={m}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Prioriteit"
                                    name="prioriteit"
                                    className="form-item"
                                    rules={[{ required: true, message: "Selecteer een prioriteit" }]}
                                >
                                    <Select className="">
                                        {mediaLijstEnums.prioriteit.map((m) => (
                                            <Select.Option key={m} value={m}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="tags"
                                    label="Tags"
                                    rules={[{ required: false, message: "" }]}
                                >
                                    <Form.List name="tags">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space
                                                        key={key}
                                                        style={{ display: "flex", marginBottom: 4 }}
                                                        align="baseline"
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "tag"]}
                                                            rules={[{ required: true, message: "Missende tag" }]}
                                                        >
                                                            <Input placeholder="Tag" />
                                                        </Form.Item>
                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        disabled
                                                        onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add tags
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>

                                {/* rating */}
                                <Form.Item
                                    name="Rating"
                                    label="rating"
                                >
                                    <Rate allowClear allowHalf />
                                </Form.Item>

                                {/* TODO: deze staat nog niet in de edit media lijst modal!     */}
                                <Form.Item
                                    label="Soort lijst"
                                    name="soortLijst"
                                    className="form-item"
                                    rules={[{ required: true, message: "Selecteer een soort lijst" }]}
                                >
                                    <Select className="">
                                        {mediaLijstEnums.soortLijst.map((m) => (
                                            <Select.Option key={m} value={m}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Divider />

                                <Space>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Sla op
                                    </Button>
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            // form.resetFields();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default AddLijst;
