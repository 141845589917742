import {
    Button, Col, Collapse, ConfigProvider, Form, Image, Input, Modal, Row, Space, Statistic, Tag, Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { StarFilled } from "@ant-design/icons";
import moment from "moment";
import { PlayCircleRounded } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../../../../hooks/AntComponents/useNotification";
import {
    GetCrew, GetDetails, GetImages, GetTrailers,
} from "../../../../../functions/TheMovieDB/GetInfo";
import { useIsScreenMobileWindowSize, useScreenSize } from "../../../../../hooks/useCheckWindowSize";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Header } from "../../../../reusableComponents/Text/Header/Header";
import { GetMeningTag } from "../../Tags/Tags";
import { TheMovieDB_MediaTypes } from "../../../../../enum/TheMovieDB";

import "./MediaDetailsModal.css";
import { useDarkMode } from "../../../../../utils/Darkmode";
import { Divider } from "../../../../reusableComponents/Layout/Divider/Divider";
import { fetchAllMediaThunk, fetchMediaEnums, fetchmediaEnums } from "../../../../../reducers/Media";
import TitleInfo from "./InfoComponents/TitleInfo";
import ContentInfo from "./InfoComponents/ContentInfo";
import MediaInfoPanel from "./FormComponents/MediaInfoPanel";
import MediaDetailsPanel from "./FormComponents/MediaDetailsPanel";
import AddToTablePanel from "./FormComponents/AddToTablePanel";
import { checkFetchError, fetchApi, fetchApiSendJson } from "../../../../../snippets/functions/fetchFunctions";
import ImagesPanel from "./FormComponents/ImagesPanel";

const { Text, Title } = Typography;

export function MediaDetailsModal({
    visible,
    onClose,
    id,
    mediaType,
    media,
    returnMovieDbId = false,
    setMovieDbId,
}) {
    const [form] = Form.useForm();
    const [formChanged, setFormChanged] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const notification = useNotification();

    const [movieDbDetails, setMovieDbDetails] = useState(null);
    const [movieDbPoster, setMovieDbPoster] = useState(null);
    const [movieDbImages, setMovieDbImages] = useState(null);
    const [carouselImages, setCarouselImages] = useState(null);
    const [trailerUrl, setTrailerUrl] = useState(null);
    const [crew, setCrew] = useState(null);
    const [actualType, setActualType] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        async function getDetails() {
            let type = mediaType;
            if (mediaType === "multi") {
                if (media.title) {
                    type = "movie";
                    setActualType("movie");
                } else if (media.name) {
                    type = "tv";
                    setActualType("tv");
                } else {
                    console.error("Unknown media type");
                    return;
                }
            }
            if (!id || !visible) return;
            try {
                setMovieDbDetails(await GetDetails(type, id));
                const images = await GetImages(type, id);
                // setCarouselImages((await GetImages(type, id)).images);
                setMovieDbImages(images.images);
                setMovieDbPoster(images.posters);
                setCarouselImages(images.backdrops);
            } catch (error) {
                notification.error("Er is iets fout gegaan bij het ophalen van de details van de film");
                console.error(error);
            }

            try {
                const trailers = await GetTrailers(type, id);
                const { officialTrailers } = trailers;
                if (officialTrailers) {
                    setTrailerUrl(officialTrailers);
                    // setTrailerUrl(`https://www.youtube.com/watch?v=${officialTrailer.key}`);
                } else {
                    console.log("No official trailer found");
                }
            } catch (error) {
                console.log("No trailer found");
            }

            try {
                const crewInfo = await GetCrew(type, id);
                const { fullCrewInfo } = crewInfo;
                if (fullCrewInfo) {
                    setCrew(fullCrewInfo);
                } else {
                    console.log("No crew found");
                }
            } catch (error) {
                console.log("No crew found");
            }

            try {

            } catch (error) {

            }
        }

        getDetails();
    }, [visible, id, mediaType, media]);

    const resetFields = () => {
        form.resetFields();
        setFormChanged(false);
        setMovieDbImages(null);
        setMovieDbPoster(null);
        setCarouselImages(null);
        setTrailerUrl(null);
        setCrew(null);
        setMovieDbDetails(null);
        setActualType(null);
    };

    useEffect(() => {
        if (!visible) {
            setFormChanged(false);
            resetFields();
        }
    }, [visible, form, id, formChanged]);

    const onValuesChange = () => {
        setFormChanged(true);
    };

    const handleSubmit = async (values) => {
        if (!values) {
            return notification.info({ message: "Geen gegevens gevonden" });
        }

        setConfirmLoading(true);

        try {
            const data = form.getFieldsValue();

            const createMedia = await fetchApiSendJson(`media/${user._id}`, "POST", { mediaInfo: data });
            await checkFetchError(createMedia);

            setTimeout(() => {
                setConfirmLoading(false);
                onClose();

                notification.success({ message: "Media toegevoegd", description: "Media is toegevoegd aan je geselecteerde lijst" });
            }, [300]);
        } catch (error) {
            console.error(error);
            notification.error({ message: "Er is iets fout gegaan", description: error.message });
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        if (formChanged) {
            handleSubmit(form.getFieldsValue());
        } else {
            onClose();
        }
    };

    const settingsTrailer = {
        dots: true,
        speed: 300,
        swipeToSlide: true,
        adaptiveHeight: true,
        slidesToShow: trailerUrl?.length > 1 ? 2 : 1,
        slidesToScroll: 2,
    };
    const isMobile = useIsScreenMobileWindowSize();
    const { darkMode } = useDarkMode();

    useEffect(() => {
        if (movieDbDetails) {
            form.setFieldsValue({
                titel: movieDbDetails.title || movieDbDetails.name,
                release_date: movieDbDetails.release_date,
                runtime: movieDbDetails.runtime,
                genres: movieDbDetails.genres,
                overview: movieDbDetails.overview,
                notities: movieDbDetails.overview,
                genre: movieDbDetails.genres.map((genre) => genre.name),
                themoviedb_type: actualType,
                themoviedb_id: id,
                jaar: (movieDbDetails.release_date ? moment(movieDbDetails.release_date).year() : movieDbDetails.first_air_date ? moment(movieDbDetails.first_air_date).year() : undefined),
            });
        }
    }, [movieDbDetails, form]);

    const {
        mediaEnums, status, error,
    } = useSelector((state) => state.media);

    useEffect(() => {
        if (mediaEnums) {
            form.setFieldsValue({
                mening: mediaEnums.mening[0],
                prioriteit: mediaEnums.prioriteit[0],
                genre: { ...mediaEnums.genre },
            });
        }
    }, [mediaEnums, form]);

    useEffect(() => {
        if (!mediaEnums || mediaEnums.length === 0 || status === "loadingEnums") {
            setLoading(true);
        }

        if (status === "enumsLoaded") {
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }, [mediaEnums, status]);

    useEffect(() => {
        if (mediaEnums === null) {
            dispatch(fetchMediaEnums());
        }
    }, []);

    const [alreadyAdded, setAlreadyAdded] = useState(false);
    useEffect(() => {
        async function getAlreadyAdded() {
            if (movieDbDetails) {
                try {
                    const titel = form.getFieldsValue("titel");
                    const fetchAlreadyAdded = await fetchApiSendJson(`media/check/${id}/${titel.titel}/${actualType}/${user._id}`, "GET");

                    await checkFetchError(fetchAlreadyAdded);
                    setAlreadyAdded(false);
                } catch (error) {
                    setAlreadyAdded(true);
                    console.log(error);
                    notification.error({ message: "Media is al toegevoegd", description: "Deze media is al toegevoegd aan een van je lijsten" });
                }
            }
        }

        getAlreadyAdded();
    }, [movieDbDetails, setAlreadyAdded, id, actualType]);

    if (!mediaEnums || status === "loadingEnums") {
        return <p>Loading...</p>;
    }

    if (error === "failedLoadingEnums") {
        return <p>{error}</p>;
    }

    const panels = () => [
        {
            key: "1",
            label: <div className="panel-header"><Title level={2}>Standard media info</Title></div>,
            children: (
                <MediaInfoPanel form={form} enums={mediaEnums} />
            ),
        },
        {
            key: "2",
            label: <div className="panel-header"><Title level={2}>Media details</Title></div>,
            children: (
                <MediaDetailsPanel form={form} enums={mediaEnums} />
            ),
        },
        {
            key: "55555",
            label: <div className="panel-header">
                <Title level={2}>
                    Media data (sources, kijk progressie)
                </Title>
            </div>,
            children: (
                <div className="panel-content">
                    <Form.Item
                        label="Titel"
                        name="titel"
                        className="form-item"
                    >
                        <Input />
                    </Form.Item>
                </div>
            ),
        },
        {

            key: "3",
            label: <div className="panel-header"><Title level={2}>Voeg toe aan</Title></div>,
            children: (
                <div className="panel-content">
                    <AddToTablePanel form={form} />

                    {/*    Voeg toe aan; selectable tabel en dan knopne onderaan met save.  */}
                    {/*    Denk aan checks; bestaat die al in die lijst?, dat ophalen! opbasis van titel en moviedb id! */}

                    {/*    weghalen uit die lijst misschien?  en anders disable dat je niks kan! dan mee geven aan de server; alle lijsten waar hij inzat + nu de nieuwe lijsten
                     in de server controleren wat is het verschil en haal hem daar uit. wel popconfirm voor de gene die weggaan  */}
                </div>
            ),
        },
        {
            key: "4",
            label: <div className="panel-header"><Title level={2}>Afbeeldingen</Title></div>,
            children: (
                <ImagesPanel form={form} images={movieDbImages} />
            ),
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        // contentBg: "#1f1f1f",
                        headerBg: "#1f1f1f",
                        // footerBg: "#fff",
                        titleColor: "#fff",
                    },
                },
            }}
        >
            <Modal
                className={`${!darkMode ? "LightMode" : ""} mediaDetailsModal`}
                open={visible}
                centered
                onCancel={handleCancel}
                okButtonProps={{
                    htmlType: "submit",
                }}
                width={isMobile ? "100%" : 1300}
                title={(
                    <TitleInfo
                        HeaderText={movieDbDetails?.title || movieDbDetails?.name}
                        Rating={movieDbDetails && movieDbDetails?.vote_average ? movieDbDetails?.vote_average.toFixed(2) : ""}
                        PosterSrc={movieDbPoster ? `https://image.tmdb.org/t/p/original${movieDbPoster[0].file_path}` : "https://via.placeholder.com/300"}
                        PosterAlt={movieDbDetails ? movieDbDetails.title : "placeholder"}
                        CarouselImages={carouselImages}
                        Genres={movieDbDetails?.genres}
                        Cast={crew?.cast}
                        Crew={crew?.crew}
                        actualType={actualType}
                        movieDbDetails={movieDbDetails}
                    />
                )}
                footer={(
                    <Space className="p-3">
                        <Button onClick={() => console.log("navigatie to edit page")} type="link">Edit</Button>
                        <Button onClick={handleCancel}>Sluit</Button>
                        {returnMovieDbId && (
                            <Button
                                onClick={() => {
                                    setMovieDbId(id, movieDbDetails);
                                    onClose();
                                }}
                                type="secondary"
                            >
                                Gebruik deze
                            </Button>
                        )}
                        <Button type="primary" htmlType="submit" loading={confirmLoading} disabled={alreadyAdded}>Sla op</Button>
                    </Space>
                )}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        form={form}
                        name="save_media"
                        onValuesChange={onValuesChange}
                        initialValues={{
                            titel: movieDbDetails?.title || movieDbDetails?.name,
                            rating: 0,
                            release_date: movieDbDetails?.release_date,
                            runtime: movieDbDetails?.runtime,
                            genres: movieDbDetails?.genres,
                            overview: movieDbDetails?.overview,
                            mening: mediaEnums?.mening ? mediaEnums.mening[0] : null,
                            prioriteit: mediaEnums?.prioriteit ? mediaEnums.prioriteit[0] : null,
                            notities: movieDbDetails?.overview,
                            genre: movieDbDetails?.genres,
                            themoviedb_type: actualType,
                            themoviedb_id: id,
                            addToLists: [],
                            // misschien later; mediaData, als in kijk info, hoever enz
                            // sources!
                        }}
                        onFinish={handleSubmit}
                        size="medium"
                    >
                        {dom}
                    </Form>
                )}
            >
                <ContentInfo
                    overview={movieDbDetails?.overview}
                    trailerUrl={trailerUrl}
                    settingsTrailer={settingsTrailer}
                />

                <Collapse
                    defaultActiveKey={["1", "2", "4"]}
                    ghost
                    items={panels()}
                />

            </Modal>
        </ConfigProvider>
    );
}
