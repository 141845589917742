import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    ConfigProvider,
    Drawer, Layout, Menu, theme,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./cmsLayout.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { useDispatch } from "react-redux";
import BreadcrumbsGenerator from "./cmsLayoutComponents/BreadcrumbGenerator";
import SideComponent from "./cmsLayoutComponents/SideComponent";
import { openSideMenuMedia, toggleSideMenuMedia } from "../../../reducers/CMSLayout";
import { useDarkMode } from "../../../utils/Darkmode";
import { useIsScreenMobileWindowSize, useScreenSize } from "../../../hooks/useCheckWindowSize";
import { BigBanner } from "../Visual/BigBanner/BigBanner";

const {
    Header, Content, Footer, Sider,
} = Layout;

export function CMSLayout({
    isSideMenuVisible,
    setIsSideMenuVisible,
    hasBackgroundColor = false,
    defaultPageHeader,
    menuItems,
    isBreadcrumbSticky = false,
    showBreadcrumbAllSizes,
    breadcrumbRoutes,
    breadcrumbSkipFirst = false,
    breadcrumbSkipPathName = "",
    footer,
    showFooter = false,
    defaultSelectedKeys,
    content,
    headerContent, // Dit is zodat je de crumbs onder de header kan zetten
    breadcrumbsAboveHeaderContent = false,
    showBreadcrumbWhenMobile = true,
    showSideMenuWhenMobile = true,
    openMenuInSideDrawerWhenMobile = true,
    drawerMenuVisible,
    toogleSideMenu,
    customPageHeader,
    overviewImage,
    bigBannerImg,
    bigBannerTitle,
    bigBannerDescription,
    bigBannerContent,
    bigBannerOnPlayClick,
    bigBannerOnInfoClick,
    // scrollable,
    // size,
    className,
    ...rest
}) {
    // TODO: netjes maken natuurlijk
    // TODO: meer logic voor page header, page title enz
    // TODO: als je een big banner hebt, dan krijg je het probleem bij switchten van content/ component, dus locatie/ route,
    //  je op een kut scroll plek wordt gestopt. dit moet ik fixen, scroll helemaal omhoog ofzo

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const screenSize = useScreenSize();
    const isMobileSize = useIsScreenMobileWindowSize();

    const handlers = useSwipeable({
        onSwipedRight: () => {
            if (isMobileSize) toogleSideMenu();
        },
        delta: isMobileSize ? 125 : 300, // Minimale afstand die moet worden afgelegd om als swipe te worden beschouwd
        preventScrollOnSwipe: true, // Voorkom scrollen tijdens swipen
        rotationAngle: 25, // Hoek waaronder de swipe nog steeds als horizontaal wordt beschouwd
        swipeDuration: Infinity, // Tijd die de swipe mag duren
    });

    const { darkMode } = useDarkMode();
    const location = useLocation();

    const [shouldShowOverviewImage, setShouldShowOverviewImage] = useState(true);
    useEffect(() => {
        setShouldShowOverviewImage(location.pathname === (`/${breadcrumbSkipPathName}`));
    }, [location, shouldShowOverviewImage]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        colorBgLayout: "",
                    },
                },
            }}
        >

            {/* TODO: dynamisch! */}
            {overviewImage && shouldShowOverviewImage && (
                <BigBanner
                    title={bigBannerTitle}
                    contentTitle={bigBannerContent}
                    description={bigBannerDescription}
                    imageUrl={bigBannerImg}
                    onPlayclick={bigBannerOnPlayClick}
                    onInfoClick={bigBannerOnInfoClick}
                />
            )}
            {!overviewImage && shouldShowOverviewImage && (
                <BigBanner
                    title="Als laatst bekeken"
                    contentTitle="Top gear"
                    description="Test dies cwdajdwa jwad jwd ajdw ajwd aw daj wdajwd ajwad j awdj adwj adwj adwjd awj"
                    imageUrl="https://image.tmdb.org/t/p/original/AtYDkNpqthD83nVGZgvEE1DyYv4.jpg"
                />
            )}

            <Layout hasSider {...handlers} style={{ marginBottom: "12px", overflow: "visible" }} className="marginsBody marginsBodySmall">
                {menuItems && (
                    <SideComponent
                        isSideMenuVisible={isSideMenuVisible}
                        setIsSideMenuVisible={setIsSideMenuVisible}
                        menuItems={menuItems}
                        showSideMenuWhenMobile={showSideMenuWhenMobile}
                        background={colorBgContainer}
                        openMenuInSideDrawerWhenMobile={openMenuInSideDrawerWhenMobile}
                        defaultSelectedKeys={defaultSelectedKeys}
                        drawerMenuVisible={drawerMenuVisible}
                        toogleSideMenu={toogleSideMenu}
                    />
                )}

                <Layout
                    style={{ padding: isMobileSize ? "3px 6px 6px" : "12px 24px 24px" }}
                    // CMSbackgroundColor bij ""
                    className={hasBackgroundColor && !darkMode && ""}
                >
                    {/* <CMSHeader style={{ background: "none" }} header={header} colorBgContainer={colorBgContainer} /> */}
                    <Layout>
                        {!breadcrumbsAboveHeaderContent && (
                            <CMSHeader header={defaultPageHeader} colorBgContainer={colorBgContainer} customPageHeader={customPageHeader} />
                        )}

                        {breadcrumbRoutes && (
                            <CMSBreadcrumb
                                style={{ padding: "12px" }}
                                breadcrumbRoutes={breadcrumbRoutes}
                                breadcrumbSkipFirst={breadcrumbSkipFirst}
                                breadcrumbSkipPathName={breadcrumbSkipPathName}
                                isBreadcrumbSticky={isBreadcrumbSticky}
                                setIsSideMenuVisible={setIsSideMenuVisible}
                            />
                        )}
                        <Content style={{
                            padding: 16,
                            minHeight: "80vh",
                            background: !darkMode ? "white" : "",
                            borderRadius: "5px",
                            overflow: "visible",
                            border: darkMode && "0.2px solid #e8e8e8",
                            // maxHeight: "80vh",
                        }}
                        >
                            {/* <CMSHeader header={header} colorBgContainer={colorBgContainer} /> */}
                            {breadcrumbsAboveHeaderContent && (
                                <CMSHeader header={defaultPageHeader} colorBgContainer={colorBgContainer} customPageHeader={customPageHeader} />
                            )}
                            {/* <p>long content</p> */}
                            {/* { */}
                            {/*    Array.from( */}
                            {/*        { */}
                            {/*            length: 100, */}
                            {/*        }, */}
                            {/*        (_, index) => ( */}
                            {/*            <React.Fragment key={index}> */}
                            {/*                {index % 20 === 0 && index ? "more" : "..."} */}
                            {/*                <br /> */}
                            {/*            </React.Fragment> */}
                            {/*        ), */}
                            {/*    ) */}
                            {/* } */}
                            {content}
                        </Content>
                    </Layout>

                    <Footer>
                        {footer}
                    </Footer>
                </Layout>
            </Layout>
        </ConfigProvider>
    );
}

function CMSHeader({ header, colorBgContainer, customPageHeader }) {
    // console.log("customPageHeader", customPageHeader);
    const { darkMode } = useDarkMode();
    if (customPageHeader && customPageHeader !== "") {
        return (
            <Header
                style={{
                    padding: 0,
                    background: !darkMode ? colorBgContainer : "black",
                    position: "sticky",
                }}
                className="kanjers"
            >
                {customPageHeader}
            </Header>
        );
    }
    if (header) {
        return (
            <Header
                style={{
                    padding: 0,
                    background: colorBgContainer,
                    position: "sticky",
                }}

                className="kanjers"
            >
                {header}
            </Header>
        );
    }
}

function CMSBreadcrumb({
    breadcrumbRoutes, breadcrumbSkipFirst, breadcrumbSkipPathName, isBreadcrumbSticky, setIsSideMenuVisible,
}) {
    return (
        <div style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            width: "100%",
            paddingBottom: "12px",
            position: isBreadcrumbSticky && "sticky",
        }}
        >
            <BreadcrumbsGenerator
                className="breadcrumb"
                routes={breadcrumbRoutes}
                skipFirst={breadcrumbSkipFirst}
                skipPathName={breadcrumbSkipPathName}
                setIsSideMenuVisible={setIsSideMenuVisible}
            />
        </div>
    );
}
