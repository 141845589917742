import {
    Table, Form, Select, Image, Space,
} from "antd";
import Slider from "react-slick";
import React, { useState } from "react";
import {
    CheckOutlined,
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined, UndoOutlined, ZoomInOutlined,
    ZoomOutOutlined,
} from "@ant-design/icons";
import { useIsScreenMobileWindowSize, useIsScreenTabletWindowSize } from "../../../../../../hooks/useCheckWindowSize";

function ImagesPanel({
    form, images, alreadySelectedPoster, alreadySelectedBackdrop,
}) {
    if (!images) {
        return null;
    }

    const isMobile = useIsScreenMobileWindowSize();
    const isTablet = useIsScreenTabletWindowSize();

    const settingsPoster = {
        dots: true,
        speed: 300,
        swipeToSlide: true,
        adaptiveHeight: true,
        slidesToShow: isMobile ? 2 : isTablet ? 3 : 4,
        slidesToScroll: isMobile ? 2 : isTablet ? 3 : 4,
        centerPadding: "60px",
        rows: (!isMobile || !isTablet) ? 2 : 1, // dit werkt niet helemaal goed
    };

    const settingsBackdrop = {
        dots: true,
        speed: 300,
        swipeToSlide: true,
        adaptiveHeight: true,
        slidesToShow: isMobile ? 2 : isTablet ? 2 : 3,
        slidesToScroll: isMobile ? 2 : isTablet ? 2 : 3,
        centerPadding: "60px",
        rows: (!isMobile || !isTablet) ? 2 : 1, // dit werkt niet helemaal goed
    };

    const onDownload = (imgUrl) => {
        fetch(imgUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = `${url}.png`;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
            });
    };

    const [selectedPoster, setSelectedPoster] = useState(alreadySelectedPoster);
    const [selectedBackdrop, setSelectedBackdrop] = useState(alreadySelectedBackdrop);
    const onSelectPoster = (imgUrl) => {
        if (imgUrl) {
            form.setFieldsValue({ poster_img: imgUrl });
            setSelectedPoster(imgUrl);
        }
    };
    const onSelectBackdrop = (imgUrl) => {
        if (imgUrl) {
            form.setFieldsValue({ backdrop_img: imgUrl });
            setSelectedBackdrop(imgUrl);
        }
    };

    return (
        <div className="p-5">
            <div className="">
                <Form.Item
                    label="Poster"
                    name="poster_img"
                    className="form-item mb-12"
                    rules={[{ required: true, message: "Selecteer een poster" }]}
                >
                    <Image.PreviewGroup preview={{
                        toolbarRender: (
                            _,
                            {
                                image: { url },
                                transform: { scale },
                                actions: {
                                    onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset, onClose,
                                },
                            },
                        ) => (
                            <Space size={18} className="toolbar-wrapper max-w-full">
                                <DownloadOutlined
                                    style={{ fontSize: "28px", padding: "4px", border: "1px solid gray" }}
                                    onClick={() => {
                                        onDownload(url);
                                        onClose();
                                    }}
                                />
                                <CheckOutlined
                                    style={{
                                        color: "green", fontSize: "28px", padding: "4px", border: "1px solid gray",
                                    }}
                                    onClick={() => {
                                        onSelectPoster(url);
                                        onClose();
                                    }}
                                />
                                <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} rotate={90} onClick={onFlipY} />
                                <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onFlipX} />
                                <RotateLeftOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateLeft} />
                                <RotateRightOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateRight} />
                                <ZoomOutOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 1} onClick={onZoomOut} />
                                <ZoomInOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 50} onClick={onZoomIn} />
                                <UndoOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onReset} />
                            </Space>
                        ),
                    }}
                    >
                        <Slider {...settingsPoster}>
                            {images?.posters && images.posters.map((poster) => (
                                <div key={poster.file_path}>
                                    <Image
                                        src={`https://image.tmdb.org/t/p/original${poster.file_path}`}
                                        alt={poster.file_path}
                                        // className={`'object-cover border-2 border-gray-200' ${selectedPoster === `https://image.tmdb.org/t/p/original${poster.file_path}` ? "border-blue-500" : ""}
                                        className={`object-cover w-full h-full ${selectedPoster === `https://image.tmdb.org/t/p/original${poster.file_path}` ? "border-4 border-green-400" : "border-2 border-gray-200"}`}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </Image.PreviewGroup>
                </Form.Item>
            </div>

            <Form.Item
                label="Backdrop"
                name="backdrop_img"
                className="form-item"
                rules={[{ required: true, message: "Selecteer een backdrop" }]}
            >
                <Image.PreviewGroup preview={{
                    toolbarRender: (
                        _,
                        {
                            image: { url },
                            transform: { scale },
                            actions: {
                                onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset, onClose,
                            },
                        },
                    ) => (
                        <Space size={24} className="toolbar-wrapper">
                            <DownloadOutlined
                                style={{ fontSize: "28px", padding: "4px", border: "1px solid gray" }}
                                onClick={() => {
                                    onDownload(url);
                                    onClose();
                                }}
                            />
                            <CheckOutlined
                                style={{
                                    color: "green", fontSize: "28px", padding: "4px", border: "1px solid gray",
                                }}
                                onClick={() => {
                                    onSelectBackdrop(url);
                                    onClose();
                                }}
                            />
                            <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} rotate={90} onClick={onFlipY} />
                            <SwapOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onFlipX} />
                            <RotateLeftOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateLeft} />
                            <RotateRightOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onRotateRight} />
                            <ZoomOutOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined style={{ fontSize: "28px", padding: "4px" }} disabled={scale === 50} onClick={onZoomIn} />
                            <UndoOutlined style={{ fontSize: "28px", padding: "4px" }} onClick={onReset} />
                        </Space>
                    ),
                }}
                >
                    <Slider {...settingsBackdrop}>
                        {images?.backdrops && images.backdrops.map((backdrop) => (
                            <div key={backdrop.file_path}>
                                <Image
                                    src={`https://image.tmdb.org/t/p/original${backdrop.file_path}`}
                                    alt={backdrop.file_path}
                                    className={`object-cover w-full h-full ${selectedBackdrop === `https://image.tmdb.org/t/p/original${backdrop.file_path}` ? "border-4 border-green-400" : "border-2 border-gray-200"}`}
                                />
                            </div>
                        ))}
                    </Slider>
                </Image.PreviewGroup>
            </Form.Item>
        </div>
    );
}

export default ImagesPanel;
