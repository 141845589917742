import { useSearchParams } from "react-router-dom";
import { MEDIA_VIEW_OPTION, ORDER, ORDER_OPTIONS } from "../../enum";

export const DEFAULTS = {
    view: MEDIA_VIEW_OPTION.CARD,
    order: ORDER.ASC,
    orderOption: ORDER_OPTIONS.LAATST_BEKEKEN,
};

export function getSearchParams(searchParams) {
    return {
        view: searchParams.get("view") || DEFAULTS.view,
        orderOption: searchParams.get("orderOption") || DEFAULTS.orderOption,
        order: searchParams.get("order") || DEFAULTS.order,
        mening: searchParams.get("mening") || "",
        titel: searchParams.get("titel") || "",
        prio: searchParams.get("prio") || "",
        laatsteKeerGekeken: searchParams.get("laatsteKeerGekeken") || "",
        kijkStatus: searchParams.get("kijkStatus") || "",
        type: searchParams.get("type") || "",
    };
}

export function filterAndSortData(data, filters) {
    let filteredData = [...data];

    const {
        orderOption, order, mening, titel, prio, laatsteKeerGekeken, kijkStatus, type,
    } = filters;

    if (titel) {
        filteredData = filteredData.filter((item) => item.titel.toLowerCase().includes(titel.toLowerCase()));
    }
    if (mening) {
        const meningValues = mening.split(",");
        filteredData = filteredData.filter((item) => meningValues.includes(item.mening));
    }
    if (prio) {
        const prioValues = prio.split(",");
        filteredData = filteredData.filter((item) => prioValues.includes(item.prioriteit));
    }
    if (kijkStatus) {
        const kijkStatusValues = kijkStatus.split(",");
        filteredData = filteredData.filter((item) => kijkStatusValues.includes(item.kijkStatus));
    }
    if (type) {
        const typeValues = type.split(",");
        if (typeValues.includes("movie")) {
            typeValues.push("film");
        }
        if (typeValues.includes("tv")) {
            typeValues.push("series");
            typeValues.push("serie");
        }

        filteredData = filteredData.filter((item) => typeValues.includes(item.mediaType.toLowerCase()));
    }
    if (orderOption === ORDER_OPTIONS.LAST_WATCHED) {
        filteredData = filteredData.sort((a, b) => {
            if (order === ORDER.ASC) {
                return new Date(a.laatsteKeerGekeken) - new Date(b.laatsteKeerGekeken);
            }
            return new Date(b.laatsteKeerGekeken) - new Date(a.laatsteKeerGekeken);
        });
    }

    // console.log(filteredData);
    if (!filteredData) return [];

    return filteredData;
}
