import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Form, Image,
    Input,
    InputNumber,
    Modal, Popconfirm,
    Rate,
    Row,
    Select,
    Slider,
    Space,
    Typography,
} from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    CloseOutlined, LockOutlined, MinusCircleOutlined, PlusOutlined, UserOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Divider } from "../../../../reusableComponents/Layout/Divider/Divider";
import { useSearchParamsUtil } from "../../../../../hooks/useSearchParams";
import {
    deleteMediaLijst,
    fetchAllMediaThunk,
    fetchMedialijstEnums,
    updateMedialijst,
} from "../../../../../reducers/Media";
import { checkFetchError, fetchApiSendJson } from "../../../../../snippets/functions/fetchFunctions";
import { useNotification } from "../../../../../hooks/AntComponents/useNotification";
// import "./EditLijstModal.css";
import { P } from "../../../../reusableComponents/Text/Paragraph/P";
import { Header } from "../../../../reusableComponents/Text/Header/Header";

const { TextArea } = Input;

export function EditLijstModal({ lijst, setLijst }) {
    const { getFilterValue, removeFilter } = useSearchParamsUtil();
    const dispatch = useDispatch();
    const openEdit = getFilterValue("edit") === "true" || false;
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [form] = Form.useForm();
    const [formChanged, setFormChanged] = useState(false);
    const navigate = useNavigate();

    const onClose = () => {
        removeFilter("edit");
    };

    useEffect(() => {
        if (!openEdit) {
            setFormChanged(false);
            form.resetFields();
        }
    }, [openEdit, form, lijst, formChanged]);

    const { user } = useSelector((state) => state.user);
    const {
        mediaLijstEnums, status, media, error,
    } = useSelector((state) => state.media);

    useEffect(() => {
        if (!mediaLijstEnums || mediaLijstEnums.length === 0 || status === "loadingEnums") {
            setLoading(true);
        }

        if (status === "enumsLoaded") {
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }, [mediaLijstEnums, status]);

    const notification = useNotification();
    const handleSubmit = async (values) => {
        setConfirmLoading(true);

        try {
            const updateMedialijst = await fetchApiSendJson(`media/lijst/${lijst._id}/${user._id}`, "PUT", { mediaLijst: values });
            const updateMedialijstData = await checkFetchError(updateMedialijst);

            setLijst(updateMedialijstData);

            setFormChanged(false);
            setTimeout(() => {
                setConfirmLoading(false);
                onClose();

                notification.success({
                    key: "success",
                    message: "Media lijst is succesvol aangepast",
                    duration: 5,
                    placement: "topRight",
                });
            }, 750);
        } catch (err) {
            notification.error({
                key: "error",
                message: "Fout opgetreden",
                description: err.message,
                duration: 20,
                placement: "topRight",
            });

            // miss later zo iets;
            // form.setFields([
            //     {
            //         name: "tags",
            //         errors: ["Voeg ten minste één tag toe."], // Foutbericht voor het veld
            //         validateStatus: "error", // Zet de validatiestatus
            //     },
            // ]);

            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        if (formChanged) {
            Modal.confirm({
                title: "Weet je zeker dat je de veranderingen wil vergeten?",
                onOk: () => onClose(),
                centered: true,
            });
        } else {
            onClose();
        }
    };

    const onValuesChange = (_, allValues) => {
        setFormChanged(true);
    };

    useEffect(() => {
        if (mediaLijstEnums === null) {
            dispatch(fetchMedialijstEnums());
        }

        dispatch(fetchAllMediaThunk({ _id: user._id }));
    }, []);

    if (!mediaLijstEnums || status === "loadingEnums") {
        return <p>Loading...</p>;
    }

    if (error === "failedLoadingEnums") {
        return <p>{error}</p>;
    }

    const confirmDelete = () => new Promise((resolve) => {
        setTimeout(async () => {
            try {
                const reponse = await fetchApiSendJson(`media/lijst/${lijst._id}/${user._id}`, "DELETE");
                await checkFetchError(reponse);

                setTimeout(() => {
                    notification.success({
                        key: "success",
                        message: "Media lijst is succesvol verwijderd",
                        duration: 5,
                        placement: "topRight",
                    });
                }, 750);

                resolve();

                navigate("/media");
            } catch (err) {
                notification.error({
                    key: "error",
                    message: "Fout opgetreden",
                    description: err.message,
                    duration: 20,
                    placement: "topRight",
                });

                setConfirmLoading(false);
                resolve();
            }
        }, 2000);
    });

    function BackdropInput(props) {
        const { value, onChange } = props;

        const onBackdropChange = (e) => {
            onChange(e.target.value);
        };

        return (
            <>
                <Input
                    value={value}
                    onChange={onBackdropChange}
                    placeholder="Backdrop URL"
                />

                {value && (
                    <div style={{ padding: "10px" }}>
                        <Image
                            src={value}
                            alt="Backdrop"
                            style={{ height: "250px", maxWidth: "100%" }}
                        />
                    </div>

                )}
            </>
        );
    }

    function PosterInput({ value, onChange }) {
        const onPosterChange = (e) => {
            console.log(e.target.value, "poster change");
            onChange(e.target.value); // Direct de nieuwe waarde doorsturen
        };

        return (
            <>
                <Input
                    value={value}
                    onChange={onPosterChange}
                    placeholder="Poster URL"
                />

                {value && (
                    <div style={{ padding: "10px" }}>
                        <Image
                            src={value}
                            alt="Poster"
                            style={{ height: "250px", maxWidth: "100%" }}
                        />
                    </div>
                )}
            </>
        );
    }

    const panels = () => [
        {
            key: "1",
            label: <Header level={5}>Algemene informatie</Header>,
            children: (
                <div className="panel-content">
                    <div className="container mx-auto px-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xxl:gap-12">
                            <div className="md:col-span-1">
                                <h2 className="text-lg font-semibold">Algemene informatie</h2>
                                <p className="text-sm text-gray-600">
                                    Gebruik een permanent adres waar je mail kunt
                                    ontvangen.
                                </p>
                            </div>

                            <div className="md:col-span-2">
                                <div className="space-y-2 gap-0 xxl:gap-8">
                                    <Form.Item
                                        name="titel"
                                        label="Lijst titel"
                                        rules={[{ required: true, message: "Vul een titel in", type: "string" }]}
                                    >
                                        <Input placeholder="Titel" allowClear />
                                    </Form.Item>

                                    <Form.Item
                                        name="beschrijving"
                                        label="Beschrijving"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <TextArea
                                            placeholder="Beschrijving"
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            allowClear
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="backdrop_img"
                                        label="Backdrop"
                                    >
                                        <BackdropInput />
                                    </Form.Item>

                                    <Form.Item
                                        name="poster_img"
                                        label="Poster"
                                    >
                                        <PosterInput />
                                    </Form.Item>

                                    <div
                                        className="grid grid-cols-1 md:grid-cols-2 gap-x-4 xxl:gap-x-12 xxl:gap-y-4"
                                    >
                                        {/*   items small */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            key: "2",
            label: <Header level={5}>Meta-data</Header>,
            children: (
                <div className="panel-content">
                    <div className="container mx-auto px-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xxl:gap-12">
                            <div className="md:col-span-1">
                                {/* <h2 className="text-lg font-semibold">Meta-data</h2> */}
                                <p className="text-sm text-gray-600">
                                    Gebruik een permanent adres waar je mail kunt
                                    ontvangen.
                                </p>
                            </div>

                            <div className="md:col-span-2">
                                <div className="space-y-2 gap-0 xxl:gap-8">
                                    <div
                                        className="grid grid-cols-1 md:grid-cols-2 gap-x-4 xxl:gap-x-12 xxl:gap-y-4"
                                    >
                                        <Form.Item
                                            name="mening"
                                            label="Mening"
                                            rules={[{ required: true, message: "" }]}
                                        >
                                            <Select placeholder="Mening">
                                                {mediaLijstEnums.mening.map((m) => (
                                                    <Select.Option key={m} value={m}>{m}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="prioriteit"
                                            label="Prioriteit"
                                            rules={[{ required: true, message: "" }]}
                                        >
                                            <Select placeholder="Prioriteit">
                                                {mediaLijstEnums.prioriteit.map((m) => (
                                                    <Select.Option key={m} value={m}>{m}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="laatsteKeerGekeken"
                                            label="laatsteKeerGekeken"
                                            rules={[{ required: false, message: "" }]}
                                        >
                                            <DatePicker
                                                className="w-full"
                                                placeholder="Niet bekend"
                                                format="DD-MM-YYYY"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="soortLijst"
                                            label="soortLijst"
                                            rules={[{ required: true, message: "" }]}
                                        >
                                            <Select placeholder="soortLijst">
                                                {mediaLijstEnums.soortLijst.map((m) => (
                                                    <Select.Option key={m} value={m}>{m}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="lijstType"
                                            label="lijstType"
                                            rules={[{ required: true, message: "" }]}
                                        >
                                            <Select placeholder="lijstType">
                                                {mediaLijstEnums.lijstType.map((m) => (
                                                    <Select.Option key={m} value={m}>{m}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="rating"
                                            label="Rating"
                                        >
                                            <Rate allowClear allowHalf />
                                        </Form.Item>

                                    </div>
                                    <Form.Item
                                        name="tags"
                                        label="Tags"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Form.List name="tags">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space
                                                            key={key}
                                                            style={{ display: "flex", marginBottom: 4 }}
                                                            align="baseline"
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "tag"]}
                                                                rules={[{ required: true, message: "Missende tag" }]}
                                                            >
                                                                <Input placeholder="Tag" />
                                                            </Form.Item>
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            disabled
                                                            onClick={() => add()}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add tags
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item
                                        name="genre"
                                        label="genre"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="genre"
                                            maxTagCount="responsive"
                                        >
                                            {mediaLijstEnums.genres.map((m) => (
                                                <Select.Option key={m} value={m}>{m}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            key: "3",
            label: <Header level={5}>Media</Header>,
            children: (
                <div className="panel-content">
                    <div className="container mx-auto px-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xxl:gap-12">
                            <div className="md:col-span-1">
                                <h2 className="text-lg font-semibold">Media Informatie</h2>
                                <p className="text-sm text-gray-600">
                                    Gebruik een permanent adres waar je mail kunt
                                    ontvangen.
                                </p>
                            </div>

                            <div className="md:col-span-2">
                                <div className="space-y-2 gap-0 xxl:gap-8">
                                    <Form.Item
                                        name="laatsteMediaGekeken"
                                        label="laatsteMediaGekeken"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="media"
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {media.map((m) => (
                                                <Select.Option
                                                    key={m._id}
                                                    value={m._id}
                                                    label={m.titel}
                                                >
                                                    {m.titel}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="media"
                                        label="media"
                                        rules={[{ required: false, message: "" }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="media"
                                            maxTagCount="responsive"
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {media.map((m) => (
                                                <Select.Option key={m._id} value={m._id} label={m.titel}>
                                                    <Space>
                                                        {m.imageUrl && (
                                                            <img
                                                                src={m.imageUrl}
                                                                alt={m.titel}
                                                                style={{ height: "65px" }}
                                                            />
                                                        )}
                                                        {m.titel}
                                                    </Space>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Modal
            open={openEdit}
            okButtonProps={{
                autoFocus: true,
                htmlType: "submit",
            }}
            onCancel={handleCancel}
            centered
            title={`Edit medialijst: ${lijst.titel}`}
            width={1000}
            confirmLoading={confirmLoading}
            loading={loading}
            okText="Save"
            cancelText="Cancel"
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    name="edit_medialijst"
                    form={form}
                    className="mt-8 mb-8 space-y-4"
                    onValuesChange={onValuesChange}
                    scrollToFirstError
                    disabled={status === "loading" || !lijst || !mediaLijstEnums}
                    initialValues={{
                        tags: lijst?.tags?.map((tag) => ({ tag })),
                        soortLijst: lijst?.soortLijst,
                        prioriteit: lijst?.prioriteit,
                        titel: lijst?.titel,
                        mening: lijst?.mening,
                        beschrijving: lijst?.beschrijving,
                        lijstType: lijst?.lijstType,
                        // genre: lijst.tags.map((genre) => ({ genre })),
                        genre: lijst?.genre,
                        media: lijst?.media?.map((mediaItem) => mediaItem._id),
                        laatsteMediaGekeken: lijst?.laatsteMediaGekeken?._id,
                        laatsteKeerGekeken: lijst?.laatsteKeerGekeken ? moment(lijst?.laatsteKeerGekeken) : null,
                        rating: lijst?.rating || 0,
                        poster_img: lijst?.poster_img,
                        backdrop_img: lijst?.backdrop_img,
                    }}
                    onFinish={handleSubmit}
                    onFinishFailed={(errorInfo) => {
                        console.log("Failed:", errorInfo);
                    }}
                    layout="vertical"
                    size="medium"
                    labelAlign="right"
                >
                    {dom}
                </Form>
            )}
        >
            <Collapse
                defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
                className="sidebar-collapse"
                expandIconPosition="start"
                ghost
                bordered
                items={panels()}
            />

            <Popconfirm
                title="Verwijder"
                description="Weet je zeker dat je deze lijst wilt verwijderen?"
                onConfirm={confirmDelete}
                okText="Ja"
                cancelText="Nee"
            >
                <Button type="primary" danger className="ml-auto mr-auto">
                    Verwijder lijst
                </Button>
            </Popconfirm>

        </Modal>
    );
}
