import { TheMovieDB_MediaTypes } from "../../enum/TheMovieDB";

const options = {
    method: "GET",
    headers: {
        accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
    },
};

function GetCorrectType(mediaType) {
    if (mediaType === "Serie" || mediaType === TheMovieDB_MediaTypes.TV) {
        return TheMovieDB_MediaTypes.TV;
    } if (mediaType === "Film" || mediaType === TheMovieDB_MediaTypes.MOVIE) {
        return TheMovieDB_MediaTypes.MOVIE;
    } if (mediaType.toLocaleLowerCase() === "beide" || mediaType === TheMovieDB_MediaTypes.MULTI) {
        return TheMovieDB_MediaTypes.MULTI;
    } return new Error("Incorrect mediaType");
}

export async function SearchMedia(mediaType, title, year, page = 1) {
    return await fetch(
        `https://api.themoviedb.org/3/search/${GetCorrectType(mediaType)}?query=${title}${year ? `&year=${year}` : ""}&page=${page}&include_adult=false&language=en-US`,
        options,
    ).then((res) => res.json());
}

export async function GetDetails(mediaType, id) {
    return await fetch(
        `https://api.themoviedb.org/3/${GetCorrectType(mediaType)}/${id}`,
        options,
    ).then((res) => res.json());
}

export async function GetImages(mediaType, id, max, page) {
    // Miss voor later; page of max aantal
    const fullImagesInfo = await fetch(
        `https://api.themoviedb.org/3/${GetCorrectType(mediaType)}/${id}/images`,
        options,
    ).then((res) => res.json());

    let { posters } = fullImagesInfo;
    let { backdrops } = fullImagesInfo;

    if (max && !isNaN(max)) {
        posters = posters.slice(0, max);
        backdrops = backdrops.slice(0, max);

        fullImagesInfo.posters = posters;
        fullImagesInfo.backdrops = backdrops;
    }
    return { posters, backdrops, images: fullImagesInfo };
}

export async function GetTrailers(mediaType, id) {
    const fullTrailerInfo = await fetch(
        `https://api.themoviedb.org/3/${GetCorrectType(mediaType)}/${id}/videos`,
        options,
    ).then((res) => res.json());

    if (!fullTrailerInfo.results || fullTrailerInfo.results.length === 0) {
        console.log("No trailers found in de getrailwers");
        return {
            officialTrailers: [], highestQualityTrailer: null, fullTrailerInfo: null, officialTrailer: null,
        };
    }

    const officialTrailers = fullTrailerInfo.results.filter((trailer) => trailer.type === "Trailer" && trailer.official);
    // const highestQualityTrailer = officialTrailers.reduce((prev, current) => (prev.size > current.size ? prev : current));
    const highestQualityTrailer = officialTrailers.reduce((prev, current) => (prev.size > current.size ? prev : current), {});
    const officialTrailer = officialTrailers[0];

    // console.log(officialTrailers, highestQualityTrailer, fullTrailerInfo, officialTrailer);
    return {
        officialTrailers, highestQualityTrailer, fullTrailerInfo, officialTrailer,
    };
}

export async function GetCrew(mediaType, id) {
    const fullCrewInfo = await fetch(
        `https://api.themoviedb.org/3/${GetCorrectType(mediaType)}/${id}/credits`,
        options,
    ).then((res) => res.json());

    const directors = fullCrewInfo.crew.filter((crew) => crew.job === "Director");
    const writers = fullCrewInfo.crew.filter((crew) => crew.department === "Writing");
    const actors = fullCrewInfo.cast.slice(0, 5);

    return {
        directors, writers, actors, fullCrewInfo,
    };
}
