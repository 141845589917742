import { Tag } from "antd";
import React from "react";

export function GetMeningTag({ mening, style, className }) {
    // if (record.special) return null;

    let color;

    switch (mening) {
    case "Favo":
        color = "green";
        break;
    case "Legend":
        color = "lime";
        break;
    case "Goed":
        color = "geekblue";
        break;
    case "Prima":
        color = "gold";
        break;
    case "Ok":
        color = "volcano";
        break;
    case "Meh":
        color = "red";
        break;
    case "Niet bekend":
    default: color = "gray";
    }

    return <Tag className={className} style={style} color={color}>{mening}</Tag>;
}
