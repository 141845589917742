import {
    Button,
    Card, Checkbox, Col, Dropdown, Row,
} from "antd";
import {
    EditOutlined, EllipsisOutlined, FullscreenOutlined, InfoCircleOutlined, PlayCircleOutlined, PlayCircleTwoTone,
} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import clipboard from "clipboard-copy";
import { useSelector } from "react-redux";
import { PlayCircleRounded } from "@mui/icons-material";
import { useSearchParamsUtil } from "../../../../../../hooks/useSearchParams";
import { useNotification } from "../../../../../../hooks/AntComponents/useNotification";
import { useMessage } from "../../../../../../hooks/AntComponents/useMessage";
import { checkFetchError, fetchApiSendJson } from "../../../../../../snippets/functions/fetchFunctions";
import { GetMeningTag } from "../../../Tags/Tags";
import ScrollBackToLocation from "../../../../../../functions/ScrollBackToLocation";
import { useGetMediaSource } from "../../useGetSource";

const imageContainerStyle = {
    // height: 300,
    minHeight: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
};

const useDeviceCapabilities = () => {
    const [canHover, setCanHover] = useState(false);

    useEffect(() => {
        const query = window.matchMedia("(hover: hover)");
        const updateCanHover = () => {
            setCanHover(query.matches);
        };
        query.addListener(updateCanHover);
        updateCanHover();
        return () => query.removeListener(updateCanHover);
    }, []);

    return { canHover };
};

export function DisplayMediaCard({ media }) {
    const { updateFilters } = useSearchParamsUtil();
    const { user } = useSelector((state) => state.user);
    const message = useMessage();
    const notification = useNotification();
    const navigate = useNavigate();
    const [hoveredCard, setHoveredCard] = useState(null);
    const { getMediaSource } = useGetMediaSource();
    const [loadingSource, setLoadingSource] = useState(false);
    const { isTouchDevice, canHover } = useDeviceCapabilities();

    if (!media || media.length === 0) {
        return (
            <div>
                <h1>Geen media gevonden</h1>
            </div>
        );
    }

    useEffect(() => {
        ScrollBackToLocation("scrollPositionMediaCard");
    }, [media]);

    const handleItemClick = (url) => {
        localStorage.setItem("scrollPositionMediaCard", window.scrollY);
        navigate(url);
    };

    const handleCardClick = (item) => {
        if (!canHover) {
            // console.log("modal:", item._id);
        } else {
            // console.log("full page:, fout", item._id);
        }
    };

    const renderActions = (item) => [
        <PlayCircleRounded
            style={{ color: "black", fontSize: "20px", cursor: loadingSource ? "not-allowed" : "pointer" }}
            key="play"
            disabled={loadingSource}
            onClick={async () => {
                await getMediaSource({
                    id: item._id, setLoadingSource, loadingSource,
                });
            }}
        />,
        <InfoCircleOutlined style={{ color: "black", fontSize: "20px" }} key="edit" onClick={() => updateFilters({ mediaModal: item._id })} />,
    ];

    const handleMouseEnter = (id) => {
        if (canHover) {
            setHoveredCard(id);
        }
    };

    const handleMouseLeave = () => {
        if (canHover) {
            setHoveredCard(null);
        }
    };
    const [alwaysShowDetails, setAlwaysShowDetails] = useState(false);
    /*
    voor images;
    const [tmdbImages, setTmdbImages] = useState({});

    ik wil maken; lees de gezette image van de item met; poster_img
    als die niet bestaat, kijk of themoviedb_id is gezet, zo ja kijk dan naar; themoviedb_type. dan kan je een default image pakken van die media via moviedb.
    en als laatste mogelijkheid, lees de imageUrl van het item, dit is van de oude zooi. vanaf nu ga ik die naar poster_img zetten.
     */

    // Dit wil ik misshcien later laten werken, wat nu niet goed gaat is dat iedere rerender alles opnieuw wordt opgeaald.
    async function fetchMovieDBImage(mediaType, themoviedb_id) {
        if (!themoviedb_id) return "https://via.placeholder.com/300";

        const movieDbType = mediaType === "Serie" ? "tv" : "movie";

        const fetchMovieDbPoster = await fetch(`https://api.themoviedb.org/3/${movieDbType}/${themoviedb_id}/images`, {
            method: "GET",
            headers: {
                accept: "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
            },
        }).then((res) => res.json());

        if (!fetchMovieDbPoster[0]) return "https://via.placeholder.com/300";

        console.log(fetchMovieDbPoster[0].file_path, "fetchMovieDbPoster[0].file_path");
        return `https://image.tmdb.org/t/p/original${fetchMovieDbPoster[0].file_path}`;
    }
    return (
        <Row gutter={[30, 30]} style={{}} className="">
            <Checkbox onChange={(e) => setAlwaysShowDetails(e.target.checked)}>Details altijd tonen</Checkbox>
            {media.map((item) => (
                <Col key={item._id} xs={12} sm={12} md={12} lg={8} xl={5} xxl={4}>
                    <Card
                        actions={hoveredCard === item._id ? renderActions(item) : undefined}
                        hoverable={!isTouchDevice}
                        style={{
                            transition: "all .8s",
                            transitionDelay: "0.3s",
                            transform: hoveredCard === item._id ? "scale(1.10)" : "none",
                            zIndex: hoveredCard === item._id ? 500 : 1,
                            position: hoveredCard === item._id ? "absolute" : "relative",
                            top: 0,
                            left: 0,
                            marginBottom: "30px",
                        }}
                        // onClick={() => {
                        //     if (!canHover) handleCardClick(item);
                        // }}
                        onMouseEnter={() => handleMouseEnter(item._id)}
                        onMouseLeave={handleMouseLeave}
                        cover={(
                            <div style={imageContainerStyle}>
                                <img
                                    alt="Alt text"
                                    // src={item.poster_img || item.imageUrl || fetchMovieDBImage(item.mediaType, item?.themoviedb_id)}
                                    src={item.poster_img || item.imageUrl || "https://via.placeholder.com/300"}
                                    style={{ cursor: !canHover ? "" : "alias" }}
                                    onClick={() => {
                                        if (!canHover) {
                                            updateFilters({ mediaModal: item._id });
                                        } else {
                                            handleItemClick(`/media/media/watch/${item._id}`);
                                        }
                                    }}
                                />
                            </div>
                        )}
                    >
                        <Meta
                            title={item.titel}
                            // TODO: opbasis van geselecteerde filters. en anders default waardes
                            description={(hoveredCard === item._id || alwaysShowDetails) && (
                                <div>
                                    <ul className="uk-padding-remove-left">
                                        <li>
                                            <GetMeningTag mening={item.mening} />
                                        </li>
                                        <li>
                                            {item.laatsteKeerGekeken}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        />
                    </Card>
                </Col>
            ))}
        </Row>
    );
}
