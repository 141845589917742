function ScrollBackToLocation(localStorageItem) {
    const savedScrollPosition = localStorage.getItem(localStorageItem);

    if (savedScrollPosition) {
        const scrollToSavedPosition = () => {
            if (window.scrollY === parseInt(savedScrollPosition, 10)) {
                return;
            }

            window.scrollTo(0, parseInt(savedScrollPosition, 10));

            // Gebruik requestAnimationFrame om te wachten tot de volgende render-cyclus
            requestAnimationFrame(() => {
                setTimeout(scrollToSavedPosition, 100);
            });
        };

        scrollToSavedPosition();

        localStorage.removeItem(localStorageItem);
    }
}

export default ScrollBackToLocation;
