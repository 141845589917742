import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import react, { useEffect, useState } from "react";
import clipboard from "clipboard-copy";
import {
    Avatar, Button, Image, List, Space, Tag,
} from "antd";
import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PlayCircleRounded } from "@mui/icons-material";
import { checkFetchError, fetchApiSendJson } from "../../../../../../snippets/functions/fetchFunctions";
import { useNotification } from "../../../../../../hooks/AntComponents/useNotification";
import { useMessage } from "../../../../../../hooks/AntComponents/useMessage";
import { useSearchParamsUtil } from "../../../../../../hooks/useSearchParams";
import { GetMeningTag } from "../../../Tags/Tags";
import { Divider } from "../../../../../reusableComponents/Layout/Divider/Divider";
import ScrollBackToLocation from "../../../../../../functions/ScrollBackToLocation";
import { useGetMediaSource } from "../../useGetSource";

function DisplayMediaList({ media }) {
    const { updateFilters } = useSearchParamsUtil();
    const navigate = useNavigate();
    const { getMediaSource } = useGetMediaSource();

    if (!media || media.length === 0) {
        return (
            <div>
                <h1>Geen media gevonden</h1>
            </div>
        );
    }

    useEffect(() => {
        ScrollBackToLocation("scrollPositionMediaList");
    }, [media]);

    const [loadingSource, setLoadingSource] = useState(false);

    const handleItemClick = (url) => {
        localStorage.setItem("scrollPositionMediaList", window.scrollY);
        navigate(url);
    };

    return (
        <div className="">
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                }}
                itemLayout="vertical"
                dataSource={media}
                renderItem={(item) => (
                    <List.Item>
                        <div className="flex cursor-pointer group">
                            <img
                                width={100}
                                src={item.imageUrl || item.poster_img}
                                alt={item.title}
                                className="mr-4"
                                onClick={() => handleItemClick(`/media/media/watch/${item._id}`)}
                            />
                            <div className="flex-1 p-4">
                                <div onClick={() => handleItemClick(`/media/media/watch/${item._id}`)}>
                                    <h3 className="text-lg font-bold group-hover:text-gray-400">{item.titel}</h3>
                                    <p className="text-gray-500">{item?.year || item?.jaar}</p>
                                    <Space>
                                        <p className="text-gray-500">
                                            <GetMeningTag className="mr-0" mening={item?.mening} />
                                        </p>
                                        <p className="text-gray-500">-</p>
                                        <p className="text-gray-500"><Tag color="default">{item.prioriteit}</Tag></p>
                                    </Space>
                                </div>
                                <div className="mt-4 flex space-x-2">
                                    <Button>
                                        <InfoCircleOutlined onClick={() => updateFilters({ mediaModal: item._id })} />
                                    </Button>
                                    <Button>
                                        <PlayCircleRounded
                                            disabled={loadingSource}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                await getMediaSource({
                                                    id: item._id, setLoadingSource, loadingSource,
                                                });
                                            }}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Divider />
                    </List.Item>
                )}
            />
        </div>
    );
}

export default DisplayMediaList;
