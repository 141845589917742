import React from "react";
import "./BigBanner.css";
import { InfoCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Header } from "../../Text/Header/Header";
import { useIsScreenMobileWindowSize } from "../../../../hooks/useCheckWindowSize";

export function BigBanner({
    title, imageUrl, contentTitle, description, onPlayclick, onInfoClick,
}) {
    const isMobile = useIsScreenMobileWindowSize();
    return (
        <div className="movie-banner mb-6" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="title-text">
                <Header className="mb-3 text-white">{title}</Header>
            </div>
            <div className="movie-info">
                <Header className="mb-3 text-white">{contentTitle}</Header>
                <p>{description}</p>
                <div className="movie-buttons">
                    {onPlayclick && (
                        <button type="button" className="btn-play" onClick={onPlayclick}>
                            <Space>
                                <PlayCircleOutlined />
                                {!isMobile && "Afspelen"}
                            </Space>
                        </button>
                    )}
                    {onInfoClick && (
                        <button type="button" className="btn-info" onClick={onInfoClick}>
                            <Space>
                                <InfoCircleOutlined />
                                {!isMobile && "Meer informatie"}
                            </Space>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
