import React from "react";
import ReactDOM from "react-dom/client";
import * as Redux from "redux";
import * as ReactRedux from "react-redux";
import thunk from "redux-thunk";
import { App } from "./App";

import "./index.css";
// import "./style/mijnEigenStyle.css";

import { fetchApiSendJson } from "./snippets/functions/fetchFunctions";
import { rootReducer } from "./reducers/rootReducer";
import { DarkModeProvider, useDarkMode } from "./utils/Darkmode";
import { MessageProvider } from "./hooks/AntComponents/useMessage";
import { NotificationProvider } from "./hooks/AntComponents/useNotification";

function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
async function postSubscription(subscription) {
    await fetchApiSendJson("pwa/subscriptions", "POST", subscription);
}

async function postSubscriptionWhenUserLogin(subscription, user_Id) {
    await fetchApiSendJson(`pwa/subscriptions/${user_Id}`, "POST", subscription);
}

const publicVapidKey = "BM-wEQjUbvE0e8Wbg3qQhwhsAt3TdzlTrw71kcG6Lh_TlbH-XiBblALrPm5pb_Op6m3mGtBCUyb5X2QFpIWz7qA";
const convertedVapidKey = urlB64ToUint8Array(publicVapidKey);

if ("serviceWorker" in navigator && "PushManager" in window) {
    // console.log('Service Worker and Push is supported');

    // register the service worker
    navigator.serviceWorker.register("/service-worker.js")
        .then((swReg) => {
            // console.log("Service Worker is registered", swReg);

            swReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidKey,
            }).then((subscription) => {
                // Pass the subscription object to your server
                // console.log("User is subscribed:", subscription);

                postSubscription(subscription);
            }).catch((err) => {
                console.log("Failed to subscribe the user: ", err);
            });
        }).catch((error) => {
            console.error("Service Worker Error", error);
        });
} else {
    console.warn("Push messaging is not supported");
}

const logger = (store) => (next) => (action) => {
    console.log("ACTION:", action.type, action);
    const result = next(action);
    console.log("STATE AFTER ACTION:", action.type, store.getState());
    return result;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || Redux.compose;

const theStore = Redux.createStore(
    rootReducer,
    composeEnhancers(Redux.applyMiddleware(logger, thunk)),
);

const root = ReactDOM.createRoot(document.getElementById("react-root"));

root.render(
    <ReactRedux.Provider store={theStore}>
        <DarkModeProvider>
            <MessageProvider>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </MessageProvider>
        </DarkModeProvider>
    </ReactRedux.Provider>,
);

export { postSubscriptionWhenUserLogin, convertedVapidKey };
