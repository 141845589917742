import React, { useState } from "react";
import { HomeOutlined, UploadOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideMenuMedia } from "../../reducers/CMSLayout";

export function Account({}) {
    const baseRoute = "/account";
    const menuItems = [
        {
            key: "1",
            path: baseRoute,
            icon: React.createElement(VideoCameraOutlined),
            label: "Media overzicht",
        },
    ];

    const Mediaroutes = [
        { path: "/", breadcrumb: "Volledig home, index" },
        {
            path: "/media",
            breadcrumb: () => (
                <span>
                    <HomeOutlined />
                    {" "}
                    Media overzicht
                </span>
            ),
        },
        { path: "/media/lijst", breadcrumb: "alleen lijst", isLastItemClickable: false },
        { path: "/lijst/", breadcrumb: "alleen lijst", isLastItemClickable: false },
        { path: "/media/lijst/add", breadcrumb: "Lijst add wad" },
        { path: "/media/speciallijst", breadcrumb: "specdial lijst", isLastItemClickable: false },
        { path: "/media/speciallijst/all", breadcrumb: "special lijst all", hideMenu: true },
        { path: "/media/lijst/watch/:id", isLastItemClickable: false },
        { path: "/media/lijst/watch/:id/test", isLastItemClickable: false },
        { path: "/media/lijst/watch/:id/test/:nogEenId", breadcrumb: "special lijst all custom teskt" },
    ];

    const drawerMenuVisible = useSelector((state) => state.cmsLayoutSlice.layoutStatePerPagina.media.isSideMenuVisible);
    const mediaCustomPageHeader = useSelector((state) => state.cmsLayoutSlice.layoutStatePerPagina.media.pageHeader);
    const dispatch = useDispatch();
    const toogleSideMenu = () => {
        dispatch(toggleSideMenuMedia());
    };
    const [customHeader, setCustomHeader] = useState(null);
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(true);

    // TODO: per component een custom header kunnen zetten of een default header of helemaal geen header
    const [showDefaultHeader, setShowDefaultHeader] = useState(true);
    const [showHeader, setShowHeader] = useState(true);

    return (
        <>
        </>
    );
}
