import React from "react";
import {
    DatePicker,
    Form, Input, Select, Typography,
} from "antd";

const { Title } = Typography;

function MediaDetailsPanel({ form, enums }) {
    // const
    return (
        <div className="panel-content gap-4 xxl:gap-12">
            <div className="container mx-auto px-4">
                <div className="md:col-span-2">
                    <div className="space-y-6 gap-0 xxl:gap-8 xxl:gap-y-12">
                        <div
                            className="md:grid-cols-2 gap-x-6 xxl:gap-x-12 xxl:gap-y-12"
                        >
                            <div className="space-y-6 gap-0 xxl:gap-8 xxl:gap-y-12">
                                {/* Items big */}
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 xxl:gap-x-12 gap-y-6">
                            {/* items small */}

                            {/* laatste keer gekeken */}
                            <Form.Item
                                label="Laatste keer gekeken"
                                name="laatsteKeerGekeken"
                                className="form-item"
                            >
                                <DatePicker className="w-full" />
                            </Form.Item>

                            {/* gestart met kijken */}
                            <Form.Item
                                label="Gestart met kijken"
                                name="gestartMetKijken"
                                className="form-item"
                            >
                                <DatePicker className="w-full" />
                            </Form.Item>

                            <Form.Item
                                label="Kijk status"
                                name="kijkStatus"
                                className="form-item"
                                rules={[{ required: true, message: "Selecteer een kijk status" }]}
                            >
                                <Select className="">
                                    {enums.kijkStatus.map((m) => (
                                        <Select.Option key={m} value={m}>{m}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Kijk type"
                                name="kijkType"
                                className="form-item"
                                rules={[{ required: true, message: "Selecteer een kijk type" }]}
                            >
                                <Select className="">
                                    {enums.kijkType.map((m) => (
                                        <Select.Option key={m} value={m}>{m}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Prioriteit"
                                name="prioriteit"
                                className="form-item"
                                rules={[{ required: true, message: "Selecteer een prioriteit" }]}
                            >
                                <Select className="">
                                    {enums.prioriteit.map((m) => (
                                        <Select.Option key={m} value={m}>{m}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Mening"
                                name="mening"
                                className="form-item"
                                rules={[{ required: true, message: "Selecteer een mening" }]}
                            >
                                <Select className="">
                                    {enums.mening.map((m) => (
                                        <Select.Option key={m} value={m}>{m}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {/* <Form.Item */}
                            {/*    label="jaar" */}
                            {/*    name="jaar" */}
                            {/*    className="form-item hidden" */}
                            {/*    rules={[{ required: false, message: "Selecteer een jaar" }]} */}
                            {/* > */}
                            {/*    <Input /> */}
                            {/* </Form.Item> */}

                            <Form.Item
                                label="themoviedb_type"
                                name="themoviedb_type"
                                className="form-item"
                                rules={[{ required: true, message: "Selecteer een media source" }]}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="themoviedb_id"
                                name="themoviedb_id"
                                className="form-item"
                                rules={[{ required: true, message: "Media id" }]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MediaDetailsPanel;
