import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    Button, Checkbox, Form, Input, Select, Space,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { Header } from "../components/reusableComponents/Text/Header/Header";
import { useDarkMode } from "../utils/Darkmode";
import { Divider } from "../components/reusableComponents/Layout/Divider/Divider";
import { useMessage } from "../hooks/AntComponents/useMessage";

export function HaalWeg() {
    const [value, setValue] = useState("");
    const [changedValue, setChangedValue] = useState("");
    const { darkMode } = useDarkMode();
    const message = useMessage();

    const replaceWithAir = (e) => {
        const removed_ = e.replace(/_/g, " ");
        const removed_html = removed_.replace(/.html/g, "");
        const removed_numbers = removed_html.replace(/[0-9]/g, "");
        const removed_website = removed_numbers.replace(/https:\/\/hqporner.com\/hdporn\//g, "");
        const removed_dashes = removed_website.replace(/-/g, "");

        return removed_dashes;
    };

    const handleChange = (e) => {
        const { value } = e.target;
        const processedValue = replaceWithAir(value);
        setValue(value);
        setChangedValue(processedValue);
    };

    const handleCopy = (textToCopy = changedValue) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            message.success({ content: "Succesvol gekopieerd!" });
        }).catch((err) => {
            console.error("Failed to copy: ", err);
            message.error({ content: "Niet gelukt om te kopieeren" });
        });
    };

    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText();
            if (text !== value) {
                const processedValue = replaceWithAir(text);
                setValue(text);
                console.log("test value tekst", text);
                setChangedValue(processedValue);
                handleCopy(processedValue);
            }
        } catch (err) {
            console.error("Failed to read clipboard contents: ", err);
            message.error({ content: "Niet gelukt om de clipboard uit te lezen ;/" });
        }
    };

    useEffect(() => {
        window.addEventListener("focus", handlePasteFromClipboard);
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                handlePasteFromClipboard();
            }
        });

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener("focus", handlePasteFromClipboard);
            document.removeEventListener("visibilitychange", handlePasteFromClipboard);
        };
    }, [value]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // if (e.ctrlKey && e.key === "v") {
            //     handlePasteFromClipboard();
            // }
            // if (e.ctrlKey && e.key === "c") {
            //     handleCopy();
            // }
            if (e.key === "v") {
                console.log("v !");
                handlePasteFromClipboard();
            }
            if (e.key === "c") {
                handleCopy();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("focus", handlePasteFromClipboard);
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                handlePasteFromClipboard();
            }
        });

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("focus", handlePasteFromClipboard);
            document.removeEventListener("visibilitychange", handlePasteFromClipboard);
        };
    }, [value, changedValue]);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Header
                    level={2}
                    className={`mt-6 text-center text-3xl font-extrabold text-custom_dark_blue ${!darkMode && "bg-custom_orange"}`}
                >
                    Haal_weg!
                </Header>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Verwijder ongewenste tekens en kopieer naar klembord
                </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-xl">
                <Form
                    name="login"
                    className="mt-8 space-y-10"
                    initialValues={{ }}
                    layout=""
                    size="large"
                    labelAlign="right"
                >
                    <Form.Item label="Met full URL?">
                        <Space>
                            <Form.Item
                                name={["check", "site"]}
                                noStyle
                                style={{ width: "100%" }}
                                rules={[
                                    {
                                        required: false,
                                        message: "",
                                    },
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                name="site"
                                noStyle
                                rules={[
                                    {
                                        required: false,
                                        message: "",
                                    },
                                ]}
                            >
                                <Select placeholder="Selecteer een site">
                                    <Option value="hq">HQ</Option>
                                </Select>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        name="tekst"
                        label="Tekst"
                        rules={[{ required: true, message: "Vul de tekst in", type: "string" }]}
                    >
                        <Input
                            placeholder=""
                            value={value}
                            onChange={handleChange}
                            allowClear
                            autoComplete="off"
                            onFocus={() => {
                                // select alle tekst of on click ofzo
                            }}
                        />
                    </Form.Item>

                    <Divider />

                    <Form.Item label="Resultaat">
                        <Space>
                            <Form.Item
                                name="Result"
                                noStyle
                            >
                                {/* Fix deze width breeder maken met de mogelijke ruiimte! */}
                                <Input
                                    className="min-w-min"
                                    style={{ width: "100%", minWidth: "500px" }}
                                    disabled
                                    placeholder={changedValue}
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                className="w-full"
                            >
                                <CopyToClipboard
                                    text={changedValue}
                                    onCopy={() => message.success({ content: "Succesvol geopieerd!" })}
                                >
                                    <CopyOutlined />
                                </CopyToClipboard>
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

// ghemaakt, maar niet getest;: dingein om dit nog beter te maken; als iemand op de pagina komt, lees zijn copy pasta uit. is dat tekst met _ dwad _ wada_ dan doe dat meteen verbeteren. dus ook als iemand weer herfocust als dat kan.
// gemaakt: shortcut maken dat iemand op: v drukt dan past hij de zooi toe in de input veld en dan ook meteen copy
// gemaakt: als iemand op: c drukt copyt hij de gerulsteerde value
// TODO: natuurlijk die func maken van full url
