function GetMovieDbTypeOnMovieDbInfo(mediaInfo) {
    if (mediaInfo.hasOwnProperty("title") && !mediaInfo.hasOwnProperty("name")) {
        // Als het object een 'title' bevat en geen 'name', is het een film
        return "movie";
    } if (mediaInfo.hasOwnProperty("name") && !mediaInfo.hasOwnProperty("title")) {
        // Als het object een 'name' bevat en geen 'title', is het een tv-show
        return "tv";
    } if (mediaInfo.hasOwnProperty("release_date") && !mediaInfo.hasOwnProperty("first_air_date")) {
        // Als het object een 'release_date' bevat en geen 'first_air_date', is het een film
        return "movie";
    } if (mediaInfo.hasOwnProperty("first_air_date") && !mediaInfo.hasOwnProperty("release_date")) {
        // Als het object een 'first_air_date' bevat en geen 'release_date', is het een tv-show
        return "tv";
    }
    // Indien niet duidelijk, return een onbekend type
    return "unknown";
}

export default GetMovieDbTypeOnMovieDbInfo;
