import {
    Form, Checkbox, Collapse, ConfigProvider, Button, Input,
} from "antd";
import React, { useEffect } from "react";
import "./css.css";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchMediaEnums } from "../../../../../../reducers/Media";
import { useSearchParamsUtil } from "../../../../../../hooks/useSearchParams";

const { Panel } = Collapse;

export function Sidebar() {
    const dispatch = useDispatch();
    const { mediaEnums } = useSelector((state) => state.media);
    const {
        updateFilters, getFilterValue, removeAllFilters, removeFilter,
    } = useSearchParamsUtil();
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    useEffect(() => {
        if (!mediaEnums || !mediaEnums.length) {
            dispatch(fetchMediaEnums());
        }
    }, []);

    if (!mediaEnums) {
        return (
            <div className="loading">
                <div className="loader" />
            </div>
        );
    }

    const handleReset = () => {
        removeFilter("titel");
        removeFilter("mening");
        removeFilter("prio");
        removeFilter("laatsteKeerGekeken");
        removeFilter("kijkStatus");
        removeFilter("type");

        setTimeout(() => {
            form.resetFields();
        }, 10);
    };

    const panels = () => [
        {
            key: "1",
            label: <div className="panel-header"><strong>Reset</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item label="" name="" className="form-item">
                        <Button onClick={handleReset}>
                            Reset
                        </Button>
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "21",
            label: <div className="panel-header"><strong>Titel</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item label="" name="titel" className="form-item">
                        <Input
                            allowClear
                            className="w-full"
                            placeholder="Zoek op titel"
                            onChange={(e) => {
                                updateFilters({ titel: e.target.value });
                            }}
                        />
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "2",
            label: <div className="panel-header"><strong>Mening</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item name="mening" className="form-item">
                        <Checkbox.Group
                            onChange={(checkedValues) => {
                                updateFilters({ mening: checkedValues.join(",") });
                            }}
                        >
                            {mediaEnums.mening.map((m) => (
                                <Checkbox key={m} className="my-checkbox large-checkbox" value={m}>{m}</Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "3",
            label: <div className="panel-header"><strong>Prioriteit</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item label="" name="prio" className="form-item">
                        <Checkbox.Group
                            onChange={(checkedValues) => {
                                updateFilters({ prio: checkedValues.join(",") });
                            }}
                        >
                            <Checkbox className="my-checkbox large-checkbox" value="Nu">Nu</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="Binnenkort">Binnenkort</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="Later">Later</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="Ooit">Ooit</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "4",
            label: <div className="panel-header"><strong>Laatste keer gekeken</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item name="laatsteKeerGekeken" className="form-item">
                        <Checkbox.Group
                            onChange={(checkedValues) => {
                                updateFilters({ laatsteKeerGekeken: checkedValues.join(",") });
                            }}
                        >
                            <Checkbox className="my-checkbox large-checkbox" value="1week">1 Week</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="2week">2 Weken</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="1maand">1 Maand</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="3maand">3 Maanden</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "5",
            label: <div className="panel-header"><strong>Kijk status</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item name="kijkStatus" className="form-item">
                        <Checkbox.Group
                            onChange={(checkedValues) => {
                                updateFilters({ kijkStatus: checkedValues.join(",") });
                            }}
                        >
                            {mediaEnums.kijkStatus.map((m) => (
                                <Checkbox key={m} className="my-checkbox large-checkbox" value={m}>{m}</Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "6",
            label: <div className="panel-header"><strong>Media type</strong></div>,
            children: (
                <div className="panel-content">
                    <Form.Item name="type" className="form-item">
                        <Checkbox.Group
                            onChange={(checkedValues) => {
                                updateFilters({ type: checkedValues.join(",") });
                            }}
                        >
                            <Checkbox className="my-checkbox large-checkbox" value="movie">Film</Checkbox>
                            <Checkbox className="my-checkbox large-checkbox" value="tv">Serie</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            ),
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Checkbox: {
                        colorPrimary: "#FFC90E", // dark blue; 1E2828, light; 0e6dcd
                        colorPrimaryHover: "#FFC90E",
                    },
                },
            }}
        >

            <Form
                layout="vertical"
                className="sidebar-form uk-margin-large-top"
                initialValues={{
                    mening: getFilterValue("mening")?.split(","),
                    prio: getFilterValue("prio")?.split(","),
                    laatsteKeerGekeken: getFilterValue("laatsteKeerGekeken")?.split(","),
                    kijkStatus: getFilterValue("kijkStatus")?.split(","),
                    type: getFilterValue("type")?.split(","),
                    titel: getFilterValue("titel"),
                }}
                form={form}
            >
                <Collapse
                    defaultActiveKey={["1", "21", "2", "3", "4", "5", "6"]}
                    className="sidebar-collapse"
                    expandIconPosition="start"
                    ghost
                    bordered
                    items={panels()}
                />
            </Form>
        </ConfigProvider>
    );
}
