import React from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
    Button, Form, Input, Typography,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { isUserLoggedIn, loginUser } from "../reducers/user";
import { Divider } from "../components/reusableComponents/Layout/Divider/Divider";
import { Header } from "../components/reusableComponents/Text/Header/Header";
import { useDarkMode } from "../utils/Darkmode";

export function LoginUI() {
    const dispatch = useDispatch();
    const { user, status, error } = useSelector((state) => state.user);
    const isLoggedIn = useSelector(isUserLoggedIn);
    const { darkMode } = useDarkMode();

    const handleSubmit = (values) => {
        if (values?.username && values?.password) {
            dispatch(loginUser({ username: values.username, password: values.password }));
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                {/* <img */}
                {/*    className="mx-auto h-12 w-auto" */}
                {/*    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" */}
                {/*    alt="Your Company" */}
                {/* /> */}
                <Header level={2} className={`mt-6 text-center text-3xl font-extrabold text-custom_dark_blue ${!darkMode && "bg-custom_orange"}`}>
                    Login op je account
                </Header>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <Form
                    name="login"
                    className="mt-8 space-y-10"
                    initialValues={{ }}
                    onFinish={handleSubmit}
                    layout="vertical"
                    size="large"
                    disabled={status === "loading" || isLoggedIn || status === "tryingToken" || status === "succeeded"}
                    labelAlign="right"
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        // label={<label className="font-semibold">Username</label>} // custom label!
                        rules={[{ required: true, message: "Vul een gebruikersnaam in", type: "string" }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={isLoggedIn ? user.username : "Username"}
                            className=""
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Wachtwoord"
                        disabled={status === "loading"}
                        rules={[{ required: true, message: "Vul een wachtwoord in" }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                            className=""
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="w-full"
                        >
                            Sign in
                        </Button>
                    </Form.Item>

                    {error && (
                        <Form.Item>
                            <Typography.Text type="danger">
                                {error}
                            </Typography.Text>
                        </Form.Item>
                    )}

                    {isLoggedIn && (
                        <>
                            <Divider>Of</Divider>

                            <Form.Item labelAlign="right">
                                <label className="font-semibold">
                                    <Typography.Text>
                                        Je bent al ingelogd. Probeer je uit te loggen?
                                    </Typography.Text>
                                </label>
                            </Form.Item>
                            <Typography.Text>
                                <Link to="/logout">Klik dan hier</Link>
                            </Typography.Text>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
}
