import {
    useLocation, useNavigate, useParams, useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import {
    Button,
    Col,
    Divider,
    Empty,
    FloatButton,
    Layout,
    Result,
    Row,
    Select,
    Space,
    theme,
    Typography,
} from "antd";
import { DownOutlined, EditOutlined, UpOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import useMessage from "antd/es/message/useMessage";
import { checkFetchError, fetchApi, fetchApiSendJson } from "../../../../snippets/functions/fetchFunctions";
import {
    NotificationPlacementOptions as NotificationPlacement,
    useNotification,
} from "../../../../hooks/AntComponents/useNotification";
import { filterAndSortData, FilterAndSortData, getSearchParams } from "../DisplayMedia/Filter/FilterAndSortData";
import { MEDIA_ORDER_OPTION, MEDIA_VIEW_OPTION } from "../enum";
import { ChangeDisplayOption, ChangeOrderOption } from "../DisplayMedia/DisplayMediaList/ChangeDisplayOption";
import { P } from "../../../reusableComponents/Text/Paragraph/P";
import { Header } from "../../../reusableComponents/Text/Header/Header";
import { useSearchParamsUtil } from "../../../../hooks/useSearchParams";
import { useScreenSize } from "../../../../hooks/useCheckWindowSize";
import "./LijstWatch.css";
import { Sidebar } from "../DisplayMedia/Filter/Sidebar/SidebarWithFilters";
import { EditLijstModal } from "./EditLijstModal/EditLijstModal";
import { MediaModel } from "../DisplayMedia/DisplayOneMedia/Model/MediaModel";
import { DisplayMediaCard } from "../DisplayMedia/DisplayMediaList/Card/Card";
import DisplayMediaList from "../DisplayMedia/DisplayMediaList/List/List";

const { Sider } = Layout;

export function LijstWatch({ setMedialijstBreadcrumb }) {
    const notification = useNotification();
    const params = useParams();
    const { user } = useSelector((state) => state.user);
    const [lijst, setLijst] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchLijst() {
            try {
                const lijstResponse = await fetchApiSendJson(`media/lijst/${params.id}/${user._id}`);
                const lijstData = await checkFetchError(lijstResponse);

                setLijst(lijstData);
                setMedialijstBreadcrumb(lijstData.titel);
            } catch (err) {
                setLijst(null);
                setError(err);

                if (err.code !== 404) {
                    notification.error({
                        key: "error",
                        message: "Fout opgetreden",
                        description: err.message,
                        duration: 20,
                        placement: NotificationPlacement.TOP,
                    });
                }
            }
        }

        if (user && user._id) {
            fetchLijst();
        }
    }, [params.id, user, notification]);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (!lijst) return;

        setMedialijstBreadcrumb(lijst.titel);
    }, [lijst]);

    if (!lijst) {
        if (error) {
            if (error.code === 404) {
                return (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, de medialijst die je probeert te bezoeken bestaat niet."
                        extra={(
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/media");
                                }}
                            >
                                Terug naar media overzicht
                            </Button>
                        )}
                    />
                );
            }

            return <P>{error.message}</P>;
        }

        // TODO: skeleton loader voor alle components er onder
        return (
            <Empty description={(
                <Typography.Text>
                    Er is nog geen data beschikbaar. We zijn bezig!
                </Typography.Text>
            )}
            />
        );
    }

    return (
        <Layout style={{ overflow: "visible" }}>
            {/* Title en beschijf ofzo */}
            {/* <Header level={2}>{lijst.titel}</Header> */}

            <LijstData lijst={lijst} setLijst={setLijst} />
        </Layout>
    );
}

export function LijstData({ lijst, setLijst }) {
    const [searchParams] = useSearchParams();
    const filters = useMemo(() => getSearchParams(searchParams), [searchParams]);
    const filteredData = useMemo(() => filterAndSortData(lijst?.media || [], filters), [lijst?.media, filters]);

    const { updateFilters, getFilterValue } = useSearchParamsUtil();
    const openEdit = getFilterValue("edit") === "true" || false;
    const openMediaModal = !!getFilterValue("mediaModal");
    const [openMediaModalId, setOpenMediaModalId] = useState(null);
    const [mediaModal, setMediaModal] = useState(null);
    const { user } = useSelector((state) => state.user);
    const notification = useNotification();
    const message = useMessage();

    function kut() {
        setOpenMediaModalId(getFilterValue("mediaModal"));
        const foundMedia = lijst.media.find((media) => media._id === getFilterValue("mediaModal"));
        setMediaModal(foundMedia);
    }
    useEffect(() => {
        if (openMediaModal) {
            kut();
        } else {
            setMediaModal(null);
        }
    }, [openMediaModal, getFilterValue, lijst.media]);

    const changeViewOption = (e) => {
        updateFilters({ view: e });
    };
    const changeOrder = (e) => {
        updateFilters({ order: e });
    };
    const changeOrderOption = (e) => {
        updateFilters({ orderOption: e });
    };

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const updateMedia = (mediaNew) => {
        // Update media in lijst, als je bijv media data heb aangepast

        const newLijst = lijst.media.map((media) => {
            if (media._id === mediaNew._id) {
                return mediaNew;
            }
            return media;
        });

        setLijst({ ...lijst, media: newLijst });
        setMediaModal(mediaNew);
    };

    const updateMediaInLijst = async (media) => {
        setLijst({ ...lijst, media });
    };

    return (
        // Hier moet de layout die alles in elkaar koppelt, zie; 'medialijstopened in oud
        <Layout className="" style={{ }}>
            <Content className="font">
                <Row gutter={[16, 16]} type="flex">
                    <Col xs={24} md={6} className="sidebarMedialist" style={{ display: "inline-block" }}>
                        <div className="">
                            <Header level={4}>Filters</Header>
                            <Sidebar />
                        </div>
                    </Col>
                    <Col xs={24} md={18} className="">

                        {/* Wat nog te fixen:  */}
                        {/* de knop staat er nu een rij onder, miss mooier alsie op zelfde rij kan. */}
                        {/* img kleiner laten worden op mobile size */}
                        {/* truncate titel als die te lang wordt */}
                        {/* naar ant dingen en tailwind ivm darkmode */}

                        {lijst?.titel && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start", // zorgt dat alles links uitgelijnd is op kleinere schermen
                                    // background: "rgba(253,211,56,0.6)",
                                    // color: "white",
                                    padding: "20px",
                                    justifyContent: "space-between", // Dit zorgt ervoor dat de kinderen verdeeld worden over de volledige breedte
                                }}
                                className="responsive-container mb-8"
                            >
                                <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                    <img
                                        src={lijst.poster_img}
                                        alt="Album Art"
                                        style={{
                                            height: "300px",
                                            marginRight: "20px",
                                            flexShrink: 0,
                                        }} // Voorkomt dat de afbeelding krimpt
                                    />
                                    <div style={{ flexGrow: 1 }}>
                                        <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "8px" }}>
                                            Medialijst
                                        </div>
                                        <div style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "8px" }}>
                                            {lijst.titel}
                                        </div>
                                        <div>
                                            <span>{lijst.beschrijving}</span>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    size="small"
                                    type="default"
                                    icon={<EditOutlined />}
                                    onClick={() => updateFilters({ edit: true })}
                                    style={{
                                        alignSelf: "flex-end",
                                        marginTop: "10px",
                                    }} // Zorgt dat de knop aan de onderkant rechts uitgelijnd wordt
                                >
                                    Edit
                                </Button>
                            </div>
                        )}

                        <OrderAndViewOptions
                            results={filteredData.length || "Niet bekend"}
                            setViewOption={changeViewOption}
                            setOrder={changeOrder}
                            setOrderOption={changeOrderOption}
                        />

                        <Divider />

                        {/*    media weergaven */}
                        <DisplayMedialist media={filteredData} />

                    </Col>
                </Row>
            </Content>

            {openEdit && !lijst.error && (
                <EditLijstModal
                    lijst={lijst}
                    setLijst={setLijst}
                />
            )}

            {openMediaModal && !lijst.error && (
                <MediaModel
                    media={mediaModal}
                    mediaId={openMediaModalId}
                    updateMedia={updateMedia}
                    lijstId={lijst._id}
                    updateMediaInLijst={updateMediaInLijst}
                />
            )}

            <FloatButton.BackTop />
        </Layout>
    );
}

{ /* header balk = volgorde filter en vieuw option    */
}

function OrderAndViewOptions({
    results, setViewOption, setOrder, setOrderOption,
}) {
    const { width } = useScreenSize();

    const isMobile = width < 950;
    return (
        <div>
            <Row align="middle" direction={isMobile ? "vertical" : "horizontal"}>
                <Col span={isMobile ? 24 : 8}>
                    <Header level={5}>
                        {results}
                        {" "}
                        resultaten
                    </Header>
                </Col>

                <Col
                    span={isMobile ? 24 : 8}
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                    <ChangeOrderOption setOrderOption={setOrderOption} setOrder={setOrder} />
                </Col>

                <Col
                    span={isMobile ? 24 : 8}
                    style={{ textAlign: "right" }}
                    className={`${isMobile ? "MarginAbove" : ""}`}
                >
                    <ChangeDisplayOption setViewOption={setViewOption} />
                </Col>
            </Row>
        </div>
    );
}

function DisplayMedialist({ media }) {
    const [searchParams] = useSearchParams();
    const filters = getSearchParams(searchParams);

    const {
        view,
    } = filters;

    if (!media.length) {
        return (
            <Empty
                description={(
                    <Typography.Text>
                        Er is geen media gevonden met de geselecteerde filters 😢
                    </Typography.Text>
                )}
            />
        );
    }

    switch (view) {
    case MEDIA_VIEW_OPTION.CARD:
        return <DisplayMediaCard media={media} />;
    case MEDIA_VIEW_OPTION.LIST:
        return <DisplayMediaList media={media} />;
    case MEDIA_VIEW_OPTION.TABLE:
        return <div>Table</div>;
    default:
        return <div>List</div>;
    }
}
