export const SPECIAL_LIJSTEN = {
    MOVIE: "MOVIE",
    SERIE: "SERIE",
    ARCHIVE: "ARCHIVE",
    ALL: "ALL",
};

export const MEDIA_VIEW_OPTION = {
    CARD: "card",
    LIST: "list",
    TABLE: "table",
};

export const MEDIA_ORDER_OPTION = {
    DEFAULT: "DEFAULT",
    REVERSE: "REVERSE",
};

export const ORDER_OPTIONS = {
    LAATST_BEKEKEN: "laatst_bekeken",
    KIJK_PRIORITEIT: "kijk_prioriteit",
    MENING: "mening",
    TYPE: "type",
    SOORT: "soort",
    KIJK_STATUS: "kijk_status",
    TITEL: "titel",
};

export const ORDER = {
    ASC: "asc",
    DESC: "desc", // default
};
