import {
    Table, Form, Select, Image, Space, Button, Input, Checkbox,
} from "antd";
import React, { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useMessage } from "../../../../../../../../hooks/AntComponents/useMessage";
import { checkFetchError, fetchApiSendJson } from "../../../../../../../../snippets/functions/fetchFunctions";
import NewSourceModal from "./Modals/NewSource";

function MediaSourcesPanelEasy({ originalSources, mediaId }) {
    // Get source enums!
    const [form] = Form.useForm();
    const message = useMessage();
    const { user } = useSelector((state) => state.user);
    const [sources, setSources] = useState(originalSources);
    const [addSourceModalVisible, setAddSourceModalVisible] = useState(false);

    const columns = [
        {
            title: "siteNaam",
            dataIndex: "siteNaam",
            key: "siteNaam",
        },
        {
            title: "sourceNaam",
            dataIndex: "sourceNaam",
            key: "sourceNaam",
        },
        {
            title: "mening",
            dataIndex: "mening",
            key: "mening",
        },
        {
            title: "default",
            dataIndex: "default",
            render: (text, record) => (
                <Checkbox
                    checked={record.default}
                    disabled
                />
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        disabled
                        onClick={() => {
                            console.log(record);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => {
                            // console.log(record);
                            deleteSource(record);
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            getSource(record);
                        }}
                    >
                        Get
                    </Button>
                </Space>
            ),
        },
    ];

    const getSource = async (source) => {
        try {
            const response = await fetchApiSendJson(`media/source/single/${mediaId}/${source._id}/${user._id}`, "GET");
            const responseData = await checkFetchError(response);

            navigator.clipboard.writeText(responseData.siteUrlEncrypt).then(() => {
                message.success({ content: "Succesvol gekopieerd!" });
            }).catch((err) => {
                console.error("Failed to copy: ", err);
                message.error({ content: "Niet gelukt om te kopieeren" });
            });
        } catch (error) {

        }
    };

    const deleteSource = async (source) => {
        try {
            const response = await fetchApiSendJson(`media/source/${mediaId}/${source._id}/${user._id}`, "DELETE");
            await checkFetchError(response);

            message.success({ content: "Source verwijderd", key: "deletesource" });
            const newSources = sources.filter((s) => s._id !== source._id);
            setSources(newSources);
            form.setFieldsValue({ mediaSources: newSources });
        } catch (error) {
            message.error({ content: error.message, key: "deletesource" });
        }
    };

    const addSource = (source) => {
        const newSources = [...sources, source];
        setSources(newSources);
        form.setFieldsValue({ mediaSources: newSources });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="media_source_form"
            // onFinish={onSubmit}
            initialValues={{
                mediaSources: originalSources,
            }}
        >
            <div className="p-5">
                <div className="w-full">
                    <Form.Item
                        label="Nieuwe source"
                        name="newSource"
                        className="form-item"
                        rules={[{ required: false, message: "" }]}
                    >
                        <Table
                            className="w-full"
                            columns={columns}
                            dataSource={sources}
                            // rowSelection={rowSelection}
                            rowKey={(record) => record._id} // Ensure each row has a unique key
                        />
                    </Form.Item>
                </div>
            </div>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                        setAddSourceModalVisible(true);
                    }}
                >
                    Nieuwe source
                </Button>
            </Form.Item>

            <NewSourceModal
                visible={addSourceModalVisible}
                close={() => setAddSourceModalVisible(false)}
                userId={user._id}
                mediaId={mediaId}
                addSource={(newSource) => addSource(newSource)}
            />
        </Form>
    );
}

export default MediaSourcesPanelEasy;
