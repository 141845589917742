import { useSelector } from "react-redux";
import clipboard from "clipboard-copy";
import { checkFetchError, fetchApiSendJson } from "../../../../snippets/functions/fetchFunctions";
import { useMessage } from "../../../../hooks/AntComponents/useMessage";
import { useNotification } from "../../../../hooks/AntComponents/useNotification";

function useGetMediaSource() {
    const { user } = useSelector((state) => state.user);
    const message = useMessage();
    const notification = useNotification();

    const getMediaSource = async ({ id, setLoadingSource, loadingSource }) => {
        if (loadingSource) return;

        setLoadingSource(true);

        message.loading({ content: "Loading media source...", key: "loadingSource" });

        try {
            const mediaSourceRespone = await fetchApiSendJson(`media/source/${id}/${user._id}`, "GET");
            const mediaSourceData = await checkFetchError(mediaSourceRespone);

            if (mediaSourceData && mediaSourceData.siteUrlEncrypt) {
                await clipboard(mediaSourceData.siteUrlEncrypt);
                setTimeout(() => {
                    setTimeout(() => {
                        message.success({
                            content: `Gekopieerd! van: ${mediaSourceData.siteNaam}`,
                            key: "loadingSource",
                            duration: 2,
                        });
                        notification.closeAll();
                    }, 500);
                }, 300);
            } else {
                throw new Error("Failed to load media source");
            }
        } catch (error) {
            message.destroy("loadingSource");

            if (error.code === 404) {
                setTimeout(() => {
                    notification.error({
                        message: "Media source niet gevonden",
                        description: error.message,
                    });
                }, 600);
            } else {
                notification.error({ message: "Er is iets fout gegaan", description: error.message });
            }
        } finally {
            setLoadingSource(false);
        }
    };

    return {
        getMediaSource,
    };
}

export { useGetMediaSource };
