import React, { useState } from "react";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";

import { ConfigProvider, theme } from "antd";
import { HeaderComponent } from "./components/oneTimeComponents/header/Header";
import { Footer } from "./components/oneTimeComponents/footer/Footer";
import { AppRoutes } from "./utils/router";
import { useDarkMode } from "./utils/Darkmode";

export function App() {
    const { darkMode } = useDarkMode();
    return (
        <BrowserRouter>
            <ConfigProvider
                theme={{
                    algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                    token: {
                        colorPrimary: "#34494f",
                        fontFamily: "Arial", // Set the font family of the token,
                        // colorText: darkMode ? "#ffc037" : "#a90000",
                    },
                    components: {
                        Layout: {
                            colorBgHeader: "", // Alleen deze werkt. ;/
                        },
                    },
                }}
            >
                <HeaderComponent />
                <AppRoutes />
                {/* <Footer /> */}
            </ConfigProvider>
        </BrowserRouter>
    );
}
