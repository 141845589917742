import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

export function Header({
    children,
    level = 1,
    className,
    defaultDarkMode = true,
    ...rest
}) {
    let defaultClass = "font-bold tracking-tight";

    switch (level) {
    case 1:
        defaultClass += " text-3xl sm:text-5xl";
        break;
    case 2:
        defaultClass += " text-2xl sm:text-4xl";
        break;
    case 3:
        defaultClass += " text-xl sm:text-3xl";
        break;
    case 4:
        defaultClass += " text-lg sm:text-2xl";
        break;
    case 5:
        defaultClass += " text-md sm:text-xl";
        break;
    default:
        defaultClass += " text-base sm:text-lg";
        break;
    }

    if (defaultDarkMode) defaultClass += " dark:text-white"; // De extra whitespace is nodig anders; staat hij direct tegen anderen classes aan

    return (
        <Title level={level} className={`${defaultClass} ${className}`} {...rest}>
            {children}
        </Title>
    );
}
