import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    Col,
    Collapse,
    Form,
    Image,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Rate,
    Row,
    Space,
    Statistic, Table,
    Tag,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { PlayCircleRounded } from "@mui/icons-material";
import moment from "moment/moment";
import {
    ArrowsAltOutlined, DeleteOutlined,
    EditOutlined, LikeOutlined, SnippetsOutlined, StarFilled,
} from "@ant-design/icons";
import ReactPlayer from "react-player";
import clipboard from "clipboard-copy";
import { useSearchParamsUtil } from "../../../../../../hooks/useSearchParams";
import { P } from "../../../../../reusableComponents/Text/Paragraph/P";
import { Header } from "../../../../../reusableComponents/Text/Header/Header";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { checkFetchError, fetchApiSendJson, fetchMovieDbApi } from "../../../../../../snippets/functions/fetchFunctions";
import "./MediaModel.css";
import { useNotification } from "../../../../../../hooks/AntComponents/useNotification";
import { useMessage } from "../../../../../../hooks/AntComponents/useMessage";
import { GetMeningTag } from "../../../Tags/Tags";
import { ChangeMediaRatingModal } from "../../../MediaRatingModal/MediaRatingModel";
import { InWhatOtherMediaLists } from "../sharedComponents/InWhatOtherMediaLists/InWhatOtherMediaLists";

const { TextArea } = Input;

async function GetSource({ sourceId, mediaId, userId }) {
    let getDecryptSourceRequest;
    if (sourceId) {
        getDecryptSourceRequest = await fetchApiSendJson(`media/source/single/${mediaId}/${sourceId}/${userId}`, "GET");
    } else {
        getDecryptSourceRequest = await fetchApiSendJson(`media/source/${mediaId}/${userId}`, "GET");
    }
    const getDecryptSourceResponse = await checkFetchError(getDecryptSourceRequest);

    console.log(getDecryptSourceResponse);

    if (getDecryptSourceResponse.siteUrlEncrypt) {
        return {
            siteUrlEncrypt: getDecryptSourceResponse.siteUrlEncrypt,
            siteNaam: getDecryptSourceResponse.siteNaam,
        };
    }
    return {
        siteUrlEncrypt: null,
        siteNaam: null,
    };
}

export function MediaModel({
    media, mediaId, updateMedia, lijstId, updateMediaInLijst,
}) {
    const { getFilterValue, removeFilter } = useSearchParamsUtil();
    const dispatch = useDispatch();
    const openEdit = !!getFilterValue("mediaModal");
    // const [loading, setLoading] = useState(false);
    const [movieDbDetails, setMovieDbDetails] = useState(null);
    const [movieDbPoster, setMovieDbPoster] = useState(null);
    const [carouselImages, setCarouselImages] = useState(null);
    const [trailerUrl, setTrailerUrl] = useState(null);
    const [crew, setCrew] = useState(null);
    const [ratingModal, setRatingModal] = useState(false);
    const { user } = useSelector((state) => state.user);
    const message = useMessage();
    const [loadingSource, setLoadingSource] = useState(false);
    const [error, setError] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 2,
        speed: 300,
        swipeToSlide: false,
    };

    const [form] = Form.useForm();
    const [formChanged, setFormChanged] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const notification = useNotification();

    const onClose = () => {
        removeFilter("mediaModal");
    };

    const resetFields = () => {
        form.resetFields();
        setFormChanged(false);
    };

    useEffect(() => {
        if (!openEdit) {
            setFormChanged(false);
            resetFields();
        }
    }, [openEdit, form, mediaId, media, formChanged]);

    const onValuesChange = () => {
        setFormChanged(true);
    };

    const handleSubmit = async (values) => {
        if (!values) {
            onClose();
            return notification.info({ message: "Geen wijzigingen gevonden" });
        }

        setConfirmLoading(true);

        try {
            const data = values.mediaData;
            let updateMediaRequest;
            if (media.mediaType === "Serie" || (media && media.themoviedb_type === "Tv")) {
                updateMediaRequest = await fetchApiSendJson(`media/mediaData/${mediaId}/${user._id}`, "PUT", {
                    mediaData: data,
                });
            } else {
                updateMediaRequest = await fetchApiSendJson(`media/mediaData/${mediaId}/${user._id}`, "PUT", {
                    mediaData: data,
                });
            }

            const updatedMediaResponse = await checkFetchError(updateMediaRequest);

            updateMedia(updatedMediaResponse);
            setFormChanged(false);

            setTimeout(() => {
                setConfirmLoading(false);
                onClose();

                notification.success({ message: `Media info bijgewerkt van: ${media.titel}` });
            }, [300]);
        } catch (e) {
            setConfirmLoading(false);

            if (e.code === 304) {
                onClose();
                return notification.info({ message: "Er zijn geen wijzigingen gevonden om op te slaan" });
            }
            notification.error({ message: "Er is iets fout gegaan", description: e.message });
        }
    };

    const handleCancel = () => {
        if (formChanged) {
            handleSubmit(form.getFieldsValue());
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (!media || !media.themoviedb_id) return;

        async function fetchDetails() {
            try {
                const mediaType = media.mediaType === "Serie" ? "tv" : "movie";

                const fetchMovieDbDetails = await fetch(`https://api.themoviedb.org/3/${mediaType}/${media.themoviedb_id}`, {
                    method: "GET",
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
                    },
                }).then((res) => res.json());

                setMovieDbDetails(fetchMovieDbDetails);

                if (!media.poster_img) {
                    const fetchMovieDbPoster = await fetch(`https://api.themoviedb.org/3/${mediaType}/${media.themoviedb_id}/images`, {
                        method: "GET",
                        headers: {
                            accept: "application/json",
                            Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
                        },
                    }).then((res) => res.json());

                    setMovieDbPoster(`https://image.tmdb.org/t/p/original${fetchMovieDbPoster.posters[0].file_path}`);
                    setCarouselImages(fetchMovieDbPoster.backdrops);
                }

                const fetchMovieDbTrailer = await fetch(`https://api.themoviedb.org/3/${mediaType}/${media.themoviedb_id}/videos`, {
                    method: "GET",
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
                    },
                }).then((res) => res.json());

                const officialTrailers = fetchMovieDbTrailer.results.filter((trailer) => trailer.type === "Trailer" && trailer.official);
                const highestQualityTrailer = officialTrailers.reduce((prev, current) => (prev.size > current.size ? prev : current));

                if (!highestQualityTrailer) {
                    setTrailerUrl(`https://www.youtube.com/watch?v=${fetchMovieDbTrailer.results[0].key}`);
                } else {
                    setTrailerUrl(`https://www.youtube.com/watch?v=${highestQualityTrailer.key}`);
                }

                const fetchMovieDbCrew = await fetch(`https://api.themoviedb.org/3/${mediaType}/${media.themoviedb_id}/credits`, {
                    method: "GET",
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_TMDB_API_KEY}`,
                    },
                }).then((res) => res.json());

                setCrew(fetchMovieDbCrew);
            } catch (error) {
                console.error("Failed to fetch movie details", error);
            }
        }

        fetchDetails();
    }, [media]);

    if (!media) {
        return null;
    }

    function BottemTitleInfo() {
        if (movieDbDetails) {
            return (
                <Space>
                    <Tag>
                        {media.mediaType}
                    </Tag>
                    <Tag>
                        {media.mediaType === "Serie" && (
                            movieDbDetails.first_air_date
                                ? moment(movieDbDetails.first_air_date).format("YYYY")
                                : ""
                        )}
                        {media.mediaType === "Film" && (
                            movieDbDetails.release_date
                                ? moment(movieDbDetails.release_date).format("YYYY")
                                : ""
                        )}
                    </Tag>
                    <Tag>
                        {media.mediaType === "Serie" && (
                            movieDbDetails.episode_run_time
                                ? `${`${movieDbDetails.episode_run_time} minuten` || `${movieDbDetails.episode_run_time[0]} minuten`}`
                            || movieDbDetails.last_episode_to_air.runtime : ""
                        )}

                        {media.mediaType === "Film" && (
                            movieDbDetails.runtime
                                ? `${movieDbDetails.runtime} minuten`
                                : "Geen runtime"
                        )}
                    </Tag>
                    {media.mening && (
                        <GetMeningTag mening={media.mening} />
                    )}
                </Space>
            );
        }
        return (
            <Space>
                <Tag>
                    {media.mediaType}
                </Tag>
                <Tag>
                    {media.release_date
                        ? moment(media.release_date).format("YYYY")
                        : "Geen release date"}
                </Tag>
                <Tag>
                    {media.runtime
                        ? `${media.runtime} minuten`
                        : "Geen runtime"}
                </Tag>
                {media.mening && (
                    <GetMeningTag mening={media.mening} />
                )}
            </Space>
        );
    }

    const getDecryptSource = async () => {
        setLoadingSource(true);

        message.loading({ content: "Ophalen van gekozen source", key: "loadingMessage" });
        try {
            const getDecryptSourceResponse = await GetSource({ mediaId, userId: user._id });

            if (getDecryptSourceResponse.siteUrlEncrypt) {
                clipboard(getDecryptSourceResponse.siteUrlEncrypt).then(() => {
                    setTimeout(() => {
                        message.success({ content: `Gekopieerd! van: ${getDecryptSourceResponse.siteNaam}`, key: "loadingMessage", duration: 2 });
                        setLoadingSource(false);
                    }, 700);
                }).catch(() => {
                    message.error({ content: "Niet gelukt om te kopieern naar clipboard", key: "loadingMessage", duration: 2 });
                    setLoadingSource(false);
                });
            }
        } catch (e) {
            console.error(e);
            message.error({ content: "Failed to get source", key: "loadingMessage", duration: 2 });
            setLoadingSource(false);
        }
    };

    const deleteMediaFromList = async () => {
        setLoadingDelete(true);

        try {
            const deleteMediaFromListResponse = await fetchApiSendJson(`media/lijst/${lijstId}/${mediaId}/${user._id}`, "DELETE", {
                media_id: mediaId,
            });
            const deleteMediaResponse = await checkFetchError(deleteMediaFromListResponse);

            if (deleteMediaResponse?.media.length) {
                updateMediaInLijst(deleteMediaResponse.media);
                message.success({ content: "Media verwijderd uit lijst", key: "loadingMessage", duration: 2 });

                onClose();
            } else console.log("probleem");
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingDelete(false);
        }

        // delete1MediaFromLijst(mediaId);
    };
    return (
        <Modal
            open={openEdit}
            centered
            onCancel={handleCancel}
            okButtonProps={{
                htmlType: "submit",
            }}
            title={(
                <Row gutter={24} justify="space-between" className="pr-3" align="middle">
                    <Col>
                        <Button className=" border-none shadow-none" disabled={loadingSource}>
                            <PlayCircleRounded onClick={getDecryptSource} />
                        </Button>
                    </Col>

                    <Col>
                        {/* Deze is niet margin top in de middel */}
                        <Header className="">
                            {media.titel}
                        </Header>
                    </Col>

                    <Col
                        className="ml-auto"
                        onClick={() => {
                            // open popconfirm
                            setRatingModal(true);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Statistic
                            className="uppercase"
                            title="Mijn Rating"
                            value={media.rating}
                            suffix="/ 5"
                            prefix={<StarFilled style={{ color: "#f5c518" }} />}
                        />
                    </Col>

                    <Col>
                        <Statistic
                            title="MovieDB rating"
                            className="w-full max-w-full"
                            value={movieDbDetails && movieDbDetails?.vote_average ? movieDbDetails?.vote_average.toFixed(2) : ""}
                            suffix="/ 100"
                        />
                    </Col>
                </Row>
            )}
            width={1300}
            footer={(
                <Space>
                    <Button onClick={() => navigate(`/media/media/edit/${mediaId}`)} type="link">
                        <EditOutlined />
                    </Button>
                    <Button onClick={() => console.log("navigatie to full page")} type="link">
                        Details
                        <ArrowsAltOutlined />
                    </Button>
                    {updateMediaInLijst && (
                        <Popconfirm
                            title="Verwijder deze media uit de lijst?"
                            onConfirm={() => deleteMediaFromList()}
                            okText="Ja"
                            cancelText="Nee"
                        >
                            <Button type="link" danger>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    )}
                    <Button onClick={handleCancel}>Sluit</Button>
                    <Button type="primary" htmlType="submit" loading={confirmLoading}>Sla op</Button>
                </Space>
            )}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    form={form}
                    name="edit_media_info"
                    onValuesChange={onValuesChange}
                    initialValues={{
                        mediaData: {
                            seizoen: media.mediaData.seizoen,
                            aflevering: media.mediaData.aflevering,
                            afleveringGekeken: media.mediaData.afleveringGekeken,
                            kijkMinuut: media.mediaData.kijkMinuut,
                            seconden: media.mediaData.seconden,
                        },
                    }}
                    onFinish={handleSubmit}
                    size="medium"
                >
                    {dom}
                </Form>
            )}
        >
            {/*         Kijk draw.io ! daar heb ik design gemaakt! */}
            <div className="w-full">
                <BottemTitleInfo />
            </div>
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xxl:gap-12">
                    <div className="md:col-span-1 pt-3">
                        <Image
                            src={media.poster_img || media.imageUrl || movieDbPoster}
                            alt="poster"
                            className="w-full h-full object-cover border-2 border-gray-200"
                        />
                    </div>

                    {/* Zeker nog naar responsive kijken! */}
                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 xxl:gap-12">
                            <div className="xxl:gap-8 md:col-span-1">
                                {(media.mediaType === "Serie" || media.themoviedb_type === "Tv") && (
                                    <>
                                        <Form.Item
                                            name={["mediaData", "seizoen"]}
                                            label="Seizoen"
                                            rules={[{
                                                required: false, message: "Vul een seizoen nummer in", type: "number",
                                            }]}
                                        >
                                            <InputNumber placeholder="seizoen" min={0} max={50} />
                                        </Form.Item>

                                        <Form.Item
                                            name={["mediaData", "aflevering"]}
                                            label="Aflevering"
                                            rules={[{ required: false, message: "Vul een aflevering in", type: "number" }]}
                                        >
                                            <InputNumber placeholder="Aflevering" min={0} max={100} />
                                        </Form.Item>

                                        <Form.Item
                                            name={["mediaData", "afleveringGekeken"]}
                                            label="Aflevering gekeken"
                                            rules={[{ required: false, message: "Vul een aflevering in", type: "number" }]}
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </>
                                )}

                                <Form.Item
                                    name={["mediaData", "kijkMinuut"]}
                                    label="Kijk minuut"
                                    rules={[{ required: false, message: "Vul een kijk minuut in", type: "number" }]}
                                >
                                    <InputNumber placeholder="Kijk minuut" min={0} max={100} />
                                </Form.Item>

                                <Form.Item
                                    name={["mediaData", "seconden"]}
                                    label="Seconden"
                                    rules={[{
                                        required: false,
                                        message: "Vul een seconden bekeken in",
                                        type: "number",
                                    }]}
                                >
                                    <InputNumber placeholder="Seconden bekeken" min={0} max={60} />
                                </Form.Item>
                            </div>

                            <div className="space-y-0 gap-0 xxl:gap-8 md:col-span-1">
                                {/*        Sources table! */}
                                <SourceTable sources={media.mediaSource} mediaId={mediaId} />
                            </div>
                        </div>

                        {movieDbDetails && (
                            <div className="m-3 mb-8" style={{ color: "black" }}>
                                <Header level={3}>Info</Header>
                                <Image.PreviewGroup>
                                    <Slider {...settings}>
                                        {carouselImages && carouselImages.map((image) => (
                                            <Image
                                                key={image.file_path}
                                                src={`https://image.tmdb.org/t/p/original${image.file_path}`}
                                                alt="carousel"
                                                className="object-cover border-2 border-gray-200"
                                            />
                                        ))}
                                    </Slider>
                                </Image.PreviewGroup>
                            </div>
                        )}

                        <div className="mt-16">
                            {movieDbDetails && trailerUrl && (
                                <ReactPlayer controls width="100%" style={{ width: "100%" }} url={trailerUrl} />
                            )}
                        </div>

                        <div>
                            <Header level={4}>Notities</Header>
                            <TextArea rows={2} value={media.notities} />
                        </div>

                        {movieDbDetails && movieDbDetails.overview !== "" && (
                            <div>
                                <Header level={4}>Beschrijving</Header>
                                <TextArea rows={4} value={movieDbDetails.overview} />
                            </div>
                        )}

                        {movieDbDetails && movieDbDetails.genres && (
                            <div>
                                <Header level={4}>Genres</Header>
                                <Space>
                                    {movieDbDetails.genres.map((genre) => (
                                        <Tag key={genre.id}>{genre.name}</Tag>
                                    ))}
                                </Space>
                            </div>
                        )}

                        <div>
                            <Header level={4}>Anderen lijsten</Header>
                            <InWhatOtherMediaLists mediaId={mediaId} userId={user._id} />
                        </div>

                    </div>

                </div>
            </div>

            <ChangeMediaRatingModal
                rating={media.rating}
                mediaId={media._id}
                notities={media.notities}
                onClose={() => setRatingModal(false)}
                open={ratingModal}
                updateMedia={updateMedia}
            />

        </Modal>
    );
}

function SourceTable({ sources, mediaId }) {
    const { user } = useSelector((state) => state.user);
    const message = useMessage();
    const [loadingSource, setLoadingSource] = useState(false);

    const getDecryptSource = async (sourceId) => {
        setLoadingSource(true);

        message.loading({ content: "Ophalen van gekozen source", key: "loadingMessage" });
        try {
            const getDecryptSourceResponse = await GetSource({ sourceId, mediaId, userId: user._id });

            if (getDecryptSourceResponse.siteUrlEncrypt) {
                clipboard(getDecryptSourceResponse.siteUrlEncrypt).then(() => {
                    setTimeout(() => {
                        message.success({ content: `Gekopieerd! van: ${getDecryptSourceResponse.siteNaam}`, key: "loadingMessage", duration: 2 });
                        setLoadingSource(false);
                    }, 700);
                }).catch(() => {
                    message.error({ content: "Niet gelukt om te kopieern naar clipboard", key: "loadingMessage", duration: 2 });
                    setLoadingSource(false);
                });
            }
        } catch (e) {
            console.error(e);
            message.error({ content: "Failed to get source", key: "loadingMessage", duration: 2 });
            setLoadingSource(false);
        }
    };
    const columns = [
        {
            title: "Naam",
            dataIndex: "sourceNaam",
            key: "sourceNaam",
        },
        {
            title: "Default?",
            key: "default",
            render: (record) => (
                <Checkbox checked={record.default} disabled />
            ),
        },
        {
            title: "Mening",
            key: "mening",
            render: (record) => (
                <GetMeningTag mening={record.mening} />
            ),
        }, {
            title: "Get",
            key: "get",
            render: (record) => (
                <Button onClick={() => getDecryptSource(record._id)} disabled={loadingSource}>
                    <SnippetsOutlined />
                </Button>
            ),
        },
    ];

    return (
        <Table
            dataSource={sources}
            className="w-full"
            columns={columns}
            pagination={false}
        />
    );
}
