import React from "react";
import { Button, Segmented, Select } from "antd";
import {
    DownOutlined,
    IdcardOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
    TableOutlined,
    UnorderedListOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { MEDIA_VIEW_OPTION, ORDER, ORDER_OPTIONS } from "../../enum";
import { getSearchParams } from "../Filter/FilterAndSortData";

export function ChangeDisplayOption({ setViewOption }) {
    const [searchParams] = useSearchParams();
    const filters = getSearchParams(searchParams);

    return (
        <Segmented
            size="large"
            defaultValue={filters.view || MEDIA_VIEW_OPTION.CARD}
            onChange={setViewOption}
            options={[
                { value: MEDIA_VIEW_OPTION.CARD, label: <span className="label-text">Card</span>, icon: <IdcardOutlined /> },
                { value: MEDIA_VIEW_OPTION.LIST, label: <span className="label-text">List</span>, icon: <UnorderedListOutlined /> },
                { value: MEDIA_VIEW_OPTION.TABLE, label: <span className="label-text">Table</span>, icon: <TableOutlined /> },
            ]}
        />
    );
}

export function ChangeOrderOption({ setOrder, setOrderOption }) {
    const [searchParams] = useSearchParams();
    const filters = getSearchParams(searchParams);

    return (
        <>
            <Select size="large" defaultValue={filters.orderOption} style={{ width: "80%" }} onSelect={setOrderOption}>
                <Select.Option value={ORDER_OPTIONS.LAATST_BEKEKEN}>Laatst bekeken</Select.Option>
                <Select.Option value={ORDER_OPTIONS.KIJK_PRIORITEIT}>Prioriteit</Select.Option>
                <Select.Option value={ORDER_OPTIONS.MENING}>Mening</Select.Option>
                <Select.Option value={ORDER_OPTIONS.TYPE}>Type media</Select.Option>
                <Select.Option value={ORDER_OPTIONS.KIJK_STATUS}>Kijk status</Select.Option>
                <Select.Option value={ORDER_OPTIONS.TITEL}>Titel</Select.Option>
            </Select>

            <Button
                size="large"
                type="default"
                onClick={() => {
                    if (filters.order === ORDER.DESC) {
                        setOrder(ORDER.ASC);
                    } else {
                        setOrder(ORDER.DESC);
                    }
                }}
            >
                {/* {filters.order ? <UpOutlined /> : <DownOutlined />} */}
                {filters.order === ORDER.DESC ? <SortDescendingOutlined style={{ fontSize: "175%" }} /> : <SortAscendingOutlined style={{ fontSize: "175%" }} />}
            </Button>
        </>

    );
}
