import React from "react";
import { Row, Col, Typography } from "antd";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import moment from "moment";

const { Title } = Typography;

function ContentInfo({ overview, trailerUrl, settingsTrailer }) {
    return (
        <div>
            <div className="px-7 pt-3 mt-3">
                <Row gutter={[16, 16]}>
                    <Col>
                        <p>{overview}</p>
                    </Col>
                </Row>
            </div>

            {trailerUrl && trailerUrl.length > 0 && (
                <div className="px-7 pt-3 mt-3">
                    <div className="flex items-center mb-2">
                        <div className="bg-yellow-500 h-6 w-1 mr-2" />
                        <Title level={2} className="flex items-center m-0">
                            Trailers
                            {trailerUrl.length !== undefined && <span className="text-gray-500 ml-2 text-sm">{trailerUrl.length}</span>}
                        </Title>
                    </div>

                    <div className="w-full">
                        <Slider {...settingsTrailer}>
                            {trailerUrl.map((trailer) => (
                                <div className="m-1" key={trailer.key}>
                                    <ReactPlayer
                                        controls
                                        className="m-1"
                                        url={`https://www.youtube.com/watch?v=${trailer.key}`}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContentInfo;
