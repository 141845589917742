import {
    Avatar, Badge, Card, Col, Divider, Dropdown, Menu, Space, Switch, Typography,
} from "antd";
// eslint-disable-next-line import/no-duplicates
import { Button } from "antd/es";
import {
    BellFilled,
    BellOutlined, DownOutlined,
    LeftOutlined,
    LoginOutlined,
    LogoutOutlined,
    MessageOutlined,
    MoonFilled, MoonOutlined,
    NotificationOutlined,
    SettingOutlined,
    SunFilled,
    UserDeleteOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./HeaderDropdown.css";
import { NoteOutlined } from "@mui/icons-material";
import { useDarkMode } from "../../../../utils/Darkmode";
import DropdownNested from "../../../reusableComponents/DropdownNested/DropdownNested";
import { isUserLoggedIn, logout } from "../../../../reducers/user";

const { Meta } = Card;

export function HeaderDropdown() {
    const isLoggedIn = useSelector(isUserLoggedIn);

    const { darkMode, setDarkMode, mode } = useDarkMode();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout()); // Hier dispatch je de logout actie
    };

    const navigate = useNavigate();
    return (
        <Col style={{
            display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px",
        }}
        >

            {/* <SunFilled */}
            {/*    onClick={() => setDarkMode(!darkMode)} */}
            {/*    style={{ */}
            {/*        color: darkMode ? "#afafaf" : "#948a00", fontSize: "150%", marginRight: "30px", transform: "scale(1.2)", */}
            {/*    }} */}
            {/* /> */}

            <DropdownNested
                button={(
                    <Button
                        style={{ transform: "scale(1.2)" }}
                        shape="circle"
                        icon={isLoggedIn ? (
                            <Avatar
                                size="default"
                                style={{
                                    backgroundColor: "gray",
                                    verticalAlign: "middle",
                                }}
                            >
                                J
                            </Avatar>
                        ) : <UserDeleteOutlined />}
                    />
                )}
                header={{
                    expression: isLoggedIn,
                    key: "header",
                    onClick: () => {
                        navigate("/account");
                    },
                    closeOnClick: false,
                    type: "divider",
                    label: <Card
                        style={{
                            width: 280,
                            border: "0",
                        }}
                    >
                        <Meta
                            avatar={(
                                <Avatar
                                    size="large"
                                    style={{
                                        backgroundColor: "gray",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    J
                                </Avatar>
                            )}
                            title="Jelmer0314"
                            description={(
                                <Space direction="vertical">
                                    <Typography.Text>Team Noppert admin!</Typography.Text>
                                    <Link className="link" to="profile">
                                        Profile
                                    </Link>
                                </Space>
                            )}
                        />
                        <Divider style={{
                            marginTop: "12px",
                            marginBottom: "0px",
                        }}
                        />
                           </Card>,
                }}
                menuConfig={{
                    main: {
                        title: "Main Menu",
                        items: [
                            {
                                key: "profile", label: "Profile", icon: <UserOutlined />, navigate: "/account", closeOnClick: true, expression: isLoggedIn,
                            },
                            {
                                key: "login", label: "Login", icon: <UserOutlined />, navigate: "/login", expression: !isLoggedIn,
                            },
                            {
                                key: "theme", label: "Theme", nextMenu: "theme", icon: <MoonOutlined />,
                            },
                            // {
                            //     key: "Pizza1", label: "Pizza1", icon: <SettingOutlined />, onClick: () => console.log("Pizza1!"), closeOnClick: true, divider: true,
                            // },
                            // {
                            //     key: "Pizzamenu",
                            //     label: "pizza menu",
                            //     nextMenu: "pizzaMenu",
                            // },
                            {
                                key: "logout", label: "Logout", icon: <LogoutOutlined />, expression: isLoggedIn, onClick: handleLogout,
                            },
                        ],
                    },
                    theme: {
                        title: "Theme",
                        selectedKey: [mode],
                        items: [
                            {
                                key: "back", label: "Back to Main", icon: <LeftOutlined />, nextMenu: "main",
                            },
                            {
                                key: "dark", label: "Dark", icon: <MoonFilled />, onClick: () => setDarkMode("dark"), closeOnClick: false,
                            },
                            {
                                key: "light", label: "Light", icon: <SunFilled />, onClick: () => setDarkMode("light"), closeOnClick: false,
                            },
                            {
                                key: "system", label: "System", icon: <SettingOutlined />, onClick: () => setDarkMode("system"), closeOnClick: false,
                            },
                        ],
                    },
                    // pizzaMenu: {
                    //     title: "Pizza",
                    //     selectedKey: ["privacy"],
                    //     items: [
                    //         {
                    //             key: "back", label: "Back to Main", icon: <LeftOutlined />, nextMenu: "main",
                    //         },
                    //         {
                    //             key: "theme", label: "pizza 1", icon: <SettingOutlined />, onClick: () => console.log("Theme Settings"), closeOnClick: false,
                    //         },
                    //         {
                    //             key: "privacy", label: "pizza 2", icon: <SettingOutlined />, onClick: () => console.log("Privacy Settings"), closeOnClick: false, expression: false,
                    //         },
                    //     ],
                    // },
                }}
            />
        </Col>
    );
}
