import { combineReducers } from "redux";
import cmsLayoutSlice from "./CMSLayout";
import media from "./Media";
import user from "./user";

export const rootReducer = combineReducers({
    user,
    media,
    cmsLayoutSlice,
});
