import React, {
    useContext, createContext, useMemo,
} from "react";

import { message } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const MessageContext = createContext(undefined);

// https://ant.design/components/message

// Voor per file use:
export function useMessage() {
    return useContext(MessageContext);
}

export function MessageProvider({ children }) {
    const [messageApi, contextHolder] = message.useMessage();

    const sendMessage = (options) => {
        if (messageApi) {
            const {
                type = "info",
                content,
                duration,
                onClose,
                key,
                style,
                className,
                showClose = false,
            } = options;

            const enhancedContent = showClose === true ? (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>{content}</span>
                    <CloseOutlined onClick={() => messageApi.destroy(key)} style={{ cursor: "pointer", color: "rgba(0,0,0,.45)" }} />
                </div>
            )
                : content;

            messageApi[type]({
                content: enhancedContent,
                duration,
                style,
                className,
                key,
                onClose,
            });
        }
    };

    const messageHandler = useMemo(() => ({
        open: (options) => sendMessage(options),
        success: (options) => sendMessage({ type: "success", ...options }),
        error: (options) => sendMessage({ type: "error", ...options }),
        warning: (options) => sendMessage({ type: "warning", ...options }),
        info: (options) => sendMessage({ type: "info", ...options }),
        loading: (options) => sendMessage({ type: "loading", ...options }),
        update: (options) => {
            messageApi.open({ key: options.key, content: options.newContent, duration: options.duration || 0 });
        },
        destroy: (key) => {
            messageApi.destroy(key);
        },
    }), [messageApi]);

    return (
        <MessageContext.Provider value={messageHandler}>
            {contextHolder}
            {children}
        </MessageContext.Provider>
    );
}

/*
    Example usage:
         const message = useMessageHandler();

    const handleAction = () => {
        const key = "updatable"; // Unique key for the message

        // Start a loading message with no timeout (indefinite)
        message.loading({
            content: "Action in progress...",
            duration: 0, // Indefinite duration
            key, // Unique key for updating and closing this message
        });

        // After some time, update the existing message
        setTimeout(() => {
            message.update({
                key, // Use the same key to update the message
                newContent: "Almost done...", // New content
                duration: 0, // Still indefinite
            });

            // After more time, switch to a success message and set a duration to auto close
            setTimeout(() => {
                message.warning({
                    content: "Action completed successfully",
                    // duration: 2, // Duration after which the message will auto-close
                    key, // Ensure to use the same key
                    style: {
                        marginTop: "20vh",
                    },
                });
            }, 1000);

            message.warning({
                content: "Action completed successfully",
                // duration: 2, // Duration after which the message will auto-close
                // key, // Ensure to use the same key
                style: {
                    marginTop: "20vh",
                    display: "flex",
                },
            });

        }, 2000);
    };

     ----------------------

     Or single use:
      <button onClick={() => message.warning({
                content: "Action completed successfully",
                duration: 0, // Duration after which the message will auto-close
                showClose: true,
                // key, // Ensure to use the same key
                style: {
                    marginTop: "20vh",
                },
            })}
            >
                Perform Action
            </button>
 */
