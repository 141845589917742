import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import {
    Button, Col, Empty, Layout, Result, Row, Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../../reusableComponents/Text/Header/Header";
import { useNotification } from "../../../../hooks/AntComponents/useNotification";
import { SPECIAL_LIJSTEN } from "../enum";
import { fetchAllMediaThunk } from "../../../../reducers/Media";
import { P } from "../../../reusableComponents/Text/Paragraph/P";
import { LijstData } from "../LijstWatch/LijstWatch";
import { TheMovieDB_MediaTypes } from "../../../../enum/TheMovieDB";

function SpecialLijst({ type, setMedialijstBreadcrumb }) {
    const dispatch = useDispatch();
    const notification = useNotification();
    const params = useParams();
    const { user } = useSelector((state) => state.user);
    const { media } = useSelector((state) => state.media);
    const [lijst, setLijst] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!media || !media.length) {
            dispatch(fetchAllMediaThunk({ _id: user._id }));
        }
    }, []);

    useEffect(() => {
        if (media && media.length) {
            window.scrollTo(0, 0);
            switch (type) {
            case SPECIAL_LIJSTEN.ALL:
                // setLijst(media);
                setLijst({ media });
                break;

            case SPECIAL_LIJSTEN.MOVIE:
                setLijst({ media: media.filter((item) => item.mediaType?.toLocaleLowerCase() === "movie" || item.mediaType?.toLocaleLowerCase() === "film" || item.themoviedb_type === TheMovieDB_MediaTypes.MOVIE) });
                break;

            case SPECIAL_LIJSTEN.SERIE:
                setLijst({ media: media.filter((item) => item.mediaType?.toLocaleLowerCase() === "serie" || item.mediaType?.toLocaleLowerCase() === "series" || item.type === "tv" || item.themoviedb_type === TheMovieDB_MediaTypes.TV) });
                break;

            case SPECIAL_LIJSTEN.ARCHIVE:
                setLijst({ media: media.filter((item) => item.mediaType === "archive") });
                break;

            default:
                setError({ code: 404, message: "Dit type heeft geen media gevonden" });
                setLijst("404");
                break;
            }
        }
    }, [type, setLijst, media]);

    if (!lijst) {
        if (error) {
            if (error.code === 404) {
                return (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, de medialijst die je probeert te bezoeken bestaat niet."
                        extra={(
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/media");
                                }}
                            >
                                Terug naar media overzicht
                            </Button>
                        )}
                    />
                );
            }

            return <P>{error.message}</P>;
        }

        // TODO: skeleton loader voor alle components er onder
        return (
            <Empty description={(
                <Typography.Text>
                    Er is nog geen data beschikbaar. We zijn bezig!
                </Typography.Text>
            )}
            />
        );
    }

    return (
        <Layout style={{ overflow: "visible" }}>
            <LijstData lijst={lijst} />
        </Layout>
    );
}

export default SpecialLijst;
