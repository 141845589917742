import React, { useEffect, useMemo, useState } from "react";
import {
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";

import {
    Link, Route, Routes, useLocation, useParams,
} from "react-router-dom";
import {
    Breadcrumb, Col, Layout, Row,
} from "antd";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "antd/es/layout/layout";
import { Home } from "../Home";
import { PageNotFound } from "../NotFound/PageNotFound";
import { CMSLayout } from "../../components/reusableComponents/cmsLayout/cmsLayout";
import { setMediaCustomPageHeader, toggleSideMenuMedia } from "../../reducers/CMSLayout";
import { useIsScreenMobileWindowSize, useScreenSize } from "../../hooks/useCheckWindowSize";
import { Header } from "../../components/reusableComponents/Text/Header/Header";
import { MediaHome } from "../../components/screenComponents/Media/Overzicht/Overzicht";
import { checkFetchError, fetchApi, fetchApiSendJson } from "../../snippets/functions/fetchFunctions";
import { LijstWatch } from "../../components/screenComponents/Media/LijstWatch/LijstWatch";
import { SPECIAL_LIJSTEN } from "../../components/screenComponents/Media/enum";
import {
    MediaDetailed,
} from "../../components/screenComponents/Media/DisplayMedia/DisplayOneMedia/FullPage/MediaDetailed/MediaDetailed";
import {
    EditMediaDetailed,
} from "../../components/screenComponents/Media/DisplayMedia/DisplayOneMedia/FullPage/EditMediaDetailed/EditMediaDetailed";
import { AddMedia } from "../../components/screenComponents/Media/AddMedia/AddMedia";
import AddLijst from "../../components/screenComponents/Media/AddLijst/AddLijst";
import SpecialLijst from "../../components/screenComponents/Media/SpecialLijsten/SpecialLijst";

export function Media() {
    const baseRoute = "/media";
    const menuItems = [
        {
            key: "1",
            path: baseRoute,
            icon: React.createElement(VideoCameraOutlined),
            label: "Media overzicht",
        },
        {
            key: "2",
            path: `${baseRoute}/lijst/add`,
            icon: React.createElement(VideoCameraOutlined),
            label: "Nieuwe medialijst",
        },
        {
            key: "3",
            path: `${baseRoute}/add`,
            icon: React.createElement(UploadOutlined),
            label: "Nieuwe media",
        },
        {
            key: "4",
            path: `${baseRoute}/speciallijst/all`,
            icon: React.createElement(VideoCameraOutlined),
            label: "Special lijst",
        },
        {
            key: "5",
            path: `${baseRoute}/defaultSources`,
            icon: React.createElement(VideoCameraOutlined),
            label: "defaultSources",
        },
    ];

    const [medialijstBreadcrumb, setMedialijstBreadcrumb] = useState("");
    const [mediaDetailedBreadcrumb, setMediaDetailedBreadcrumb] = useState("");

    const Mediaroutes = [
        { path: "/", breadcrumb: "Volledig home, index" },
        {
            path: "/media",
            breadcrumb: () => (
                <span>
                    <HomeOutlined />
                    {" "}
                    Overzicht
                </span>
            ),
            hideMenu: false,
        },
        { path: "/media/lijst", breadcrumb: "", isLastItemClickable: false },
        { path: "/media/lijst/add", breadcrumb: "Nieuwe lijst" },
        { path: "/media/speciallijst", breadcrumb: "Speciale lijsten", isLastItemClickable: false },
        { path: "/media/speciallijst/all", breadcrumb: "Alle media", hideMenu: true },
        { path: "/media/speciallijst/series", breadcrumb: "Alle series", hideMenu: true },
        { path: "/media/speciallijst/movies", breadcrumb: "Alle films", hideMenu: true },
        { path: "/media/lijst/watch", isLastItemClickable: false },
        {
            path: "/media/lijst/watch/:id", breadcrumb: medialijstBreadcrumb, isLastItemClickable: false, hideMenu: true, customSideMenu: <h1>Custom side menu</h1>,
        },
        {
            path: "/media/media/watch/:id", isLastItemClickable: true, hideMenu: true, breadcrumb: mediaDetailedBreadcrumb,
        },
        {
            path: "/media/media", isLastItemClickable: false,
        },
        {
            path: "/media/media/edit", isLastItemClickable: false,
        },
        {
            path: "/media/media/edit/:id", isLastItemClickable: true, hideMenu: false, breadcrumb: mediaDetailedBreadcrumb,
        },
    ];

    const drawerMenuVisible = useSelector((state) => state.cmsLayoutSlice.layoutStatePerPagina.media.isSideMenuVisible);
    const mediaCustomPageHeader = useSelector((state) => state.cmsLayoutSlice.layoutStatePerPagina.media.pageHeader);
    const dispatch = useDispatch();
    const toogleSideMenu = () => {
        dispatch(toggleSideMenuMedia());
    };
    const [customHeader, setCustomHeader] = useState(null);
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(true);

    // TODO: per component een custom header kunnen zetten of een default header of helemaal geen header
    const [showDefaultHeader, setShowDefaultHeader] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const { user } = useSelector((state) => state.user);

    const [bigBannerContent, setBigBannerContent] = useState(null);
    // TODO: dit verder maken. ik laat dit voor nu zitten, omdat bijna alle media nog geen uitgebereide images hebben.
    useEffect(() => {
        async function getOverviewImage() {
            try {
                const random = Math.random();
                // if (random < 0.3) {
                //     // last lijst watched instead of media
                // } else {
                const lastWatchedMedialijst = await fetchApiSendJson(`media/lijst/lastwatched/media/${user._id}`);
                const lastWatchedMedialijstData = await checkFetchError(lastWatchedMedialijst);

                if (lastWatchedMedialijstData?.backdrop_img && lastWatchedMedialijstData?.poster_img) {
                    setBigBannerContent({
                        backdrop_img: lastWatchedMedialijstData.backdrop_img,
                        poster_img: lastWatchedMedialijstData.poster_img,
                        title: "Als laatst bekeken",
                        contentTitle: lastWatchedMedialijstData.titel,
                        contentDescription: lastWatchedMedialijstData.notities,
                        onPlayClick: () => console.log("play"),
                        onInfoClick: () => console.log("info"),
                    });
                }

                // console.log("lastWatchedMedialijstData", lastWatchedMedialijstData);
                // }
            } catch (err) {

            }
        }

        getOverviewImage();
    }, [setBigBannerContent]);
    return (
        <CMSLayout
            showBreadcrumbAllSizes
            breadcrumbRoutes={Mediaroutes}
            breadcrumbSkipFirst
            breadcrumbSkipPathName="media"
            showFooter={false}
            menuItems={menuItems}
            isSideMenuVisible={isSideMenuVisible}
            setIsSideMenuVisible={setIsSideMenuVisible}
            showSideMenuWhenMobile
            // defaultPageHeader={<div><p>Header default</p></div>}
            breadcrumbsAboveHeaderContent
            hasBackgroundColor
            drawerMenuVisible={drawerMenuVisible}
            toogleSideMenu={toogleSideMenu}
            customPageHeader={customHeader}
            showHeader={showHeader}
            showDefaultHeader={showDefaultHeader}
            overviewImage={bigBannerContent?.backdrop_img}
            bigBannerTitle={bigBannerContent?.title}
            bigBannerDescription={bigBannerContent?.contentDescription}
            bigBannerContent={bigBannerContent?.content}
            bigBannerImg={bigBannerContent?.poster_img}
            bigBannerOnPlayClick={bigBannerContent?.onPlayClick}
            bigBannerOnInfoClick={bigBannerContent?.onInfoClick}
            content={(
                <Routes>
                    <Route path="/" strict exact element={<MediaHome />} />
                    <Route path="/lijst/add" element={<AddLijst />} />
                    <Route path="/lijst/watch/:id" element={<LijstWatch setMedialijstBreadcrumb={setMedialijstBreadcrumb} />} />

                    <Route path="/media/watch/:id" element={<MediaDetailed setMediaDetailedBreadcrumb={setMediaDetailedBreadcrumb} />} />
                    <Route path="/media/edit/:id" element={<EditMediaDetailed setMediaDetailedBreadcrumb={setMediaDetailedBreadcrumb} />} />

                    <Route path="/add" element={<AddMedia />} />

                    <Route path="/speciallijst/all" element={<SpecialLijst type={SPECIAL_LIJSTEN.ALL} />} />
                    <Route path="/speciallijst/films" element={<SpecialLijst type={SPECIAL_LIJSTEN.MOVIE} />} />
                    <Route path="/speciallijst/series" element={<SpecialLijst type={SPECIAL_LIJSTEN.SERIE} />} />
                    <Route path="/speciallijst/archive" element={<SpecialLijst type={SPECIAL_LIJSTEN.ARCHIVE} />} />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            )}
        />
    );
}

function LijstAdd({ setCustomHeader }) {
    const dispatch = useDispatch();
    // useEffect(() => {
    //     console.log("Zet custom header!");
    //     dispatch(setMediaCustomPageHeader(<p>hawdjdwajdw jwd ajjd wajd wa</p>));
    // }, []);
    // const headerContent = useMemo(() => <p>hawdjdwajdw jwd ajjd wajd wa</p>, []);
    // useEffect(() => {
    //     dispatch(setMediaCustomPageHeader(headerContent));
    // }, [dispatch, headerContent]);

    const headerContent = useMemo(() => <p>hawdjdwajdw jwd ajjd wajd wa</p>, []);
    useEffect(() => {
        console.log("customHeader kanjer bro", setCustomHeader);
        // setCustomHeader(<p>via de lokale manier</p>);
        // setCustomHeader(setMediaCustomPageHeader(headerContent));
    }, [setCustomHeader, headerContent]);
    return (
        <div>
            <p>Lijst add</p>
        </div>
    );
}

function LijstWatchTestNogEenId() {
    const params = useParams();

    const dispatch = useDispatch();
    const headerContent = useMemo(() => <p>header met params id,</p>, []);
    useEffect(() => {
        dispatch(setMediaCustomPageHeader(headerContent));
    }, [dispatch, headerContent]);

    return (
        <div>
            <p>Lijst watch test nog een id</p>
            <p>{params.id}</p>
            <p>{params.nogEenId}</p>
        </div>
    );
}
