import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../EditMediaDetailed/EditMediaDetailed.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "antd";
import { useSearchParamsUtil } from "../../../../../../../hooks/useSearchParams";
import { getSpecificMedia } from "../../../../../../../reducers/Media";
import { checkFetchError, fetchApiSendJson } from "../../../../../../../snippets/functions/fetchFunctions";
import {
    NotificationPlacementOptions as NotificationPlacement,
} from "../../../../../../../hooks/AntComponents/useNotification";

export function MediaDetailed({ setMediaDetailedBreadcrumb }) {
    const [media, setMedia] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    // const { mediaLijst } = useSelector((state) => state.media);
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // dispatch(getSpecificMedia(params.id)).then((res) => {
        //     console.log(res);
        //     setMedia(res.payload);
        // });

        // async function GetMedia() {
        //     const test = await dispatch(getSpecificMedia(params.id));
        //     console.log(test);
        //     console.log("jo maatj");
        // }
        // GetMedia();

        async function fetchMedia() {
            try {
                const mediaResponse = await fetchApiSendJson(`media/item/${params.id}/${user._id}`);
                const mediaData = await checkFetchError(mediaResponse);

                setMedia(mediaData);
                console.log(mediaData);
                setMediaDetailedBreadcrumb(mediaData.titel);
            } catch (err) {
                setMedia(null);
                setError(err);

                // if (err.code !== 404) {
                //     notification.error({
                //         key: "error",
                //         message: "Fout opgetreden",
                //         description: err.message,
                //         duration: 20,
                //         placement: NotificationPlacement.TOP,
                //     });
                // }
            }
        }

        if (user && user._id) {
            fetchMedia();
        }
    }, [params.id, user]);
    // }, [params.id, user, notification]);

    return (
        <>
            <h1>Media detailed </h1>
            <h1>{media.titel}</h1>
        </>
    );
}
