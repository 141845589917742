// eslint-disable-next-line camelcase
import { useSearchParams as useSearchParams_react_router_dom } from "react-router-dom";

export function useSearchParamsUtil() {
    const [searchParams, setSearchParams] = useSearchParams_react_router_dom();

    return ({
        updateFilters: (filters) => {
            for (const key in filters) {
                if (filters[key] === null || filters[key] === undefined) {
                    searchParams.delete(key);
                } else {
                    searchParams.set(key, filters[key]);
                }
            }
            setSearchParams(searchParams, { replace: true });
        },
        addFilter: (filterName, value) => {
            searchParams.set(filterName, value);
            setSearchParams(searchParams, { replace: true });
        },
        removeFilter: (filterName) => {
            searchParams.delete(filterName);
            setSearchParams(searchParams, { replace: true });
        },
        getFilterValue: (filterName) => searchParams.get(filterName),
        removeAllFilters: () => {
            for (const key of searchParams.keys()) {
                searchParams.delete(key);
            }
            setSearchParams(searchParams, { replace: true });
        },
    });
}
