import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    layoutStatePerPagina: {
        media: {
            isSideMenuVisible: false,
            pageHeader: "",
            useDefaultPageHeader: true,
        },
        formules: {
            isSideMenuVisible: false,
        },
    },
};

const cmsLayoutSlice = createSlice({
    name: "cmsLayout",
    initialState,
    reducers: {
        toggleSideMenuMedia: (state) => {
            state.layoutStatePerPagina.media.isSideMenuVisible = !state.layoutStatePerPagina.media.isSideMenuVisible;
        },
        openSideMenuMedia: (state) => {
            state.layoutStatePerPagina.media.isSideMenuVisible = true;
        },
        setSideMenuMedia: (state, action) => {
            state.layoutStatePerPagina.media.isSideMenuVisible = action.payload;
        },
        setMediaCustomPageHeader: (state, action) => {
            state.layoutStatePerPagina.media.pageHeader = action.payload;
        },
    },
});

export const {
    setMediaCustomPageHeader, toggleSideMenuMedia, openSideMenuMedia, setSideMenuMedia,
} = cmsLayoutSlice.actions;

export default cmsLayoutSlice.reducer;
