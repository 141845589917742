import React, {
    useRef, useState, useEffect, useCallback,
} from "react";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

require("./DropdownNested.css");

function DropdownNested({
    menuConfig, header, button, width,
}) {
    const navigate = useNavigate();

    const [openMainDrop, setOpenMainDrop] = useState(false);
    const [openDetailDrop, setOpenDetailDrop] = useState(false);
    const mainDropdownRef = useRef(null);

    const [openMenuKey, setOpenMenuKey] = useState("main");

    const closeMainDropdown = useCallback(() => setOpenMainDrop(false), []);

    const setDetailDropdown = useCallback((status) => setOpenDetailDrop(status), []);
    const closeDetailDropdown = useCallback(() => setOpenDetailDrop(false), []);

    const closeAllDropdowns = useCallback(() => {
        closeMainDropdown();
        closeDetailDropdown();
    }, [closeMainDropdown, closeDetailDropdown]);

    const handleMenuClick = useCallback((e) => {
        const { key } = e;

        if (key === "header" || e?.target?.className === "link") {
            header.onClick();
            if (header.closeOnClick) closeMainDropdown();
            return;
        }

        const item = menuConfig[openMenuKey].items.find((i) => i.key === key);

        if (item) {
            if (item.nextMenu) {
                setOpenMenuKey(item.nextMenu);
                closeMainDropdown();
                setDetailDropdown(true);
            }
            if (item.navigate) {
                navigate(item.navigate);
                closeAllDropdowns();
            }
            if (item.onClick) {
                item.onClick();
                if (item.closeOnClick) {
                    closeAllDropdowns();
                    setOpenMenuKey("main");
                }
            }
        }
    }, [menuConfig, openMenuKey, header, closeMainDropdown, setDetailDropdown, closeAllDropdowns]);

    const handleClickOutside = useCallback((event) => {
        // console.log(event.target.className);

        if (event.target.className === "link") handleMenuClick(event);

        if (event.target.className instanceof SVGAnimatedString) return;

        if (event.target.className === "ant-dropdown-menu-title-content" || event.target.className === "ant-card-meta-description") return;

        if (mainDropdownRef.current && !mainDropdownRef.current.contains(event.target)) {
            closeAllDropdowns();
            setOpenMenuKey("main");
        }
    }, [mainDropdownRef, closeAllDropdowns]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    const menuItems = useCallback(() => {
        let items = [...menuConfig[openMenuKey].items];
        if (openMenuKey === "main" && header && (header.expression === undefined || header.expression)) items.unshift(header);

        items = items.filter((item) => item.expression === undefined || item.expression);
        return {
            items: items.map((item) => ({
                key: item.key,
                label: item.label,
                icon: item.icon,
            })),
            selectedKeys: menuConfig[openMenuKey].selectedKey || [],
        };
    }, [menuConfig, openMenuKey, header]);

    return (
        <>
            <div style={{ width }} ref={mainDropdownRef}>
                {!openDetailDrop && (
                    <Dropdown
                        menu={{
                            items: menuItems().items,
                            onClick: handleMenuClick,
                        }}
                        onClick={() => {
                            setOpenMainDrop(!openMainDrop);
                        }}
                        open={openMainDrop}
                    >
                        {button}
                    </Dropdown>
                )}
            </div>

            {openDetailDrop && (
                <Dropdown
                    menu={{
                        items: menuItems().items,
                        onClick: handleMenuClick,
                        selectedKeys: menuItems().selectedKeys,
                    }}
                    open={openDetailDrop}
                >
                    {button}
                </Dropdown>
            )}
        </>
    );
}

export default DropdownNested;

// TODO: Divider kunnen aangeven
