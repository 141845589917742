import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchApiSendJson, checkFetchError } from "../snippets/functions/fetchFunctions";
import { convertedVapidKey, postSubscriptionWhenUserLogin } from "../index";
import { UpdateSubscription } from "../functions/PWA";

const initialState = {
    user: null,
    error: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed' | 'tryingToken' | 'loggedOut'
    token: localStorage.getItem("token") || null,
    authChecked: false,
};

export const validateTokenAndFetchUserInfo = createAsyncThunk(
    "user/validateToken",
    async (_, { getState, rejectWithValue }) => {
        const { token } = getState().user;

        if (!token) {
            return rejectWithValue("No token found");
        }

        try {
            const response = await fetchApiSendJson("user/validate-token", "GET");
            const data = await checkFetchError(response);
            if (response.ok) {
                return data;
            }
            localStorage.removeItem("token");
            return rejectWithValue("Token validation failed");
        } catch (error) {
            console.error(error, "error!!");
            return rejectWithValue(error.message);
        }
    },
);

export const loginUser = createAsyncThunk(
    "user/login",
    async ({ fastCode, username, password }, { rejectWithValue }) => {
        try {
            let response;
            if (fastCode) {
                response = await fetchApiSendJson("user/login", "POST", { username, fastCode });
            } else {
                response = await fetchApiSendJson("user/login", "POST", { usernameLogin: username, fastCode: password });
            }

            if (response.status === 401) {
                return rejectWithValue("Incorrecte gegevens. Probeer het opnieuw!");
            }

            const userData = await checkFetchError(response);

            if (userData.user && userData.token) {
                localStorage.setItem("token", userData.token);
                localStorage.setItem("user", JSON.stringify(userData.user));

                UpdateSubscription(userData.user._id);

                return { user: userData.user, token: userData.token };
            }

            return rejectWithValue("Login failed");
        } catch (err) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            return rejectWithValue(err.message);
        }
    },
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            state.user = null;
            state.token = null;
            state.status = "loggedOut";
            state.authChecked = false; // Reset authChecked bij logout
            // message.error("Logout successful");
        },
        authCheckComplete: (state) => {
            state.authChecked = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateTokenAndFetchUserInfo.pending, (state) => {
                state.status = "tryingToken";
                state.authChecked = false;
            })
            .addCase(validateTokenAndFetchUserInfo.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.status = "succeeded";
                state.authChecked = true;
                state.error = null;
                // message.success("Token is correct");

                // console.log("Valdiatie tokens in de user slice", action.payload.user);
            })
            .addCase(validateTokenAndFetchUserInfo.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
                state.authChecked = true;
            })
            .addCase(loginUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
                state.user = null;
            });
    },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;

export const isUserLoggedIn = (state) => Boolean(state.user.user);
