import React from "react";
import { Typography } from "antd";

const { Paragraph: Par } = Typography;

export function P({
    children,
    className,
    defaultDarkMode = true,
    ...rest
}) {
    let defaultClass = "text-base leading-7";
    if (defaultDarkMode) defaultClass += " dark:text-white"; // De extra whitespace is nodig anders; staat hij direct tegen anderen classes aan

    return (
        <Par className={`${defaultClass} ${className}`} {...rest}>
            {children}
        </Par>
    );
}
