import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { Navigate } from "react-router-dom";
import { useMessage } from "../hooks/AntComponents/useMessage";

export function PrivateRoute({ children, authenticated, loading }) {
    const { status, authChecked } = useSelector((state) => state.user);

    if (loading || status === "tryingToken" || status === "loading") {
        return (
            <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", height: "100vh",
            }}
            >
                <Spin tip="Loading..." size="large" />
            </div>
        );
    }

    if (!authChecked && status !== "loggedOut") {
        return (
            <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", height: "100vh",
            }}
            >
                <Spin tip="Loading..." size="large" />
            </div>
        );
    }

    if (status === "loggedOut") {
        return <Navigate to="/login" replace />;
    }

    if (!authenticated) {
        return <Navigate to="/nietgevonden" replace />;
    }

    return children;
}
