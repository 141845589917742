import React from "react";
import { Divider as AntdDivder } from "antd";

// https://ant.design/components/divider#

export const DividerType = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
};

export const DividerTextOrientation = {
    LEFT: "left",
    RIGHT: "right",
    CENTER: "center",
};

export function Divider({
    children,
    className,
    style,
    dashed = false,
    title,
    textOrientation = DividerTextOrientation.CENTER,
    orientationMargin,
    type = DividerType.HORIZONTAL,
    plainText = false,
    size = "",
    color = "",
}) {
    const defaultStyle = {
        borderColor: color,
        borderWidth: size,
    };

    return (
        <AntdDivder
            className={className}
            style={style}
            // style={{ ...defaultStyle, ...style }}
            dashed={dashed}
            plain={plainText}
            title={title}
            orientation={textOrientation}
            orientationMargin={orientationMargin}
            type={type}
        >
            {children}
        </AntdDivder>
    );
}

/*
    Example use:

    <Divider size={2} color="red" />
    <Divider style={{ borderWidth: 5 }} />
    <Divider className="border-8" />

    <Divider size={2} color="red" />
    <Divider className="border-8" />
    <Divider style={{ borderWidth: 5 }} />
    <Divider>Of</Divider>

 */
