import "./AddMedia.css";
import {
    Button, Card, Col, Divider, Form, Input, InputNumber, Pagination, Row, Select, Space, Tabs, FloatButton,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Header } from "../../../reusableComponents/Text/Header/Header";
import { DisplayMediaCard } from "../DisplayMedia/DisplayMediaList/Card/Card";
import { MediaDetailsModal } from "./MediaDetailsModal/MediaDetailsModal";
import { GetDetails, SearchMedia } from "../../../../functions/TheMovieDB/GetInfo";

export function AddMedia() {
    const items = [
        {
            label: "Titel",
            key: "FindMedia",
            children: <SearchWithTitel />,
        },
        {
            label: "Collectie",
            key: "collectie",
            children: <SearchWithTitel />,
            disabled: true,
        },
        {
            label: "Custom",
            key: "AddMedia",
            // children: <AddCustomMedia />,
            disabled: true,
        },
        // netwerken
        // cast
        // crew
        // personen
        // trending
        // airing today
        // populair
        {
            label: "Netwerk",
            key: "netwerk",
            disabled: true,
        },
        {
            label: "Cast",
            key: "cast",
            disabled: true,
        },
        {
            label: "Crew",
            key: "crew",
            disabled: true,
        },
        {
            label: "Personen",
            key: "personen",
            disabled: true,
        },
        {
            label: "Trending",
            key: "trending",
            disabled: true,
        },
        {
            label: "Airing today",
            key: "airing today",
            disabled: true,
        },
        {
            label: "Populair",
            key: "populair",
            disabled: true,
        },
        // {
        //     label: "Momenteel in de bioscoop",
        //     key: "bioscoop",
        //     children: <FindMedia />,
        // },
        // {
        //     label: "Upcoming",
        //     key: "upcoming",
        //     children: <FindMedia />,
        // },
        // {
        //     label: "Acteur",
        //     key: "acteur",
        //     children: <FindMedia />,
        // },
        // {
        //     label: "Director",
        //     key: "director",
        //     children: <FindMedia />,
        // },
        // {
        //     label: "Tags",
        //     key: "tags",
        //     children: <FindMedia />,
        // },
        // {
        //     label: "Popular",
        //     key: "popular",
        //     children: <FindMedia />,
        // },

    ];
    return (
        <div className="mt-8">
            <Tabs
                type="card"
                items={items}
            />
        </div>
    );
}
export function SearchWithTitel({ returnMovieDbId = false, setMovieDbId }) {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [theMovieDBResult, setTheMovieDBResult] = useState([]);
    const [movieDBMedia, setMovieDBMedia] = useState([]);
    const [cache, setCache] = useState({});

    const [prevSearch, setPrevSearch] = useState({});

    const [mediaDetails, setMediaDetails] = useState({});
    const [mediaDetailsId, setMediaDetailsId] = useState({});
    const [mediaDetailsVisible, setMediaDetailsVisible] = useState(false);

    const onFinish = async (values) => {
        const { title, type, year } = values;

        const key = `${title}-${type}-${year}-${page}`;
        if (cache[key]) {
            setMovieDBMedia(cache[key].results);
        } else {
            // Er is hier nog wel iets van een bugje in, vgm als; je op pagina 5 zit en dan iets opzoekt met maar max 3 paginas bijv, dan zijn er 0 results
            if (JSON.stringify(values) !== JSON.stringify(prevSearch)) {
                setPage(1);
                setPrevSearch(values);
            }

            const fetchMedia = await SearchMedia(type, title, year, page);

            setTheMovieDBResult(fetchMedia);
            setMovieDBMedia(fetchMedia.results);
            setCache((prevCache) => ({ ...prevCache, [key]: fetchMedia }));
        }
    };

    const fetchDetails = async (item) => {
        const { id } = item;
        let mediaType = form.getFieldValue("type") || "";

        if (mediaType === "multi") {
            if (item.title) {
                mediaType = "movie";
            } else if (item.name) {
                mediaType = "tv";
            } else {
                console.error("Unknown media type");
                return;
            }
        }

        setMediaDetailsId(id);
        setMediaDetails(item);
        // setMediaDetails(await GetDetails(mediaType, id));
        setMediaDetailsVisible(true);
    };

    useEffect(() => {
        if (form.isFieldsTouched()) {
            onFinish(form.getFieldsValue());
        }
    }, [page]);

    const onPageChange = (page) => {
        setPage(page);
    };

    function GetCards() {
        if (Array.isArray(movieDBMedia)) {
            return (
                <Row gutter={[30, 30]}>
                    {movieDBMedia.map((item) => (
                        <Col key={item.id} xs={12} sm={12} md={12} lg={8} xl={5} xxl={4}>
                            <Card
                                key={item.id}
                                className="card hoverable ease-in-out duration-700 transform hover:scale-110"
                                hoverable
                                cover={(
                                    //                             {/*<img alt="Alt text" src={`https://image.tmdb.org/t/p/original${item.poster_path}`} style={cardImageStyle} onClick={() => { this.setModalVisibility(item); }} /> */}
                                    <img alt="Alt text" src={`https://image.tmdb.org/t/p/original${item.poster_path}`} />
                                )}
                                onClick={() => { fetchDetails(item); }}
                            >
                                <Card.Meta
                                    onClick={() => { this.setModalVisibility(item); }}
                                    title={item.original_title ? item.original_title : item.original_name}
                                    description={(
                                        <div>
                                            {" "}
                                            <ul className="uk-padding-remove-left">
                                                <li>
                                                    {" "}
                                                    {item.release_date ? item.release_date : item.first_air_date ? item.first_air_date : "No date"}
                                                    {" "}
                                                </li>
                                                <li>
                                                    {" "}
                                                    {item.vote_average ? item.vote_average : "No rating"}
                                                    {" "}
                                                </li>
                                            </ul>
                                            {" "}

                                        </div>
                                    )}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
            );
        }
        return null;
    }

    return (
        <div className="">
            <div className="titleDiv">
                <Header className="">Zoek op The MovieDB</Header>
            </div>

            <Form
                form={form}
                name="advanced_search"
                id="search"
                // style={formStyle}
                initialValues={{
                    type: "multi",
                }}
                onFinish={onFinish}
                size="large"
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="title"
                            label="Titel"
                            rules={[{ required: true, message: "Vul in titel in" }]}
                        >
                            <Input
                                placeholder="Titel"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[{ required: true, message: "Selecteer een type" }]}
                        >
                            <Select
                                placeholder="Type"
                            >
                                <Select.Option value="movie">Film</Select.Option>
                                <Select.Option value="tv">Serie</Select.Option>
                                <Select.Option value="multi">Beide</Select.Option>

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="year"
                            label="Jaar"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber
                                min={1900}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div
                    style={{
                        textAlign: "right",
                    }}
                >
                    <Space size="small">
                        <Button type="primary" htmlType="submit">
                            Zoek
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            Annuleer
                        </Button>
                    </Space>
                </div>

                {/* Deze is bedoeld als; advanced search opties */}
                {/* <a */}
                {/*    style={{ */}
                {/*        fontSize: 12, */}
                {/*    }} */}
                {/*    onClick={() => { */}
                {/*        setExpand(!expand); */}
                {/*    }} */}
                {/* > */}
                {/*    <DownOutlined rotate={expand ? 180 : 0}/> */}
                {/*    {" "} */}
                {/*    Collapse */}
                {/* </a> */}
            </Form>

            {movieDBMedia && movieDBMedia.length > 0 && (
                <Pagination
                    align="center"
                    current={page}
                    // onChange={(page) => setPage(page)}
                    onChange={onPageChange}
                    total={theMovieDBResult?.total_results || 0}
                    showSizeChanger={false}
                    showQuickJumper
                    pageSize={20}
                    showTotal={(total) => `Total ${total} items`}
                />
            )}

            <Divider />

            <GetCards />

            <Pagination
                align="center"
                current={page}
                // onChange={(page) => setPage(page)}
                onChange={onPageChange}
                total={theMovieDBResult?.total_results || 0}
                showSizeChanger={false}
                showQuickJumper
                pageSize={20}
                showTotal={(total) => `Total ${total} items`}
            />

            <MediaDetailsModal
                visible={mediaDetailsVisible}
                id={mediaDetailsId}
                media={mediaDetails}
                mediaType={form.getFieldValue("type")}
                onClose={() => setMediaDetailsVisible(false)}
                returnMovieDbId={returnMovieDbId}
                setMovieDbId={setMovieDbId}
            />

            <FloatButton.BackTop />
        </div>
    );
}
