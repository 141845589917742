import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    Button, Col, Drawer, Dropdown, Menu, Row, Layout, Switch,
} from "antd";
import "./header.css";
import { MoonFilled, SunFilled } from "@ant-design/icons";
import { HeaderDrawer } from "./HeaderDrawer/HeaderDrawer";
import { HeaderDropdown } from "./HeaderDropdown/HeaderDropdown";
import { useDarkMode } from "../../../utils/Darkmode";

export function HeaderComponent() {
    const { darkMode } = useDarkMode();

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Als je naar beneden scrollt en verder dan 50 pixels van de top bent
            if (currentScrollY > 150) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Voeg de event listener toe
        window.addEventListener("scroll", handleScroll);

        // Cleanup de event listener
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Row
            id="header"
            justify="space-between"
            align="middle"
            className={`header-container ${isScrolled ? "scrolled" : ""} ${darkMode ? "dark-mode" : ""} sticky top-0 z-50 bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-400 dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-700 dark:to-gray-800`}
        >
            <HeaderDrawer />

            <Col>
                <Link to="/">
                    {darkMode && (
                        <img src={require("../../../theme/images/teamNoppertLogoTransDarkmode.png")} alt="logo" width="250" />
                    )}
                    {!darkMode && isScrolled && (
                        <img src={require("../../../theme/images/teamNoppertLogoLightMode.png")} alt="logo" width="40" />
                    )}
                    {!darkMode && !isScrolled && (
                        <img src={require("../../../theme/images/teamNoppertLogoTrans.png")} alt="logo" width="250" />
                    )}
                </Link>
            </Col>

            <HeaderDropdown />
        </Row>
    );
}

/*
OUD;;

<Row
            id="header"
            justify="space-between"
            align="middle"
            className={`bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-400 p-4 ${visible ? "sticky top-0" : "hidden"} z-50 marginsHeader dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-700 dark:to-gray-800`}
        >

            <HeaderDrawer />

            <Col>
                <Link to="/">
                    { darkMode ? (
                        <img src={require("../../../theme/images/teamNoppertLogoTransDarkmode.png")} alt="logo" width="250" />
                    ) : (
                        <img src={require("../../../theme/images/teamNoppertLogoTrans.png")} alt="logo" width="250" />
                    )}
                </Link>
            </Col>

            <HeaderDropdown />

        </Row>
 */

//    className="bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-400 p-4 sticky top-0 z-50 marginsHeader dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-700 dark:to-gray-800"
