import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./EditMediaDetailed.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Col, Collapse, Form, Input, Modal, Popconfirm, Popover, Row, Statistic, Typography,
} from "antd";
import { PlayCircleRounded } from "@mui/icons-material";
import { StarFilled } from "@ant-design/icons";
import moment from "moment/moment";
import { useSearchParamsUtil } from "../../../../../../../hooks/useSearchParams";
import { Header } from "../../../../../../reusableComponents/Text/Header/Header";
import { checkFetchError, fetchApiSendJson } from "../../../../../../../snippets/functions/fetchFunctions";
import { ChangeMediaRatingModal } from "../../../../MediaRatingModal/MediaRatingModel";
import { fetchMediaEnums } from "../../../../../../../reducers/Media";
import MediaInfoPanel from "../../../../AddMedia/MediaDetailsModal/FormComponents/MediaInfoPanel";
import MediaDetailsPanel from "../../../../AddMedia/MediaDetailsModal/FormComponents/MediaDetailsPanel";
import AddToTablePanel from "../../../../AddMedia/MediaDetailsModal/FormComponents/AddToTablePanel";
import ImagesPanel from "../../../../AddMedia/MediaDetailsModal/FormComponents/ImagesPanel";
import { GetImages } from "../../../../../../../functions/TheMovieDB/GetInfo";
import { SearchWithTitel } from "../../../../AddMedia/AddMedia";
import GetMovieDbTypeOnMovieDbInfo from "../../../../WhatTypeOfMedia";
import { useNotification } from "../../../../../../../hooks/AntComponents/useNotification";
import MediaSourcesPanel from "./FormComponents/MediaSourcesExperimental";
import MediaSourcesPanelBeta from "./FormComponents/MediaSourcesExperimental";
import MediaSourcesPanelEasy from "./FormComponents/MediaSourcesEasy";

const { Text, Title } = Typography;

export function EditMediaDetailed({ setMediaDetailedBreadcrumb }) {
    const dispatch = useDispatch();
    const params = useParams();
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [media, setMedia] = useState([]);
    const { user } = useSelector((state) => state.user);
    const [ratingModal, setRatingModal] = useState(false);
    const [movieDbImages, setMovieDbImages] = useState(null);
    const notification = useNotification();
    const navigate = useNavigate();

    const {
        mediaEnums, status, error,
    } = useSelector((state) => state.media);

    useEffect(() => {
        if (mediaEnums === null) {
            dispatch(fetchMediaEnums());
        }
    }, []);

    const setImages = async (id, selectedMedia) => {
        try {
            if (!id || !selectedMedia) {
                return notification.error({
                    message: "No MovieDB id found",
                    description: "No MovieDB id found, please add one",
                });
            }

            const images = await GetImages(selectedMedia?.themoviedb_type?.toLowerCase() || GetMovieDbTypeOnMovieDbInfo(selectedMedia), id);
            setMovieDbImages(images.images);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        setLoading(true);

        async function getMediaDbData() {
            let type;
            try {
                const media = await fetchApiSendJson(`media/item/${params.id}/${user._id}`, "GET");
                const mediaReponse = await checkFetchError(media);
                if (mediaReponse) {
                    setMedia(mediaReponse);
                    setMediaDetailedBreadcrumb(mediaReponse.titel);
                } else {
                    throw new Error("Failed to load media data");
                }

                if (mediaReponse.themoviedb_id) {
                    await setImages(mediaReponse.themoviedb_id, mediaReponse);
                }
            } catch (e) {
                notification.error({
                    message: "Fout met het ophalen van de gekozen media",
                    description: e.message,
                });

                if (e.code === 404) {
                    navigate(-1);
                }
            } finally {
                setLoading(false);
            }
        }

        getMediaDbData();
    }, []);

    const setMovieDbId = async (id, selectedMedia) => {
        setMedia({ ...media, themoviedb_id: id, themoviedb_type: GetMovieDbTypeOnMovieDbInfo(selectedMedia) });
        form.setFieldsValue({ themoviedb_id: id });
        form.setFieldsValue({ themoviedb_type: GetMovieDbTypeOnMovieDbInfo(selectedMedia) });

        await setImages(id, selectedMedia);
    };

    useEffect(() => {
        if (media) {
            form.setFieldsValue({
                titel: media.titel || media.name,
                archief: media.archief,
                imageUrl: media.imageUrl,
                mediaData: media.mediaData,
                kijkType: media.kijkType,
                laatsteKeerGekeken: media.laatsteKeerGekeken ? moment(media.laatsteKeerGekeken) : null,
                mening: media.mening,
                prioriteit: media.prioriteit,
                notities: media.notities,
                genre: media.genre,
                themoviedb_id: media.themoviedb_id,
                kijkStatus: media.kijkStatus,
                backdrop_img: media.backdrop_img,
                poster_img: media.poster_img,
                themoviedb_type: media.themoviedb_type,
            });
        }
    }, [media, form]);

    if (loading || !media || !mediaEnums) {
        return <div>Loading...</div>;
    }
    const fetchSearchResults = async () => {

    };

    const updateMedia = async (values) => {
        setMedia(values);
    };

    const handleSubmit = async (values) => {
        try {
            setConfirmLoading(true);
            const response = await fetchApiSendJson(`media/${params.id}/${user._id}`, "PUT", { mediaInfo: values });
            const data = await checkFetchError(response);
            if (data) {
                notification.success({
                    message: "Media updated",
                    description: "Media updated successfully",
                });
                // navigate(`/media/${params.id}`);
            }
        } catch (e) {
            notification.error({
                message: "Failed to update media",
                description: e.message,
            });
        } finally {
            setConfirmLoading(false);
        }
    };

    const panels = () => [
        {
            key: "1",
            label: <div className="panel-header"><Title level={2}>Standard media info</Title></div>,
            children: (
                <MediaInfoPanel form={form} enums={mediaEnums} />
            ),
        },
        {
            key: "2",
            label: <div className="panel-header"><Title level={2}>Media details</Title></div>,
            children: (
                <MediaDetailsPanel form={form} enums={mediaEnums} />
            ),
        },
        {
            key: "55555",
            label: <div className="panel-header">
                <Title level={2}>
                    Media data (sources, kijk progressie)
                </Title>
            </div>,
            children: (
                <div className="panel-content">
                    <Form.Item
                        label="Titel"
                        name="titel"
                        className="form-item"
                    >
                        <Input />
                    </Form.Item>
                </div>
            ),
        },
        {
            key: "4",
            label: <div className="panel-header"><Title level={2}>Afbeeldingen</Title></div>,
            children: (
                <ImagesPanel form={form} images={movieDbImages} alreadySelectedPoster={media?.poster_img} alreadySelectedBackdrop={media?.backdrop_img} />
            ),
        },
        {
            key: "5",
            label: <div className="panel-header"><Title level={2}>MovieDB id</Title></div>,
            children: (
                <Form.Item
                    label="MovieDB id"
                    name="themoviedb_id"
                    className="form-item"
                >
                    <Input />
                </Form.Item>
            ),
        },
    ];

    return (
        <div>
            {/* <MediaSourcesPanelBeta form={form} originalSources={media?.mediaSource} mediaId={media._id} /> */}
            <MediaSourcesPanelEasy form={form} originalSources={media?.mediaSource} mediaId={media._id} />
            <Form
            // bg-black
                className="min-h-screen"
                form={form}
                layout="vertical"
                name="edit_media"
                scrollToFirstError
                onFinish={handleSubmit}
                onFinishFailed={(errorInfo) => {
                    console.log("Failed:", errorInfo);
                }}
                size="medium"
                initialValues={{

                }}
            >
                {/*    */}
                <Row gutter={24} justify="space-between" className="pr-3" align="middle">

                    <Col>
                        {/* Deze is niet margin top in de middel */}
                        <Header className="">
                            {media.titel}
                        </Header>
                    </Col>

                    <Col
                        className="ml-auto"
                        onClick={() => {
                            setRatingModal(true);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Statistic
                            className="uppercase"
                            title="Mijn Rating"
                            value={media.rating}
                            suffix="/ 5"
                            prefix={<StarFilled style={{ color: "#f5c518" }} />}
                        />
                    </Col>
                </Row>

                <Collapse
                    defaultActiveKey={["1", "2", "4"]}
                    ghost
                    items={panels()}
                />

                <ChangeMediaRatingModal
                    rating={media.rating}
                    mediaId={media._id}
                    notities={media.notities}
                    onClose={() => setRatingModal(false)}
                    open={ratingModal}
                    updateMedia={updateMedia}
                />

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>

                <Form.Item>

                    <Popconfirm
                        title="Verwijder media"
                        description="Weet je het zeker?"
                        onConfirm={async () => {
                            try {
                                await fetchApiSendJson(`media/${params.id}/${user._id}`, "DELETE");
                                notification.success({
                                    message: "Media deleted",
                                    description: "Media deleted successfully",
                                });

                                navigate(-1);
                            } catch (e) {
                                notification.error({
                                    message: "Failed to delete media",
                                    description: e.message,
                                });
                            }
                        }}
                        okText="Ja"
                        cancelText="Nee"
                    >
                        <Button danger>Verwijder</Button>
                    </Popconfirm>
                </Form.Item>
            </Form>

            <SearchWithTitel returnMovieDbId setMovieDbId={setMovieDbId} />

        </div>
    );
}
