import {
    Button, Form, Modal, Rate, Space, Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNotification } from "../../../../hooks/AntComponents/useNotification";
import { checkFetchError, fetchApiSendJson } from "../../../../snippets/functions/fetchFunctions";

const { TextArea } = Input;
export function ChangeMediaRatingModal({
    rating, mediaId, onClose, open, updateMedia, notities,
}) {
    const [form] = Form.useForm();
    const [formChanged, setFormChanged] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { user } = useSelector((state) => state.user);
    const notification = useNotification();

    const resetFields = () => {
        form.resetFields();
        setFormChanged(false);
    };

    useEffect(() => {
        if (!open) {
            setFormChanged(false);
            resetFields();
        }
    }, [open, form, mediaId, formChanged]);

    const onValuesChange = () => {
        setFormChanged(true);
    };

    const handleSubmit = async (values) => {
        if (values.rating === rating && values.notities === notities) {
            onClose();
            return notification.info({ message: "Geen wijzigingen gevonden" });
        }

        setConfirmLoading(true);

        try {
            const updateMediaRequest = await fetchApiSendJson(`media/${mediaId}/${user._id}`, "PUT", { mediaInfo: { rating: values.rating, notities: values.notities } });
            const updatedMediaResponse = await checkFetchError(updateMediaRequest);

            updateMedia(updatedMediaResponse);
            setFormChanged(false);

            setTimeout(() => {
                setConfirmLoading(false);
                onClose();

                notification.success({ message: "Rating bijgewerkt" });
            }, [300]);
        } catch (e) {
            setConfirmLoading(false);
            notification.error({ message: "Er is iets fout gegaan", description: e.message });
        }
    };

    const handleCancel = () => {
        if (formChanged) {
            Modal.confirm({
                title: "Weet je zeker dat je de rating niet wil aanpassen?",
                onOk: () => onClose(),
                centered: true,
            });
        } else {
            onClose();
        }
    };

    // TODO: submit met ctr + enter
    return (
        <Modal
            open={open}
            centered
            okButtonProps={{
                htmlType: "submit",
            }}
            onCancel={handleCancel}
            title="Media mening"
            okText="Save mening"
            cancelText="Annuleer"
            confirmLoading={confirmLoading}
            destroyOnClose
            footer={(
                <Space>
                    <Button onClick={resetFields} type="link">Reset</Button>
                    <Button onClick={onClose}>Annuleer</Button>
                    <Button type="primary" htmlType="submit" loading={confirmLoading}>Save</Button>
                </Space>
            )}
            modalRender={(dom) => (
                <Form
                    form={form}
                    name="edit_media_rating"
                    onValuesChange={onValuesChange}
                    initialValues={{
                        rating,
                        notities,
                    }}
                    onFinish={handleSubmit}
                    size="medium"

                >
                    {dom}
                </Form>
            )}
        >
            <Form.Item label="Rating" name="rating" rules={[{ required: false, message: "Vul een rating in" }]}>
                <Rate allowHalf />
            </Form.Item>

            <Form.Item label="Notities" name="notities" rules={[{ required: false, message: "Vul een notitie in" }]}>
                <TextArea />
            </Form.Item>

            <Button onClick={resetFields} type="link">Annuleer</Button>
        </Modal>
    );
}
