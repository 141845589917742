import React, { useState } from "react";
import { HomeOutlined, UploadOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "antd/es/layout/layout";
import { Col, Layout, Row } from "antd";
import { toggleSideMenuMedia } from "../../reducers/CMSLayout";
import { Header } from "../../components/reusableComponents/Text/Header/Header";

export function Youtube({}) {
    const baseRoute = "/account";
    const menuItems = [
        {
            key: "1",
            path: baseRoute,
            icon: React.createElement(VideoCameraOutlined),
            label: "Media overzicht",
        },
    ];

    const Mediaroutes = [
        { path: "/", breadcrumb: "Volledig home, index" },
        {
            path: "/youtube",
            breadcrumb: () => (
                <span>
                    <HomeOutlined />
                    {" "}
                    Media overzicht
                </span>
            ),
        },
        { path: "/media/lijst", breadcrumb: "alleen lijst", isLastItemClickable: false },
        { path: "/lijst/", breadcrumb: "alleen lijst", isLastItemClickable: false },
        { path: "/media/lijst/add", breadcrumb: "Lijst add wad" },
        { path: "/media/speciallijst", breadcrumb: "specdial lijst", isLastItemClickable: false },
        { path: "/media/speciallijst/all", breadcrumb: "special lijst all", hideMenu: true },
        { path: "/media/lijst/watch/:id", isLastItemClickable: false },
        { path: "/media/lijst/watch/:id/test", isLastItemClickable: false },
        { path: "/media/lijst/watch/:id/test/:nogEenId", breadcrumb: "special lijst all custom teskt" },
    ];

    const drawerMenuVisible = useSelector((state) => state.cmsLayoutSlice.layoutStatePerPagina.media.isSideMenuVisible);
    const mediaCustomPageHeader = useSelector((state) => state.cmsLayoutSlice.layoutStatePerPagina.media.pageHeader);
    const dispatch = useDispatch();
    const toogleSideMenu = () => {
        dispatch(toggleSideMenuMedia());
    };
    const [customHeader, setCustomHeader] = useState(null);
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(true);

    // TODO: per component een custom header kunnen zetten of een default header of helemaal geen header
    const [showDefaultHeader, setShowDefaultHeader] = useState(true);
    const [showHeader, setShowHeader] = useState(true);

    return (
        <Layout className="" style={{ minHeight: "100vh", overflow: "visible" }}>
            <Content className="font" style={{ minHeight: "100vh", overflow: "visible" }}>
                <Row type="flex" style={{ minHeight: "100vh", overflow: "visible" }}>
                    <Col
                        style={{
                            height: "80vh",
                            position: "sticky",
                            top: 90,
                            zIndex: 999,
                        }}
                        xs={24}
                        md={6}
                        className="sidebarMedialist"
                    >
                        <div className={`h-5/6 overflow-auto ${false ? "hidden" : ""}`}>
                            <Header level={4}>Filters</Header>
                            <p>long content</p>
                            {
                                Array.from(
                                    {
                                        length: 100,
                                    },
                                    (_, index) => (
                                        <React.Fragment key={index}>
                                            {index % 20 === 0 && index ? "more" : "..."}
                                            <br />
                                        </React.Fragment>
                                    ),
                                )
                            }
                        </div>
                    </Col>
                    <Col xs={24} md={18}>
                        {/* <OrderAndViewOptions */}
                        {/*    results={4} */}
                        {/*    setViewOption={changeViewOption} */}
                        {/*    setOrder={changeOrder} */}
                        {/*    setOrderOption={changeOrderOption} */}
                        {/* /> */}
                        {
                            Array.from(
                                {
                                    length: 200,
                                },
                                (_, index) => (
                                    <React.Fragment key={index}>
                                        {index % 20 === 0 && index ? "more" : "..."}
                                        <br />
                                    </React.Fragment>
                                ),
                            )
                        }
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
