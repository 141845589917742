import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Button, message, Rate, Space, Table, Tag,
} from "antd";
import { DeleteTwoTone, PlayCircleOutlined } from "@ant-design/icons";
import { fetchAllMediaThunk, fetchMedialijst } from "../../../../reducers/Media";
import { Header } from "../../../reusableComponents/Text/Header/Header";
import { P } from "../../../reusableComponents/Text/Paragraph/P";

export function MediaHome() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { mediaLijst } = useSelector((state) => state.media);

    useEffect(() => {
        dispatch(fetchMedialijst({ _id: user._id }));
    }, [dispatch]);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div>
            <Header>Media overzicht</Header>

            {mediaLijst && mediaLijst.length > 0 && (
                <Tables medialijsten={mediaLijst} />
            )}
        </div>
    );
}

function Tables({ medialijsten }) {
    const columns = [
        {
            title: "Titel",
            dataIndex: "titel",
            key: "titel",
            render: (text, record) => {
                if (record.special) {
                    return <Link to={`/media/speciallijst/${record._id}`}>{text}</Link>;
                }
                return (
                    <Link to={`/media/lijst/watch/${record._id}`}>
                        {record.poster_img && (
                            <img
                                src={record.poster_img}
                                alt={record.titel}
                                style={{ width: "100px", height: "auto", marginRight: "10px" }}
                            />
                        )}
                        <Link to={`/media/lijst/watch/${record._id}`}>{text}</Link>
                    </Link>
                );
            },
        },
        {
            title: "Mening",
            dataIndex: "mening",
            key: "mening",
            render: (mening, record) => {
                if (record.special) return null;
                let color;
                switch (mening) {
                case "Favo":
                    color = "green";
                    break;
                case "Legend":
                    color = "lime";
                    break;
                case "Goed":
                    color = "geekblue";
                    break;
                case "Prima":
                    color = "gold";
                    break;
                case "Ok":
                    color = "volcano";
                    break;
                case "Meh":
                    color = "red";
                    break;
                case "Niet bekend":
                default: color = "gray";
                }
                return <Tag color={color}>{mening}</Tag>;
            },
            responsive: ["md"],
        },
        {
            title: "Prioriteit",
            dataIndex: "prioriteit",
            key: "prioriteit",
            responsive: ["md"],
        },
        // {
        //     title: "Tags",
        //     dataIndex: "tags",
        //     key: "tags",
        //     render: (tags, record) => {
        //         if (record.special) return null;
        //         return (
        //             <>
        //                 {tags.map((tag) => (
        //                     <Tag color="blue" key={tag}>{tag}</Tag>
        //                 ))}
        //             </>
        //         );
        //     },
        // },
        {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
            render: (tags, record) => {
                if (record.special) return null;
                return (
                    <Rate disabled allowHalf defaultValue={tags} />
                );
            },
        },
        {
            title: "Acties",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Link to={record.special ? `/media/speciallijst/${record._id}` : `/media/lijst/watch/${record._id}`}>
                        <PlayCircleOutlined />
                    </Link>
                    {/* {record.special ? null */}
                    {/*    : ( */}
                    {/*        <Button */}
                    {/*            style={{ background: "transparent", borderStyle: "none" }} */}
                    {/*            onClick={() => { */}
                    {/*                // this.props.doDeleteMediaLijst(record._id, this.props.user._id); */}
                    {/*            }} */}
                    {/*        > */}
                    {/*            <DeleteTwoTone /> */}
                    {/*        </Button> */}
                    {/*    )} */}
                </Space>
            ),
            fixed: "right",
            width: 120,
            responsive: ["md"],
        },
    ];

    if (!medialijsten) {
        return;
    }

    let tableData = [];
    if (Array.isArray(medialijsten)) {
        tableData = [...medialijsten];
    } else if (medialijsten.errors && medialijsten.errors.includes("MediaLijst not found")) {
        message.error("Geen media lijsten gevonden. Maak er snel 1!");
    }

    const defaultEntries = [
        {
            titel: "Alle films of series", beschrijving: "Alle ", mening: "", prioriteit: "", tags: [], special: true, _id: "all",
        },
        {
            titel: "Alleen series", beschrijving: "Series", mening: "", prioriteit: "", tags: [], special: true, _id: "series",
        },
        {
            titel: "Alleen films", beschrijving: "Films", mening: "", prioriteit: "", tags: [], special: true, _id: "films",
        },
        {
            titel: "Gearchiveerde films of series", beschrijving: "Archive", mening: "", prioriteit: "", tags: [], special: true, _id: "archive",
        },
    ];

    return (
        <div style={{ width: "100%", overflowX: "auto" }}>
            <Table
                columns={columns}
                dataSource={tableData}
                size="middle"
                rowKey={(record) => record._id || record.titel}
                pagination={{ position: ["bottomRight"] }}
                bordered
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.beschrijving}
                        </p>
                    ),
                    rowExpandable: (record) => true,
                }}
            />

            <h2 className="uk-margin-remove-top">
                <span className="Opta-Title Opta-h2">Standaard lijsten</span>
            </h2>

            <Table
                columns={columns}
                dataSource={defaultEntries}
                size="middle"
                rowKey={(record) => record._id || record.titel}
                pagination={{ position: ["bottomRight"] }}
                bordered
                expandable={{
                    expandedRowRender: (record) => (
                        <p style={{ margin: 0 }}>
                            {record.beschrijving}
                        </p>
                    ),
                    rowExpandable: (record) => true,
                }}
            />
        </div>
    );
}
