import React from "react";
import {
    DatePicker,
    Form, Input, Rate, Select, Typography,
} from "antd";

function MediaInfoPanel({ form, enums }) {
    // const
    return (
        <div className="panel-content gap-4 xxl:gap-12">
            <div className="container mx-auto px-4">
                <div className="md:col-span-2">
                    <div className="space-y-6 gap-0 xxl:gap-8 xxl:gap-y-12">
                        <div
                            className="md:grid-cols-2 gap-x-6 xxl:gap-x-12 xxl:gap-y-12"
                        >
                            <div className="space-y-6 gap-0 xxl:gap-8 xxl:gap-y-12">
                                {/* Items big */}
                                <Form.Item
                                    label="Genre"
                                    name="genre"
                                    className="form-item"
                                    rules={[{ required: true, message: "Selecteer 1 of meer genres" }]}
                                >
                                    <Select
                                        mode="multiple"
                                        className="w-full"
                                        placeholder="genre"
                                        maxTagCount="responsive"
                                    >
                                        {enums.genres.map((m) => (
                                            <Select.Option key={m} value={m}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {/* voor later; custom image kiezen, nu automatisch vgm */}

                                {/* leuk idee, maar is nu niet in DB: aantal keer gekeken */}
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 xxl:gap-x-12 gap-y-6">
                            {/* items small */}
                            <Form.Item
                                label="Titel"
                                name="titel"
                                className="form-item"
                                rules={[{ required: true, message: "Vul een titel in" }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Notities"
                                name="notities"
                                className="form-item mt-0"
                            >
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item
                                name="rating"
                                label="rating"
                            >
                                <Rate allowClear allowHalf />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MediaInfoPanel;
